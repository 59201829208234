<template>
  <div
    class="PlaceholderComponent border border-dashed cursor-pointer m-8 rounded-full mx-auto p-2 w-16 h-16 flex items-center justify-center text-gray-500"
  >
    <Icon icon="plus" />
  </div>
</template>
<script>
import Icon from './Icon.vue'
export default {
  components: { Icon },
  name: 'PlaceholderComponent',
  inject: {
    _isInEditor: { default: () => () => false }, // can style differently if in a deployed wc
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    form: Object,
    userData: Object,
  },
}
</script>

export default function getParameterByName(name, url) {
  if (typeof URLSearchParams === 'function') {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(name)
  } else {
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
}

import Axios from 'axios'
// import Firebase from '@firebase/app'
// import '@firebase/functions'

import { getGeohash } from '../helpers/geolocation'

const IP_DATA_API_KEY = process.env.VUE_APP_IP_DATA_API_KEY

export default async function getIpData() {
  const { data } = await Axios.get(`https://api.ipdata.co/?api-key=${IP_DATA_API_KEY}`)

  const {
    ip,
    timezone,
    city,
    region,
    region_code,
    country_name,
    country_code,
    continent_name,
    continent_code,
    latitude,
    longitude,
    flag,
    emoji_flag,
  } = data

  const geohash = getGeohash(latitude, longitude)

  const locationData = {
    ip,
    timezone,
    city,
    region,
    region_code,
    country_name,
    country_code,
    continent_name,
    continent_code,
    latitude,
    longitude,
    flag,
    emoji_flag,
    geohash,
  }

  Object.entries(locationData).forEach(e => {
    if (e[1] === undefined || e[1] === null) delete locationData[e[0]]
  })

  return locationData
}

export default function getUrlDict() {
  const str = window.location.href.split('?')[1]

  const dict = str
    ? str
        .split('&')
        .map(part => part.split('='))
        .reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {})
    : {}
  return dict
}

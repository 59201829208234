<template>
  <RichText
    v-if="rich"
    :class="key"
    :component="{ text }"
    :form="form"
    :userData="userData"
    @update-form="onUpdateForm"
    @update="onUpdate"
  />
  <QuickEditText
    v-else
    :value="text"
    :class="key"
    v-bind="{ _key: key, placeholder }"
    @update-text="onUpdateText"
  >
    <slot />
  </QuickEditText>
</template>
<script>
export default {
  components: {
    QuickEditText: () => import('./helpers/QuickEditText'),
    RichText: () => import('./RichText'),
  },
  provide() {
    return { _isEditing: () => this.editing }
  },
  inject: { _isEditing: { default: () => () => false } },
  props: {
    rich: Boolean,
    placeholder: String,
    condition: { type: Boolean, default: true },
    _key: String,
    text: String,
    form: Object,
    userData: Object,
    jsxKey: String,
  },
  data() {
    return {}
  },
  computed: {
    editing() {
      return this.condition && this._isEditing()
    },
    key() {
      return this.jsxKey || this._key
    },
  },
  watch: {},
  methods: {
    onUpdate(...args) {
      this.$emit('update', ...args)
    },
    onUpdateText(e) {
      this.$emit('update-form', e)
    },
    onUpdateForm(e) {
      this.$emit('update-form', [this.key, e[1]])
    },
  },
}
</script>

// From here: https://developers.facebook.com/docs/facebook-pixel/reference#standard-events

const content_category = {
  name: 'content_category',
  type: 'string',
  description: 'Category of the page/product',
}
const content_ids = {
  name: 'content_ids',
  type: 'array:string,number',
  description: "Product IDs associated with the event, such as SKUs (e.g. ['ABC123', 'XYZ789']).",
}
const contents = {
  name: 'contents',
  type: 'array:objects',
  description:
    "An array of JSON objects that contains the quantity and the International Article Number (EAN) when applicable, or other product or content identifier(s). id and quantity are the required fields. e.g. [{'id': 'ABC123', 'quantity': 2}, {'id': 'XYZ789', 'quantity': 2}].",
}
const currency = {
  name: 'currency',
  type: 'string',
  description: 'The currency for the value specified.',
}
const value = {
  name: 'value',
  type: 'number',
  description: 'The value of a user performing this event to the business.',
}
const content_name = {
  name: 'content_name',
  type: 'string',
  description: 'Name of the page/product.',
}
const content_type = {
  name: 'content_type',
  type: 'string',
  description:
    'Either product or product_group based on the content_ids or contents being passed. If the IDs being passed in content_ids or contents parameter are IDs of products then the value should be product. If product group IDs are being passed, then the value should be product_group.',
}
const num_items = {
  name: 'num_items',
  type: 'number',
  description: 'Used with InitiateCheckout event. The number of items when checkout was initiated.',
}
const search_string = {
  name: 'search_string',
  type: 'string',
  description: 'Used with the Search event. The string entered by the user for the search.',
}
const predicted_ltv = {
  name: 'predicted_ltv',
  type: 'number',
  description:
    'Predicted lifetime value of a subscriber as defined by the advertiser and expressed as an exact value.',
}

export default function facebookPixelEvents() {
  return [
    'AddPaymentInfo',
    'AddToCart',
    'AddToWishlist',
    'CompleteRegistration',
    'Contact',
    'CustomizeProduct',
    'Donate',
    'InitiateCheckout',
    'Lead',
    'PageView',
    'Purchase',
    'Schedule',
    'Search',
    'StartTrial',
    'SubmitApplication',
    'Subscribe',
    'ViewContent',
  ]
}

export function getEventData(e) {
  switch (e) {
    case 'AddPaymentInfo': {
      return {
        content_category,
        content_ids,
        contents,
        currency,
        value,
      }
    }
    case 'AddToCart': {
      return {
        content_ids,
        content_name,
        content_type,
        contents,
        currency,
        value,
      }
    }
    case 'AddToWishlist': {
      return { content_name, content_category, content_ids, contents, currency, value }
    }
    case 'CompleteRegistration': {
      return { content_name, currency, status, value }
    }
    case 'Contact': {
      return {}
    }
    case 'CustomizeProduct': {
      return {}
    }
    case 'Donate': {
      return {}
    }
    case 'InitiateCheckout': {
      return { content_category, content_ids, contents, currency, num_items, value }
    }
    case 'Lead': {
      return { content_category, content_name, currency, value }
    }
    case 'PageView': {
      return {}
    }
    case 'Purchase': {
      return {
        content_ids,
        content_name,
        content_type,
        contents,
        currency: { ...currency, required: true },
        num_items,
        value: { ...value, required: true },
      }
    }
    case 'Schedule': {
      return {}
    }
    case 'Search': {
      return { content_category, content_ids, contents, currency, search_string, value }
    }
    case 'StartTrial': {
      return { currency, predicted_ltv, value }
    }
    case 'SubmitApplication': {
      return {}
    }
    case 'Subscribe': {
      return { currency, predicted_ltv, value }
    }
    case 'ViewContent': {
      return {
        content_ids,
        content_category,
        content_name,
        content_type,
        contents,
        currency,
        value,
      }
    }

    default:
      return {}
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SignpostForm',_vm._b({on:{"testmode":function($event){_vm.isTest = $event}}},'SignpostForm',Object.assign({}, _vm.attributes,
    {id: _vm.id,
    values: _vm.values,
    noSpinner: _vm.noSpinner,
    flowType: 'wc',
    isInWebApp: _vm.isTest,
    popup: _vm.isPopup,
    version: _vm.version,
    startingPage: _vm.startingPage}),false))}
var staticRenderFns = []

export { render, staticRenderFns }
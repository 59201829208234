<script>
export default {
  name: 'FlowTransition',
  props: {
    name: String,
    form: Object,
    transition: Object,
    appear: { type: Boolean, default: true },
    mode: { type: String, default: 'out-in' },
  },
  computed: {
    individual() {
      return (this.transition && this.transition[`individual-${this.name}`]) || false
    },
    transitionName() {
      return this.individual
        ? this.transition[this.name] || 'none'
        : (this.form &&
            this.form.defaults &&
            this.form.defaults.transitions &&
            this.form.defaults.transitions[this.name]) ||
            'none'
    },
  },
  render() {
    return (
      <transition appear={this.appear} mode={this.mode} name={this.transitionName}>
        {this.$scopedSlots.default ? this.$scopedSlots.default() : <div></div>}
      </transition>
    )
  },
}
</script>

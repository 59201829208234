<template>
  <div class="InfoBox" :class="{ showing: Boolean(name) }">
    <div class="InfoBox-background" @click="closeInfoBox" />
    <div class="InfoBox-box">
      <div class="close" @click="closeInfoBox">
        <Icon icon="feather:x" />
      </div>
      <FormPage
        v-if="page"
        :page="page"
        :pageData="userData"
        :pageId="page && page.id"
        hideFooter
        hideHeader
        hideProgress
        v-bind="{ form, page, userData, infoBox: true }"
        @update="$emit('update', $event)"
        @update-form="onFormPageUpdate(page, $event)"
        @reset="$emit('reset', $event)"
        @next="$emit('next', $event)"
        @prev="$emit('prev', $event)"
        @go-to-page="$emit('go-to-page', $event)"
      />
    </div>
  </div>
</template>
<script>
import FormPage from './FormPage'
import Icon from './Icon.vue'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export default {
  name: 'InfoBox',
  components: { Icon, FormPage },
  inject: {
    _triggerInfoBox: { default: () => () => {} },
    _setCheckpoint: { default: () => () => {} },
    _getFlowPages: { default: () => () => [] },
  },
  props: { name: String, form: Object, userData: Object },
  data() {
    return {}
  },
  computed: {
    page() {
      return this.name ? this._getFlowPages().find(p => p.key === this.name) : null
    },
  },
  watch: {
    page: {
      handler(p, o) {
        if (isEqual(p, o)) return
        if (p && p.checkpoints) {
          this._setCheckpoint(p.checkpoints)
        }
      },
    },
  },
  methods: {
    closeInfoBox() {
      this._triggerInfoBox(null)
    },
    onFormPageUpdate(page, [key, value]) {
      const pages = cloneDeep(this.form.pages)
      pages.find(c => c.id === page.id)[key] = value
      this.$emit('update-form', ['pages', pages])
    },
  },
}
</script>

import range from 'lodash/range'

export default function getButtons(component, userData) {
  let buttons = []

  if (component && component.preset_buttons) {
    buttons = buttons.concat(getPresetButtons(component.preset_buttons))
  }

  const mainButtons =
    userData && component.button_repeater_key !== undefined
      ? userData[component.button_repeater_key]
      : component && component.buttons

  if (Array.isArray(mainButtons)) buttons = buttons.concat(mainButtons)

  return buttons
}

function getPresetButtons(preset) {
  switch (preset) {
    case 'countries':
      return getCountries()
    case 'states':
      return getStates()
    case 'states_with_dc':
      return getStates({ dc: true })
    case 'states_with_dc_and_territories':
      return getStates({ dc: true, territories: true })
    case 'states_with_outside_us':
      return getStates({ outside_us: true })
    case 'canadian_provinces':
      return getProvince({})
    case 'canadian_provinces_and_territories':
      return getProvince({ territories: true })
    case 'day_nums':
      return getDayNums()
    case 'months':
      return getMonths()
    case 'years':
      return getYears()
    default:
      return []
  }
}

function getStates({ dc, territories, outside_us } = {}) {
  const territoriesList = ['as', 'fm', 'gu', 'mh', 'mp', 'pr', 'pw', 'vi']

  return Object.entries({
    ak: 'Alaska',
    al: 'Alabama',
    ar: 'Arkansas',
    as: 'American Samoa',
    az: 'Arizona',
    ca: 'California',
    co: 'Colorado',
    ct: 'Connecticut',
    dc: 'District Of Columbia',
    de: 'Delaware',
    fl: 'Florida',
    fm: 'Federated States Of Micronesia',
    ga: 'Georgia',
    gu: 'Guam',
    hi: 'Hawaii',
    ia: 'Iowa',
    id: 'Idaho',
    il: 'Illinois',
    in: 'Indiana',
    ks: 'Kansas',
    ky: 'Kentucky',
    la: 'Louisiana',
    ma: 'Massachusetts',
    md: 'Maryland',
    me: 'Maine',
    mh: 'Marshall Islands',
    mi: 'Michigan',
    mn: 'Minnesota',
    mo: 'Missouri',
    mp: 'Northern Mariana Islands',
    ms: 'Mississippi',
    mt: 'Montana',
    nc: 'North Carolina',
    nd: 'North Dakota',
    ne: 'Nebraska',
    nh: 'New Hampshire',
    nj: 'New Jersey',
    nm: 'New Mexico',
    nv: 'Nevada',
    ny: 'New York',
    oh: 'Ohio',
    ok: 'Oklahoma',
    or: 'Oregon',
    pa: 'Pennsylvania',
    pr: 'Puerto Rico',
    pw: 'Palau',
    ri: 'Rhode Island',
    sc: 'South Carolina',
    sd: 'South Dakota',
    tn: 'Tennessee',
    tx: 'Texas',
    ut: 'Utah',
    va: 'Virginia',
    vi: 'Virgin Islands',
    vt: 'Vermont',
    wa: 'Washington',
    wi: 'Wisconsin',
    wv: 'West Virginia',
    wy: 'Wyoming',
    outside_us: 'Outside the US',
  })
    .map(([key, text]) => ({ id: key, key, text }))
    .filter(
      b =>
        (dc || b.key !== 'dc') &&
        (territories || !territoriesList.includes(b.key)) &&
        outside_us | (b.key !== 'outside_us')
    )
}

function getProvince({ territories } = {}) {
  const territoriesList = ['nt', 'nu', 'yt']

  return Object.entries({
    ab: `Alberta`,
    bc: `British Columbia`,
    mb: `Manitoba`,
    nb: `New Brunswick`,
    nl: `Newfoundland and Labrador`,
    nt: `Northwest Territories`,
    ns: `Nova Scotia`,
    nu: `Nunavut`,
    on: `Ontario`,
    pe: `Prince Edward Island`,
    qc: `Quebec`,
    sk: `Saskatchewan`,
    yt: `Yukon`,
  })
    .map(([key, text]) => ({ id: key, key, text }))
    .filter(b => territories || !territoriesList.includes(b.key))
}

function getCountries() {
  return [
    `Afghanistan`,
    `Albania`,
    `Algeria`,
    `American Samoa`,
    `Andorra`,
    `Angola`,
    `Anguilla`,
    `Antarctica`,
    `Antigua and Barbuda`,
    `Argentina`,
    `Armenia`,
    `Aruba`,
    `Australia`,
    `Austria`,
    `Azerbaijan`,
    `Bahamas`,
    `Bahrain`,
    `Bangladesh`,
    `Barbados`,
    `Belarus`,
    `Belgium`,
    `Belize`,
    `Benin`,
    `Bermuda`,
    `Bhutan`,
    `Bolivia, Plurinational State of`,
    `Bonaire, Sint Eustatius and Saba`,
    `Bosnia and Herzegovina`,
    `Botswana`,
    `Bouvet Island`,
    `Brazil`,
    `British Indian Ocean Territory`,
    `Brunei Darussalam`,
    `Bulgaria`,
    `Burkina Faso`,
    `Burundi`,
    `Cambodia`,
    `Cameroon`,
    `Canada`,
    `Cape Verde`,
    `Cayman Islands`,
    `Central African Republic`,
    `Chad`,
    `Chile`,
    `China`,
    `Christmas Island`,
    `Cocos (Keeling) Islands`,
    `Colombia`,
    `Comoros`,
    `Congo`,
    `Congo, the Democratic Republic of the`,
    `Cook Islands`,
    `Costa Rica`,
    `C√¥te d'Ivoire`,
    `Croatia`,
    `Cuba`,
    `Cura√ßao`,
    `Cyprus`,
    `Czech Republic`,
    `Denmark`,
    `Djibouti`,
    `Dominica`,
    `Dominican Republic`,
    `Ecuador`,
    `Egypt`,
    `El Salvador`,
    `Equatorial Guinea`,
    `Eritrea`,
    `Estonia`,
    `Ethiopia`,
    `Falkland Islands (Malvinas)Faroe Islands`,
    `Fiji`,
    `Finland`,
    `France`,
    `French Guiana`,
    `French Polynesia`,
    `French Southern Territories`,
    `Gabon`,
    `Gambia`,
    `Georgia`,
    `Germany`,
    `Ghana`,
    `Gibraltar`,
    `Greece`,
    `Greenland`,
    `Grenada`,
    `Guadeloupe`,
    `Guam`,
    `Guatemala`,
    `Guernsey`,
    `Guinea`,
    `Guinea-Bissau`,
    `Guyana`,
    `Haiti`,
    `Heard Island and McDonald Islands`,
    `Holy See (Vatican City State)`,
    `Honduras`,
    `Hong Kong`,
    `Hungary`,
    `Iceland`,
    `India`,
    `Indonesia`,
    `Iran, Islamic Republic of`,
    `Iraq`,
    `Ireland`,
    `Isle of Man`,
    `Israel`,
    `Italy`,
    `Jamaica`,
    `Japan`,
    `Jersey`,
    `Jordan`,
    `Kazakhstan`,
    `Kenya`,
    `Kiribati`,
    `Korea, Democratic People's Republic of`,
    `Korea, Republic of `,
    `Kosovo`,
    `Kuwait`,
    `Kyrgyzstan`,
    `Lao People's Democratic Republic`,
    `Latvia`,
    `Lebanon`,
    `Lesotho`,
    `Liberia`,
    `Libya`,
    `Liechtenstein`,
    `Lithuania`,
    `Luxembourg`,
    `Macao`,
    `Macedonia, the Former Yugoslav Republic of`,
    `Madagascar`,
    `Malawi`,
    `Malaysia`,
    `Maldives`,
    `Mali`,
    `Malta`,
    `Marshall Islands`,
    `Martinique`,
    `Mauritania`,
    `Mauritius`,
    `Mayotte`,
    `Mexico`,
    `Micronesia, Federated States of`,
    `Moldova, Republic of`,
    `Monaco`,
    `Mongolia`,
    `Montenegro`,
    `Montserrat`,
    `Morocco`,
    `Mozambique`,
    `Myanmar`,
    `Namibia`,
    `Nauru`,
    `Nepal`,
    `Netherlands`,
    `New Caledonia`,
    `New Zealand`,
    `Nicaragua`,
    `Niger`,
    `Nigeria`,
    `Niue`,
    `Norfolk Island`,
    `Northern Mariana Islands`,
    `Norway`,
    `Oman`,
    `Pakistan`,
    `Palau`,
    `Palestine, State of`,
    `Panama`,
    `Papua New Guinea`,
    `Paraguay`,
    `Peru`,
    `Philippines`,
    `Pitcairn`,
    `Poland`,
    `Portugal`,
    `Puerto Rico`,
    `Qatar`,
    `Réunion`,
    `Romania`,
    `Russia`,
    `Rwanda`,
    `Saint Barthélemy`,
    `Saint Helena, Ascension and Tristan da Cunha`,
    `Saint Kitts and Nevis`,
    `Saint Lucia`,
    `Saint Martin`,
    `Saint Pierre and Miquelon`,
    `Saint Vincent and the Grenadines`,
    `Samoa`,
    `San Marino`,
    `Sao Tome and Principe`,
    `Saudi Arabia`,
    `Senegal`,
    `Serbia`,
    `Seychelles`,
    `Sierra Leone`,
    `Singapore`,
    `Sint Maarten (Dutch part)`,
    `Slovakia`,
    `Slovenia`,
    `Solomon Islands`,
    `Somalia`,
    `South Africa`,
    `South Georgia and the South Sandwich Islands`,
    `South Sudan`,
    `Spain`,
    `Sri Lanka`,
    `Sudan`,
    `Suriname`,
    `Svalbard and Jan Maye`,
    `Swaziland`,
    `Sweden`,
    `Switzerland`,
    `Syrian Arab Republic`,
    `Taiwan, Province of China`,
    `Tajikistan`,
    `Tanzania, United Republic of`,
    `Thailand`,
    `Timor-Leste`,
    `Togo`,
    `Tokelau`,
    `Tonga`,
    `Trinidad and Tobago`,
    `Tunisia`,
    `Turkey`,
    `Turkmenistan`,
    `Turks and Caicos Islands`,
    `Tuvalu`,
    `Uganda`,
    `Ukraine`,
    `United Arab Emirates`,
    `United Kingdom`,
    `United States`,
    `United States Minor Outlying Islands`,
    `Uruguay`,
    `Uzbekistan`,
    `Vanuatu`,
    `Venezuela, Bolivarian Republic of`,
    `Viet Nam`,
    `Virgin Islands, British`,
    `Virgin Islands, U.S.`,
    `Wallis and Futuna`,
    `Western Sahara`,
    `Yemen`,
    `Zambia`,
    `Zimbabwe`,
  ].map(key => ({ key, text: key, id: key }))
}

function getDayNums() {
  return range(1, 32, 1).map(num => ({
    key: `${num}`,
    text: `${num}`,
    id: `${num}`,
  }))
}

function getMonths() {
  return [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ].map(text => ({ key: text.toLowerCase(), text, id: text }))
}

function getYears() {
  return range(2021, 1900, -1).map(num => ({
    key: `${num}`,
    text: `${num}`,
    id: `${num}`,
  }))
}

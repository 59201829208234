<template>
  <div v-if="!hidden" class="TextValue">
    {{ text | computed(userData, form) }}
  </div>
</template>

<script>
export default {
  name: 'TextValue',
  props: {
    text: String,
    userData: Object,
    form: Object,
    hidden: Boolean,
  },
}
</script>

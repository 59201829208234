<script>
import WindowEvent from '@/components/logic/WindowEvent.vue'
import Icon from '@/components/form/Icon'
import getUrlDict from '@/helpers/getUrlDict'
export default {
  inject: {
    _getSelectionMode: { default: () => () => {} },
  },
  props: { popup: Boolean, open: Boolean, loading: Boolean },
  data() {
    return {
      ignorePopup: false,
    }
  },
  mounted() {
    const urlDict = getUrlDict()
    if (['false', false].includes(urlDict.is_popup)) this.ignorePopup = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
  render() {
    const slotContent = (this.$scopedSlots.default && this.$scopedSlots.default()) || null
    const selectionMode = this._getSelectionMode() || false
    const buttonClasses = selectionMode ? 'is-picker-on' : ''
    if (this.popup && !this.ignorePopup) {
      return (
        <div
          class={`SavvyFlowPopup Flow-Element ElementType-FlowPopupOuter ${
            this.open ? 'popup-open' : 'popup-closed'
          }`}
          key="popup"
        >
          <WindowEvent event-name="keyup" onOnTrigger={this.close} />
          <div
            class={`SavvyFlowPopup-overlay overlay Flow-Element ElementType-FlowPopupOverlay ${
              this.open ? 'in-popup-open' : 'in-popup-closed'
            }`}
            onClick={this.close}
          ></div>
          <div
            class={`SavvyFlowPopup-container container Flow-Element ElementType-FlowPopupContainer ${
              this.open ? 'in-popup-open' : 'in-popup-closed'
            }`}
          >
            <button
              class={`close borderless Flow-Element ElementType-FlowPopupCloseButton ${buttonClasses}`}
              onClick={this.close}
            >
              <Icon icon="feather:x" />
              Close
            </button>
            <div class="box Flow-Element ElementType-FlowPopupContainerBox">{slotContent}</div>
          </div>
        </div>
      )
    }

    return slotContent
  },
}
</script>

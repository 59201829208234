function replacer(k, v) {
  if (['updatedAt', 'createdAt'].includes(k)) return v
  if (typeof v === 'number' && isNaN(v)) return '_error'
  if (v === Infinity) return '_error'
  if (v === undefined) return null
  if (v && typeof v.then === 'function') return '[Pending Promise]'
  if (typeof v === 'function') return 'Function'
  if (typeof v === 'symbol') return 'Symbol'
  return v
}

export default function removeEmpty(obj, useReplacer) {
  if (!obj) return obj
  Object.keys(obj).forEach(key => {
    const newKey = key || `_${key}`
    if (!key) {
      obj[newKey] = obj[key]
      delete obj[key]
    }
    if (obj[newKey] && obj[newKey].then && typeof obj[newKey].then === 'function')
      obj[newKey] = '[Pending Promise]'
    else if (typeof obj[newKey] === 'number' && isNaN(obj[newKey])) obj[newKey] = null
    else if (obj[newKey] && Array.isArray(obj[newKey])) {
      obj[newKey].forEach((k, i) => {
        if (Array.isArray(k)) obj[newKey][i] = JSON.stringify(k)
        else if (typeof obj[newKey][i] === 'number' && isNaN(obj[newKey][i])) obj[newKey][i] = null
        else if (typeof k === 'object') obj[newKey][i] = removeEmpty(k)
        else if (k === undefined) obj[newKey][i] = 'Undefined'
      })
    } else if (obj[newKey] && typeof obj[newKey] === 'object') removeEmpty(obj[newKey])
    // Todo make work with arrays
    else if (obj[newKey] === undefined) delete obj[newKey]
  })
  return useReplacer ? JSON.parse(JSON.stringify(obj, replacer)) : obj
}

export function cleanObj(obj, useReplacer) {
  if (!obj) return obj
  Object.keys(obj).forEach(key => {
    const newKey = key || `_${key}`
    if (!key) {
      obj[newKey] = obj[key]
      delete obj[key]
    }
    if (typeof obj[newKey] === 'number' && (isNaN(obj[newKey]) || obj[newKey] === Infinity)) {
      obj[newKey] = '_error'
    } else if (obj[newKey] && obj[newKey].then && typeof obj[newKey].then === 'function')
      obj[newKey] = '[Pending Promise]'
    else if (obj[newKey] && Array.isArray(obj[newKey])) {
      obj[newKey].forEach((k, i) => {
        if (Array.isArray(k)) obj[newKey][i] = JSON.stringify(k)
        else if (
          (typeof obj[newKey][i] === 'number' && isNaN(obj[newKey][i])) ||
          obj[newKey] === Infinity
        )
          obj[newKey][i] = '_error'
        else if (typeof k === 'object') obj[newKey][i] = removeEmpty(k)
        else if (k === undefined) obj[newKey][i] = null
      })
    } else if (obj[newKey] && typeof obj[newKey] === 'object') removeEmpty(obj[newKey])
    // Todo make work with arrays
    else if (obj[newKey] === undefined) obj[newKey] = null
  })
  return useReplacer ? JSON.parse(JSON.stringify(obj, replacer)) : obj
}

import Axios from 'axios'
// import * as Sentry from '@sentry/vue'

const URL =
  process.env.VUE_APP_FIREBASE_PROJECT_ID === 'savvy-b2c-dev'
    ? `https://us-central1-savvy-b2c-dev.cloudfunctions.net/updateSavvy`
    : `https://us-central1-savvy-app-live.cloudfunctions.net/updateSavvy`

const ENV = process.env.VUE_APP_TEST_BLOCKER ? 'Dev' : 'Prod'

export default async function notifySlack(text, error, context) {
  const formId = context && context.form && context.form.id
  const formTitle = context && context.form && context.form.title
  const entryId = context && context.userData && context.userData.entryId
  const contextString = [
    { name: 'Flow Title', value: formTitle },
    { name: 'Flow Id', value: formId },
    { name: 'Entry Id', value: entryId },
  ]
    .filter(e => e.value)
    .map(e => `${e.name}: ${e.value}`)
    .join(' | ')

  // return text
  if (window.location.toString().startsWith('file:///')) return
  // if (error) Sentry.captureException(error)

  let message =
    context && context.justText ? text : `${ENV} Error at ${window.location.toString()} - ${text}`
  if (error)
    message += `: ${
      error && error.response && error.response.data
        ? JSON.stringify(error.response.data)
        : error.message
    }`
  if (contextString.length > 0) message += `\n\n${contextString}`

  const channelId = getChannelId(context, message)
  await Axios.post(URL, { message, channelId })
}

function getChannelId(context, message) {
  if (ENV === 'Dev') return `C01527NKEF6`
  if (message.includes('Failed to fetch')) return `C0269MX9ETB`
  if (!context) return `G012HKB0XQE`
  if (context.datadump) return `C021S60JJPM`
  if (context.failedFetch) return `C0269MX9ETB`
  if (context.channel) return context.channel
  return `G012HKB0XQE`
}

<template>
  <SignpostForm
    v-bind="{
      ...attributes,
      id,
      values,
      noSpinner,
      flowType: 'wc',
      isInWebApp: isTest,
      popup: isPopup,
      version,
      startingPage,
    }"
    @testmode="isTest = $event"
  />
</template>

<script>
import Vue from 'vue'

// import '@/sentryFlows'
import '@/helpers/prototypes'
import detectLocalStorage from '@/helpers/detectLocalStorage'
import getUrlDict from '@/helpers/getUrlDict'
import SignpostForm from '@/components/form/SignpostForm'
import notifySlack from '@/helpers/notify'

Vue.config.productionTip = false
Vue.config.performance = (detectLocalStorage() && localStorage.SavvyDebugMode) || false
Vue.config.devtools = (detectLocalStorage() && localStorage.SavvyDebugMode) || false

export default {
  name: 'Flow',
  components: {
    SignpostForm,
  },
  props: {
    id: String,
    values: {},
    noSpinner: Boolean,
    popup: [Boolean, String],
    version: Number,
  },
  data() {
    return {
      isTest: initialTestValue(),
      attributes: {},
      currentPageId: null,
      currentPageIndex: null,
      currentPageKey: null,
    }
  },
  computed: {
    isPopup() {
      return ![undefined, 'false', false, null].includes(this.popup)
    },
    startingPage() {
      if (this.currentPageId) return { type: 'id', value: this.currentPageId }
      if (this.currentPageIndex) return { type: 'index', value: this.currentPageIndex }
      if (this.currentPageKey) return { type: 'key', value: this.currentPageKey }
      return null
    },
  },
  created() {
    console.log('LUCIAN - Flow created()')
    this.onMounted()
    this.isTest = initialTestValue()
    let message
    ;(async () => {
      try {
        console.log('LUCIAN - trying to send slack notification')
        const knownOreIds = [
          'NadXxPf8yFc1VmLjajzy',
          'yN0VoAoECs8mtQn8Z66V',
          'tIX6yYmhypnkxY5G4QV6',
          'siIMMPXnNCQu8SyFa6ks',
          'KTaxpG3DF0UgINCxBivl',
          'M6zBoxYgYo2jqFOR7JZM',
          'OIOUZJDqvKD8j55Mcqav',
          'N4XxWim0p8d8iaTX1d6F',
          'OYsADbAJCjF4kFH9usoa',
          'VZXnNUt42nygL56aaPMo',
          'flow_hh9ej83bd66a0fi1j9idfg2hd',
          'flow_7hjc43b8ehj73289ca0741ef4f',
          'mdlnwqTrTuvtSlZaQWAz',
          'flow_j937edhjcdaii9cjg58d20d9d',
          'flow_jj58f6749b0gb29df47je61ffj',
          'WBjbELMKlQAWOqqH7PEO',
          'g25Cgz9Eq2LQiBgLMzr5',
          'miFc3AIeOJkdQ1VZU8nQ',
          'Z1FIF26wHSqTwXOoCWsO',
          'cIjyZTcZ6zhjGCco3vFF',
          'flow_2a13e8hfh329cd222059199d',
          'fpajfMnHjoyUA4E4zso2',
          'geDfKtiBPeqjsu8UWNqY',
          'k07WeW2XZPkWRtERBstw',
          'k79H14jTSU5QYcxNFLl8',
          'km5Umhqsix0BAlhhtUH5',
          'mFejSBJsNihXK7HcqsJV',
          'Vac9mSVSaopcpZRR3jZ1',
          '98GGcAPVBJXA2I3nUVlG',
          'I0LhZGMR9nU4HTH2EBds',
          '055DUjbEv4TLNdbgM40A',
          '0Xo9IatT9y870IyD40Ye',
          '0y4mCPfZaDNJHUlfS30M',
          'I1Ch776UGFEHLLRh8xc1',
          'Ppro7edHjDxDcAfvGV6b',
          'TQHHHMIePXUBK11PnUqm',
          'PNhYFaJt61aGHqjElVWZ',
          '1zrrquKszgk5donFiFJ1',
          'I2swLgOayTB4y2DGvWfp',
          'UPU5plryns5lIk5cF6lV',
          '9sBWfYkaXiuky6nLgwNx',
          'tEKxFTvF4qrgX8boJ0XY',
          'rO4x7aDIQpKis8yzf35m',
          'nUSgYrUUwKriv3KXT4YJ',
          'JfokPic6thWBZB0mCG8Z',
          'KUwLEwKyUy6ZUwW85a0I',
          'flow_5j5b78cb09a767322igfaifhh',
          'n1LzluRYA9ihNqCIJcmj',
        ]
        const flow_id = this.id
        const channelId = knownOreIds.includes(flow_id) ? 'C069Z671A1Y' : 'C0684B0CUF8'
        const docTitle = document.title
        const URL = window.location.href
        message = `ORE flow opened. *. Flow_id: ${flow_id} *. document.title: ${docTitle} *. URL: ${URL}`
        console.log('LUCIAN - sending slack notification message: ', message)
        await notifySlack(message, channelId, true)
      } catch (error) {
        console.error(`Error sending Slack notification Message: ${message}. Error: `, error)
      }
    })()
  },
  methods: {
    onMounted() {
      console.log('LUCIAN - Flow onMounted()')
      const dict = getUrlDict()
      if (dict.current_page_id) this.currentPageId = dict.current_page_id
      if (dict.current_page_index) this.currentPageIndex = dict.current_page_index
      if (dict.current_page_key) this.currentPageKey = dict.current_page_key

      const host = document.querySelector(`savvy-flow[id="${this.id}"]`)
      const attrs = {}
      const hostAttributes = host.attributes
      if (hostAttributes) {
        // const start = 'value_'
        for (let i = 0; i < hostAttributes.length; i++) {
          const { nodeName, nodeValue } = hostAttributes[i]

          // if (nodeName.startsWith(start)) {
          attrs[nodeName] = nodeValue
          // }
        }
        this.attributes = attrs
      }
    },
  },
}

function initialTestValue() {
  console.log('LUCIAN - Flow initialTestValue()')
  const str = window.location.href.split('?')[1]

  const dict = str
    ? str
        .split('&')
        .map(part => part.split('='))
        .reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {})
    : {}

  const isTest = dict.savvy_test === 'true'

  const isReflectTest = window.isReflectTest === true
  return isTest || isReflectTest
}
</script>

<style lang="scss">
// @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css';
@import '@/styles/flows.scss';
</style>

<template>
  <div
    class="FlowComponents Container"
    v-if="componentsToShow.length > 0"
    :class="[location, _elSel()]"
  >
    <FormComponent
      v-for="component in componentsToShow"
      :key="component.id"
      v-bind="{
        form,
        component,
        page,
        pageData: userData,
        userData,
        componentTabGroupIndexes,
        componentIndex: component._originalIndex,
        pageMeetsRequirements,
        components,
      }"
      @update="onUpdate"
      @prev="onPrev"
      @next="onNext"
      @enter="onEnter"
      @reset="$emit('reset')"
      @go-to-page="$emit('go-to-page', $event)"
      @update-form="onUpdateFormComponent(component, $event)"
      @click.native="clickAction(component, component._originalIndex)"
    />
  </div>
</template>
<script>
import FormComponent from './FormComponent'
export default {
  components: { FormComponent },
  inject: {
    _emitUpdateForm: { default: () => () => {} },
    _updateUserData: { default: () => () => {} },
    _elSel: { default: () => () => [] },
  },
  provide() {
    return {
      _onUpdateFormComponent: this.onUpdateFormComponent,
      _clickAction: this.clickAction,
    }
  },
  props: {
    location: String,
    form: Object,
    page: Object,
    components: Array,
    userData: Object,
    componentTabGroupIndexes: { type: Array, default: () => [] },
    pageMeetsRequirements: { type: Boolean, default: true },
  },
  data() {
    return {}
  },
  computed: {
    componentsToShow() {
      const components = this.components || []
      const containerKeys = new Set(
        ((this.form && this.form.components) || [])
          .filter(c => c.type === 'Container')
          .map(c => c.key)
      )
      return components
        .filter(c => this.location && c._location === this.location)
        .filter(c => (c.parent_key ? !containerKeys.has(c.parent_key) : true))
        .map(c => ({ ...c, _originalIndex: components.findIndex(oc => oc.id === c.id) }))
    },
  },
  watch: {},
  methods: {
    onUpdate([key, value], extra) {
      // const updateValues = extra || {}
      // const updateObj = { ...this.userData, ...updateValues, [key]: value }
      Object.entries(extra || {}).forEach(e => this._updateUserData(e[0], e[1]))
      if (key !== undefined && value !== undefined)
        //updateObj[key] = value
        this._updateUserData(key, value)

      // this.$emit('update', [null, updateObj])
    },
    onPrev() {
      this.$emit('prev')
    },
    onNext() {
      this.$emit('next')
    },
    onEnter() {
      /* do nothing? Supposed to be the last input on the page press enter go to next page feature but that doesn't seem to apply here */
    },
    onUpdateFormComponent(component, [key, value]) {
      const components = ((this.form && this.form.components) || []).map(c =>
        c.id === component.id ? { ...c, [key]: value } : c
      )
      this._emitUpdateForm('components', components)
    },
    clickAction() {
      // @TODO
    },
  },
}
</script>

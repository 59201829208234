import computedValues from '../editor/helpers/computedValues'
import getUrlDict from '@/helpers/getUrlDict'
import { validateInputBox } from '@/components/form/helpers/validation'
import { repeatPages } from './repeatPages'
import componentTypes from '@/components/form/editor/helpers/componentTypes'

let fbclid = getUrlDict().fbclid
let pixelId = Math.floor(Math.random() * 1000000000)

export const OUTPUTS_COLLECTION = process.env.VUE_APP_TEST_BLOCKER
  ? 'flow-submissions-test'
  : 'flow-submissions'
export const OUTPUT_DOCUMENT_STATUS = process.env.VUE_APP_TEST_BLOCKER ? 'test' : 'new'

const noUserInput = componentTypes()
  .filter(c => c.hasNoValue)
  .map(c => c.key)
//  ['PlainText', 'MediaImage', 'MediaEmbed', 'RichText', 'Container', 'PlaceholderComponent']
export function getDataToSend(form, userData) {
  const allKeys =
    (form &&
      Array.isArray(form.pages) &&
      repeatPages(form.pages, userData).reduce((acc, p) => {
        if (Array.isArray(p.components))
          p.components.forEach(c => {
            if (!noUserInput.includes(c.type) && !c.doNotSave) {
              acc.push(c.key)
              const extras = addExtraComponentKeys(c)
              if (extras.length > 0) acc.push(...extras)
            }
          })
        return acc
      }, [])) ||
    []
  if (form && form.computedFields) {
    form.computedFields.forEach(c => {
      if (!c.doNotSave) allKeys.push(c.key)
    })
  }
  if (form && form.experiments) {
    form.experiments.forEach(c => {
      allKeys.push(c.key)
    })
  }
  if (form && form.components) {
    form.components.forEach(c => {
      if (!noUserInput.includes(c.type) && !c.doNotSave) {
        allKeys.push(c.key)
        const extras = addExtraComponentKeys(c)
        if (extras.length > 0) allKeys.push(...extras)
      }
    })
  }
  if (form.registered_keys) {
    allKeys.push(
      ...form.registered_keys
        .replace(/,/gi, '\n')
        .split('\n')
        .filter(e => e)
    )
  }
  allKeys.unshift('createdAt', 'entryId', 'has_submitted', 'is_test')

  return Array.from(new Set(allKeys))
}

export function createDestinationData(e, data, form, userData) {
  const regex = /spreadsheets\/d\/.*\//gi

  const mappings = (e.mappingsAllowed && e.mappings) || {}

  /* TWILIO IS NOT READY FOR PRODUCTION USE YET */
  if (e.output === 'twilio') return

  const destinationData = {
    output: e.output,
    scrub: e.scrubOnSuccess,
    entryId: userData.entryId,
    data: {
      ...data,
      excludeUnmappedKeys: e.excludeUnmappedKeys,
      mappings,
      uniqueId: e.uniqueId,
      // entryId: userData.entryId,
      formTitle: form.title,
    },
  }

  // const removeIfNotMapped = new Set(['entryId'])
  if (!mappings.entryId) {
    destinationData.data.data = destinationData.data.data.filter(d => d[0] !== 'entryId')
  }

  if (e.excludeUnmappedKeys) {
    destinationData.data.data = destinationData.data.data.filter(d => mappings[d[0]])
  }

  if (e.valueMap) {
    destinationData.data.valueMaps = e.valueMap
    destinationData.data.data = mapValues(destinationData.data.data, e.valueMap)
  }

  switch (e.output) {
    case 'airtable': {
      destinationData.data.table = e.table
      destinationData.data.base = e.base
      break
    }

    case 'google-sheets': {
      if (e.sheetUrl) {
        const sheetIdResults = e.sheetUrl.match(regex)
        if (sheetIdResults) {
          const sheetId = sheetIdResults[0].split('/')[2]
          destinationData.data.sheetId = sheetId
        }
      } else if (e.sheetId) {
        destinationData.data.sheetId = e.sheetId
      }
      break
    }

    case 'close-io': {
      if (e.leadId) destinationData.data.leadId = e.leadId
      if (e.add_sequence) {
        destinationData.data.sequence = {
          sequenceId: e.sequence_id,
          accountId: e.sender_account_id,
          name: e.sender_name,
          email: e.sender_email,
        }
      }
      break
    }

    case 'twilio': {
      destinationData.data.messageInfo = {
        to: computedValues(userData, e.to, form),
        from: e.from,
        body: computedValues(userData, e.body, form),
      }
      break
    }

    case 'sendgrid-contacts': {
      if (e.listId) destinationData.data.listId = computedValues(userData, e.listId, form)
      break
    }

    case 'sendgrid': {
      destinationData.data.emailInfo = {
        templateId: e.templateId,
        body: computedValues(userData, e.body, form),
        subject: computedValues(userData, e.subject, form),
        to: computedValues(userData, e.to, form),
        toName: computedValues(userData, e.toName, form),
        cc: computedValues(userData, e.cc, form),
        bcc: computedValues(userData, e.bcc, form),
        from: e.from,
        fromName: e.fromName,
        fromSavvy: e.sendFromSavvy,
        formTitle: form.title,
      }
      break
    }

    case 'diahook': {
      if (e.outboundId) destinationData.data.outboundId = e.outboundId
      break
    }

    case 'salesforce': {
      if (e.accountId) destinationData.data.accountId = e.accountId
      break
    }

    case 'facebook-conversions': {
      if (e.pixelId) {
        const getCookieValue = name => {
          const matches = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
          return (matches && matches.pop()) || ''
        }
        const pixel = {
          pixelId: e.pixelId,
          eventId: `_${Math.round(Math.random() * 10000)}-${Math.round(Math.random() * 10000)}`,
        }
        if (navigator && navigator.userAgent) pixel.userAgent = navigator.userAgent
        if (e.eventName) pixel.eventName = e.eventName
        if (userData && userData.location_data) pixel.ipAddress = userData.location_data.ip
        pixel.sourceUrl = window.location.href
        const fbpValue = getCookieValue('_fbp')
        const fbcValue = getCookieValue('_fbc')
        pixel.fbp = fbpValue ? fbpValue : `fb.1.${Date.now()}.${pixelId}`
        if (fbcValue || fbclid) pixel.fbc = fbcValue ? fbcValue : `fb.1.${Date.now()}.${fbclid}`
        if (e.purchase_value) pixel.purchaseValue = e.purchase_value
        destinationData.data.pixel = pixel
      }
      break
    }

    case 'hubspot': {
      if (e.listId) destinationData.data.listId = computedValues(userData, e.listId, form)
      if (e.submit_as_form) {
        const formData = {}
        if (e.portalId) formData.portalId = e.portalId
        if (e.formId) formData.formId = e.formId
        formData.contextualFormData = {
          pageName: document.title,
          pageUri: window.location.href.split('#')[0],
        }
        // hubspotutk=67d27e575ab5f50a4a39f699e33f3b91;
        const utk =
          document.cookie &&
          document.cookie
            .split(';')
            .map(t => t && t.trim().split('='))
            .find(t => t && t[0] === 'hubspotutk')
        if (utk) formData.contextualFormData.hutk = utk[1]
        if (e.contactFields) formData.contactFields = e.contactFields
        destinationData.data.formData = formData
      }
      break
    }

    default:
      break
  }

  return destinationData
}

export function dataOutputValid(e, datastream, options, userData, finalData) {
  /* If in datastream mode, ignore any dataoutputs that do not have datastream turned on */
  if (!e.output) return false
  if (!(options && options.sendAll)) {
    if (datastream === true && !e.datastream) return false
    if (datastream === false && e.datastream) return false
    if (e.custom_trigger) {
      const triggers = e.triggers || []
      if (triggers.length > 0) {
        const triggerMatches =
          options && options.location
            ? evaluateTriggerCondition(e, options)
            : triggers.every(t => !Boolean(t.condition))
        if (!triggerMatches) return false
      }
    }
  }

  if (e.no_trigger_finish && options && options.sendAll) return false
  if (e.manual_trigger_only) return false
  if (e.ignoreTest && [true, 'true'].includes(userData.is_test)) return false
  if (e.output === 'hubspot' && e.datastream && datastream) {
    const mappingsIsEmail = Object.entries(e.mappings || {}).find(e => e[1] === 'email')
    const mappingKey = mappingsIsEmail ? mappingsIsEmail[0] : null
    const dataHasEmail = finalData.find(
      d => (mappingKey && d[0] === mappingKey) || d[0] === 'email'
    )
    if (!dataHasEmail || !dataHasEmail[1]) return false
    if (dataHasEmail[1]) {
      const isValid = validateInputBox(
        { validate_value: true, validation_formula: 'email_address' },
        dataHasEmail[1]
      )
      if (!isValid) return false
    }
  }
  return true
}

function evaluateTriggerCondition(e, options = {}) {
  const triggers = e.triggers || []
  return triggers.every(t => {
    const value = t.value
    if (value === undefined) return true
    if (options.location && !t.condition) return false
    const location = t.condition === options.location
    if (!location) return false
    const operator = t.operator
    const compareValue = options.locationValue

    switch (operator) {
      case 'exists':
        return compareValue !== undefined && compareValue !== null
      case 'not exists':
      case '!exists':
        return compareValue === undefined || compareValue === null
      case '>=':
        return Array.isArray(value) ? value.some(v => compareValue >= v) : compareValue >= value
      case '>':
        return Array.isArray(value) ? value.some(v => compareValue > v) : compareValue > value
      case '!=':
      case '!= / not in':
        return Array.isArray(compareValue)
          ? Array.isArray(value)
            ? value.every(v => !compareValue.includes(v))
            : !compareValue.includes(value)
          : Array.isArray(value)
          ? value.every(v => compareValue !== v)
          : compareValue !== value
      case 'includes':
      case '==':
      case '== / includes':
      default:
        return Array.isArray(compareValue)
          ? Array.isArray(value)
            ? value.some(v => compareValue.includes(v))
            : compareValue.includes(value)
          : Array.isArray(value)
          ? value.some(v => compareValue === v)
          : compareValue === value
    }
  })
}

export function createRefData(output, destinationData, formId, entryId, timestamp) {
  const refData = {
    string: JSON.stringify({ ...destinationData.data, destinations: [destinationData.output] }),
    status: OUTPUT_DOCUMENT_STATUS,
    formId,
    entryId: entryId || null,
    outputId: output.id,
    timestamp: timestamp || new Date().toISOString(),
  }
  if (destinationData.scrub) refData.scrub = destinationData.scrub
  if (!output.datastream && output.submit_limit) refData.submitLimit = output.submit_limit

  const outputHasTimeout = typeof output.timeout === 'number' && output.timeout > 0
  if (outputHasTimeout) {
    // timeout in ms
    refData.intervalEndAt = Date.now() + output.timeout * 1000
    refData.status = 'timeout'
  }
  if (output.eval_triggers) {
    refData.status = refData.status === 'timeout' ? 'eval-timeout' : 'eval'
    try {
      if (output.triggers) refData.triggers = JSON.stringify(output.triggers)
    } catch (error) {
      console.error(error)
    }
  }
  return refData
}

export function mapValues(data, valueMaps) {
  return data.reduce((acc, d) => {
    const [k, v] = d
    const baseValueMapObject = (valueMaps && valueMaps[k]) || {}
    const OMIT_VALUE = '_omit_value'
    if (Array.isArray(v)) {
      const newValues = v
        .map(val => {
          const mapValue = baseValueMapObject[val]
          const finalVal = mapValue || val
          return mapValue !== OMIT_VALUE ? finalVal : null
        })
        .filter(e => e)
      acc.push([k, newValues])
    } else {
      const mapValue = baseValueMapObject[v]
      const val = mapValue || v
      if (mapValue !== OMIT_VALUE) acc.push([k, val])
    }
    return acc
  }, [])
}

export async function runCustomOutput(e, userData, contextFunctions) {
  if (!e.code) {
    console.warn(`No custom code detected for custom data output ${e.id}`)
    return
  }
  if (!contextFunctions) console.log(contextFunctions)
  const code = `${e.code}; output(${JSON.stringify(userData)}, contextFunctions);`
  return await eval(code)
}

export function doExtraActions(output, data) {
  if (!output || !data) return
  switch (output.output) {
    case 'facebook-conversions': {
      if (data.pixel && typeof window.fbq === 'function') {
        const trackType = output.use_custom_event ? 'trackCustom' : 'track'
        window.fbq(trackType, data.pixel.eventName, undefined, { eventID: data.pixel.eventId })
      }
      break
    }

    default:
      break
  }
}

export function findOutputsInUse(form, outputId) {
  const outputs = new Set()
  const add = (k, location) => {
    if (k === outputId) outputs.add(location)
  }
  const addIfArray = (arr, location) => {
    if (Array.isArray(arr)) arr.forEach(k => add(k, location))
  }
  addIfArray(form.outputs_onopenpopup, 'open_popup')
  addIfArray(form.outputs_onclosepopup, 'close_popup')
  addIfArray(form.outputs_onloadflow, 'load_flow')
  addIfArray(form.outputs_onviewflow, 'view_flow')
  if (Array.isArray(form.pages))
    form.pages.forEach(p => {
      addIfArray(p.outputs_onload, `load ${p.key || p.id}`)
      addIfArray(p.outputs_oncomplete, `complete ${p.key || p.id}`)
      if (Array.isArray(p.components)) {
        p.components.forEach(c => {
          switch (c.type) {
            case 'BookMeeting': {
              addIfArray(c.outputs_tier_1, `${c.type} tier 1 ${c.key} in ${p.key || p.id}`)
              addIfArray(c.outputs_tier_2, `${c.type} tier 2 ${c.key} in ${p.key || p.id}`)
              addIfArray(c.outputs_tier_3, `${c.type} tier 3 ${c.key} in ${p.key || p.id}`)
              break
            }
            case 'CustomButton': {
              addIfArray(c.outputs_onclick, `on click  ${c.type} ${c.key} ${p.key || p.id}`)
              break
            }
            case 'StripeCheckout': {
              addIfArray(
                c.on_payment_complete_outputs,
                `on complete ${c.type} ${c.key} ${p.key || p.id}`
              )

              break
            }

            default:
              break
          }
        })
      }
    })

  return Array.from(outputs)
}

function addExtraComponentKeys(component) {
  if (!component) return
  const keys = []

  switch (component.type) {
    case 'StripeCheckout': {
      const componentIsCollectDetails =
        component.action === 'collect_details' || component.collect_details
      if (
        componentIsCollectDetails &&
        component.output_payment_method &&
        component.payment_method_key
      ) {
        keys.push(component.payment_method_key)
      }
      break
    }

    default:
      break
  }
  return keys
}

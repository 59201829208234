import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import Handlebars from 'handlebars'

import getButtons from '../../helpers/getButtons'
import { repeatPages } from '../../helpers/repeatPages'

/* Use by {{ default x y }}, where x = value and y = safeValue (usually primitive value) */
Handlebars.registerHelper('default', function(value, safeValue) {
  return new Handlebars.SafeString(value || safeValue)
})

export default function(userData, text, form, extra) {
  if (!text || typeof text !== 'string') return text

  const _display_values = getDisplayValues(userData, form)

  const fullUserData = { ...userData, _display_values, ...extra }

  const template = Handlebars.compile(text)

  return text.indexOf('{{') > -1
    ? isSingleReference(text)
      ? get(fullUserData, text.slice(2, -2))
      : template(fullUserData)
    : text
}

function isSingleReference(text) {
  return (
    text.slice(0, 2) === '{{' && text.slice(-2) === '}}' && text.slice(2, -2).indexOf('{{') === -1
  )
}

export function getDisplayValues(userData, form) {
  const displayValues = cloneDeep(userData)
  const buttonComps = repeatPages(form.pages || [], userData).reduce(
    (buttonComps, page) =>
      buttonComps.concat(
        (page.components || []).filter(
          c => c.type === 'OptionButtons' || c.type === 'OptionSelector'
        )
      ),
    []
  )

  buttonComps.forEach(
    c =>
      (displayValues[c.key] = replaceWithDisplayValues(
        displayValues[c.key],
        getButtons(c, userData) || []
      ))
  )

  return displayValues
}

function replaceWithDisplayValues(v, buttons) {
  if (Array.isArray(v)) return v.map(val => (buttons.find(b => b.key === val) || {}).text || val)

  return (buttons.find(b => b.key === v) || {}).text || v
}

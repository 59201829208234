import { pascalToProper } from '@/helpers/textStringConversions'

const separator = '-'

export function generateElementSelectors(elementType, page, component, elementTags, elementExtras) {
  return [
    'Flow-Element',
    elementType ? `ElementType-${elementType}` : null,
    ...(elementTags || []).map(tag => `ElementTag-${tag}`),
    ...Object.entries(elementExtras || {}).map(([k, v]) => `Element${k}-${v}`),
  ]
    .concat(
      (component ? generateComponentSelectors(component, page) : generatePageSelectors(page)).slice(
        1
      )
    )
    .filter(s => s)
}

export function generateComponentSelectors(component, page) {
  const bases = {
    ComponentType: [component.type],
    ComponentTag: component.tags || [],
    PageTag: (page && page.tags) || [],
    PageKey: page ? [page.key] : [],
    ComponentKey: [component.key],
  }
  const isInContainer = component.parent_key

  const classes = Object.entries(bases).reduce(
    (acc, [key, values]) => {
      values.forEach(v => {
        const selector = `${key}${separator}${v}`
        acc.push(selector)
        if (isInContainer) {
          const containerString = `InContainer${separator}${isInContainer}`
          acc.push(`${containerString}.${selector}`)
        }
      })
      return acc
    },
    ['Flow-Component']
  )
  return classes
}

export function generatePageSelectors(page) {
  if (!page) return []
  const bases = { PageKey: [page.key], PageTag: page.tags || [] }

  const classes = Object.entries(bases).reduce(
    (acc, [key, values]) => {
      values.forEach(v => acc.push(`${key}${separator}${v}`))
      return acc
    },
    ['Flow-Page']
  )
  return classes
}

export function componentClassToLabel(selector) {
  const isResponsive = selector.startsWith('.Flow-EntireFlow[max-width~=')
  const sel = isResponsive
    ? selector.slice(`.Flow-EntireFlow[max-width~='720px']`.length)
    : selector
  const tags = sel.split('.')
  const type = 'Component'
  const responsivePrefix = getResponsivePrefix(selector)

  const tagsMap = tags.reduce((acc, t) => {
    const [k, v] = t.split(separator)
    acc[k] = v
    return acc
  }, {})

  const componentType = tagsMap.ComponentType ? ` ${getFriendlyType(tagsMap.ComponentType)}` : ''

  const ending = tags
    .map(tag => {
      const [k, v] = tag.split(separator)
      switch (k) {
        case 'InContainer':
          return `in Container ${v}`
        case 'ComponentTag':
          return `with the ${v} tag`
        case 'ElementKey':
          return `with the key ${v}`
        case 'PageTag':
          return `in ${v}-tagged pages`
        case 'PageKey':
          return `in This Page (${v})`
        default:
          return ``
      }
    })
    .filter(e => e)
    .join(' and ')

  if (tagsMap.ElementKey) return `${responsivePrefix}${type} Children ${ending}`.trim()
  if (tagsMap.ComponentKey) return `${responsivePrefix}This ${type} ${ending}`.trim() // (${tagsMap.Key})`
  return `${responsivePrefix}All${componentType} ${type}s ${ending}`.trim()
}
export function pageClassToLabel(selector) {
  const isResponsive = selector.startsWith('.Flow-EntireFlow[max-width~=')
  const responsivePrefix = getResponsivePrefix(selector)
  /* Might need to adjust to deal with a space after the max width segment, might not */
  const sel = isResponsive
    ? selector.slice(`.Flow-EntireFlow[max-width~='720px']`.length)
    : selector
  const tags = sel.split('.')
  const type = 'Page'
  if (tags && tags[0] === 'Flow-Page') return `${responsivePrefix}All Pages`
  const friendlyType = getFriendlyType(type)

  const tagsMap = tags.reduce((acc, t) => {
    const [k, v] = t.split(separator)
    acc[k] = v
    return acc
  }, {})
  if (tagsMap.PageKey) return `${responsivePrefix}This Page (${tagsMap.PageKey})`

  const ending = tags
    .map(tag => {
      const [k, v] = tag.split(separator)
      switch (k) {
        case 'PageTag':
          return `with the ${v} tag`

        default:
          return ``
      }
    })
    .filter(e => e)
    .join(' and ')

  return `${responsivePrefix}All ${friendlyType}s ${ending}`.trim()
}

export function flowClassToLabel(selector) {
  switch (selector.replace('.Flow-Element.ElementType-', '')) {
    case '.Flow-EntireFlow':
      return 'Entire Flow'
    case 'FlowMain':
      return 'Main Flow Container'
    case 'FlowPopupOuter':
      return 'Popup Outer'
    case 'FlowPopupOverlay':
      return 'Popup Overlay'
    case 'FlowPopupContainer':
      return 'Popup Container'
    case 'FlowPopupCloseButton':
      return 'Popup Close Button'
    case 'FlowPopupCloseButtonIcon':
      return 'Popup Close Button Icon'
    case 'FlowPopupContainerBox':
      return 'Popup Container Box'
    default:
      return selector
  }
}

export function elementToIcon(elementKey) {
  if (!elementKey) return 'fluent:border-24-filled'
  const [root, state, child] = elementKey.split('__')
  if (root && state && child) {
    // Handle determining icon
  } else if (root && state) {
    // Handle determining icon
  } else if (root) {
    // Handle determining icon
    switch (root) {
      case 'PageHeader':
        return 'fluent:window-header-horizontal-20-filled'
      case 'PageLogo':
        return 'fluent:document-header-16-regular'
      case 'PageLogoImage':
        return 'akar-icons:image'
      case 'PageProgressBar':
        return 'carbon:progress-bar'
      case 'PageContents':
        return 'eos-icons:content-lifecycle-management'
      case 'PageComponents':
        return 'icon-park-outline:components'
      case 'PageComponentsList':
        return 'codicon:list-unordered'
      case 'Label':
        return 'bi:menu-button-fill'
      case 'OptionButtonList':
        return 'bi:menu-button-wide'
      case 'CustomButton':
      case 'SubmitButton':
        return 'mdi:gesture-tap-button'
      case 'OptionButtonCard':
        return 'ic:round-smart-button'
      case 'OptionButtonCardText':
        return 'carbon:string-text'
      case 'OptionButtonCardDescription':
        return 'ci:text-align-left'
      case 'OptionButtonCardIcon':
        return 'ant-design:check-circle-filled'
      case 'InputElement':
        return 'bi:input-cursor-text'
      case 'TextareaElement':
        return 'bi:textarea-resize'
      case 'ul':
        return 'bx:bx-list-ul'
      case 'ol':
        return 'bx:bx-list-ol'
      case 'li':
        return 'bi:view-list'
      case 'a':
        return 'bi:view-list'
      case 'strong':
        return 'bi:view-list'
      case 'i':
        return 'bi:view-list'

      default:
        break
    }
  }
  return null
}

export function getFriendlyType(type) {
  switch (type) {
    case 'OptionButtonCardText':
      return 'Button Text'
    case 'OptionButtonCardDescription':
      return 'Description'
    case 'OptionButtonCardIcon':
      return 'Button Icon'
    case 'OptionButtonCardIconImage':
      return 'Button Icon Image'
    case 'OptionButtonCardIconImageElement':
      return 'Button Icon Image Element'
    case 'OptionButtonList':
      return 'Button List'
    case 'OptionDropdown':
      return 'Button Dropdown'
    case 'InputElement':
      return 'Input'
    case 'TextareaElement':
      return 'Text Area'
    case 'CustomButton':
    case 'SubmitButton':
    case 'OptionButtonCard':
      return 'Button'
    case 'PlainText':
      return 'Plain Text'
    case 'RichText':
      return 'Rich Text'
    case 'ul':
      return 'Bullet List'
    case 'ol':
      return 'Number List'
    case 'li':
      return 'List Item'
    case 'a':
      return 'Hyperlink Item'
    case 'i':
      return 'Italicized Item'
    case 'strong':
      return 'Bolded Item'

    default:
      return type && pascalToProper(type === 'Page' ? type : type.replace(/^Page/, ''))
  }
}

function getResponsivePrefix(selector) {
  const start = `.Flow-EntireFlow[max-width~='`.length
  const end = start + 3

  switch (selector.slice(start, end)) {
    case '720':
      return `Tablet: `
    case '520':
      return `Mobile: `
    default:
      return ``
  }
}

<template>
  <div
    v-if="repeaterComponentData.length"
    :id="element_id"
    :class="[
      inPageClasses,
      {
        'is-picker-on-exact':
          this.id && _getHoveredComponentId() && _getHoveredComponentId() === this.id,
        'is-picked': isSelectedComponent,
        'is-picker-on': _getSelectionMode(),
      },
    ]"
    @mouseover="hoverComponent($event, id, true)"
    @mouseout="hoverComponent($event, id, false)"
    @click="selectComponent($event, id)"
  >
    <component
      v-for="(c, i) in repeaterComponentData"
      :key="c.id"
      class="component"
      :is="type"
      :componentId="id"
      :class="[
        generateComponentSelectors(c, page),
        `component-key-${key}`,
        ...(tags || []).map(tag => `component-tag-${tag}`),
        ...(tags || []).map(tag => `tag-${tag}`), // Backwards compatibility
        inPageClasses,
        {
          isLink: linkUrl,
          fullPageBackground,
          selected: isSelected,
          'validation-failed': validationFailed,
          required: isRequired,
        },
      ]"
      v-bind="{
        component: c,
        components,
        componentData: userData[c.key] || componentData,
        userData,
        form,
        page,
        pageId,
        pageMeetsRequirements,
      }"
      @update="onRepeaterUpdate(i, $event)"
      @go-to-page="$emit('go-to-page', $event)"
      @prev="onPrev"
      @next="onNext"
      @reset="$emit('reset')"
      @enter="onEnter"
      @update-form="$emit('update-form', $event)"
    />
  </div>
  <component
    v-else
    :id="element_id"
    class="component"
    :is="type"
    :componentId="id"
    :class="[
      generateComponentSelectors(component, page),
      `component-key-${key}`,
      ...(tags || []).map(tag => `component-tag-${tag}`),
      ...(tags || []).map(tag => `tag-${tag}`), // Backwards compatibility
      inPageClasses,
      {
        isLink: linkUrl,
        fullPageBackground,
        selected: isSelected,
        'validation-failed': validationFailed,
        'is-picker-on': _getSelectionMode(),
        'is-picker-on-exact':
          this.id && _getHoveredComponentId() && _getHoveredComponentId() === this.id,
        'is-picked': this.id && _getSelectedComponentId() && _getSelectedComponentId() === this.id,
      },
    ]"
    v-bind="{
      component,
      componentData,
      userData,
      form,
      page,
      pageId,
      pageMeetsRequirements,
      components,
    }"
    @mouseover.native="hoverComponent($event, id, true)"
    @mouseout.native="hoverComponent($event, id, false)"
    @click.native="selectComponent($event, id)"
    @update="onUpdate"
    @go-to-page="$emit('go-to-page', $event)"
    @prev="onPrev"
    @next="onNext"
    @reset="$emit('reset')"
    @enter="onEnter"
    @update-form="$emit('update-form', $event)"
  />
</template>

<script>
import { unpack } from '@/helpers/computed'

import QuickEditText from './helpers/QuickEditToggle'
import QuickEditToggle from './helpers/QuickEditToggle'
import { componentPassesValidation } from './helpers/validation'

import PlainText from './PlainText'
import RichText from './RichText'
import MediaImage from './MediaImage'
import MediaEmbed from './MediaEmbed'
import InputBox from './InputBox'
import AddressBox from './AddressBox'
import OptionSelector from './OptionSelector'
import CustomButton from './CustomButton'
import BookMeeting from './BookMeeting'
import FlowFooter from './FlowFooter.vue'
import NextFooterButton from './NextButton.vue'
import PrevFooterButton from './PrevButton.vue'
import PageTitle from './PageTitle.vue'
import Container from './Container.vue'
import PageSubtitle from './PageSubtitle.vue'
import PageHeadingLabel from './PageHeadingLabel.vue'
import PlaceholderComponent from './PlaceholderComponent.vue'
import { generateComponentSelectors, generateElementSelectors } from './helpers/labelGenerator'
import { defaultHeadingComponents } from '@/components/form/helpers/defaultComponents.js'

export default {
  name: 'FormComponent',
  components: {
    QuickEditText,
    QuickEditToggle,
    PlainText,
    RichText,
    MediaImage,
    MediaEmbed,
    InputBox,
    AddressBox,
    OptionSelector,
    CustomButton,
    BookMeeting,
    Calendly: BookMeeting,
    FlowFooter,
    NextFooterButton,
    PrevFooterButton,
    PageTitle,
    PageSubtitle,
    PageHeadingLabel,
    PlaceholderComponent,
    ProgressBar: () => import('./ProgressBar'),
    StripeCheckout: () => import('./StripeCheckout'),
    PaypalCheckout: () => import('./PaypalCheckout'),
    CalculatedBlock: () => import('./CalculatedBlock'),
    FileUpload: () => import('./FileUpload'),
    // VideoCall: () => import('./VideoCall'),
    CustomAuth: () => import('./CustomAuth'),
    RegisterForMeeting: () => import('./RegisterForMeeting'),
    Lottie: () => import('./Lottie'),
    Container, //: () => import('./Container'),
  },
  provide() {
    return {
      _isEditing: this.isEditing,
      // Gets Element Selectors
      _elSel: (elementType, elementTags, elementExtras) =>
        generateElementSelectors(
          elementType,
          this.page,
          this.component,
          elementTags,
          elementExtras
        ),
    }
  },
  inject: {
    _validationFailed: { default: () => () => {} },
    _getSelectionMode: { default: () => () => {} },
    _getHoveredComponentId: { default: () => () => null },
    _getSelectedComponentId: { default: () => () => null },
    _setHoveredComponentId: { default: () => () => {} },
    _setSelectedComponentId: { default: () => () => {} },
    _isEditing: { default: () => () => {} },
    _getRegistered: { default: () => () => {} },
  },
  props: {
    form: Object,
    page: Object,
    component: Object,
    components: Array,
    pageData: Object,
    userData: Object,
    pageId: String,
    componentTabGroupIndexes: Array,
    componentIndex: Number,
    pageMeetsRequirements: Boolean,
  },
  computed: {
    ...unpack('component', [
      'key',
      'id',
      'tags',
      'linkUrl',
      'element_id',
      'fullPageBackground',
      'isRequired',
    ]),
    isSelectedComponent() {
      return this._getSelectedComponentId() === this.component.id
    },
    isEditing() {
      if (this.component.protected || this.repeaterComponentData.length) return () => false
      return () =>
        this._getSelectionMode() && (this.isGeneratedDefaultComponent || this.isSelectedComponent) //this._isEditing
    },
    type() {
      const type = this.component && this.component.type
      return type === 'OptionButtons' ? 'OptionSelector' : type
    },
    componentData() {
      const data = this.pageData || this.userData
      return data && data[this.key || this.id]
    },
    validationFailed() {
      return (
        this._validationFailed() &&
        !componentPassesValidation(this.component, this.userData, this.form)
      )
    },
    isSelected() {
      const indexes = this.componentTabGroupIndexes || []
      return Object.entries(indexes[this.componentIndex] || {}).some(
        ([key, value]) => this.userData && (this.userData[key] || 0) === value
      )
    },
    repeaterKey() {
      return this.component.repeater_key
    },
    repeaterArray() {
      return this.repeaterKey ? this.userData[this.repeaterKey] || [] : null
    },
    repeaterComponentData() {
      if (!Array.isArray(this.repeaterArray)) return []
      return this.repeaterArray.map((c, i) => {
        const componentData =
          typeof c === 'object' ? { ...this.component, ...c } : { ...this.component }
        componentData.id = `${componentData.id}_${i}`
        if (typeof c !== 'object') {
          switch (this.type) {
            case 'PlainText':
            case 'RichText': {
              componentData.text = c
              break
            }

            default:
              break
          }
        }
        return componentData
      })
    },
    inPageClasses() {
      if (!this.page) return []

      const classes = [
        `cid-${this.component.id}`,
        `in-page-key-${this.page.key}`,
        ...(this.page.tags || []).map(tag => `in-page-tag-${tag}`),
      ]
      if (this.component.mask_recording) classes.push('savvy-disable')
      if (this.component.ignore_recording) classes.push('savvy-disable-input')
      return classes
    },
    isGeneratedDefaultComponent() {
      return Object.values(defaultHeadingComponents()).some(c => c.id === this.id)
    },
  },
  methods: {
    onRepeaterUpdate(index, e, extras) {
      if (typeof index === 'number' && Array.isArray(this.repeaterArray)) {
        const allKeysSame = this.repeaterComponentData.every(c => c.key === this.component.key)

        const newRepeaters = allKeysSame
          ? this.repeaterArray.map((c, i) => {
              const key = this.key || this.id
              const existingValue = (this.userData[key] && this.userData[key][i]) || null
              return index === i ? e[1] : existingValue
            })
          : e[1]
        const update = [e[0], newRepeaters]
        this.$emit('update', update, extras)
      }
    },
    onUpdate(e, extras) {
      this.$emit('update', e, extras)
    },
    onEnter() {
      this.$emit('enter', { component: this.component, componentIndex: this.componentIndex })
    },
    goToPage(...e) {
      this.$emit('go-to-page', ...e)
    },
    onPrev(...e) {
      this.$emit('prev', ...e)
    },
    onNext(...e) {
      this.$emit('next', ...e)
    },
    onUpdateForm(...e) {
      this.$emit('update-form', ...e)
    },
    hoverComponent(e, id, isOn) {
      if (this.isGeneratedDefaultComponent) return
      if (this._getSelectionMode()) {
        this._setHoveredComponentId(e, id, isOn)
      }
    },
    async selectComponent(e, id) {
      if (this.isGeneratedDefaultComponent) return
      if (!this._getSelectionMode()) return
      if (!this.isSelectedComponent) this._setSelectedComponentId(e, id)
      if (this.component.type === 'PlaceholderComponent') {
        /* Open modal */

        await this.$nextTick()
        const { modal } = this._getRegistered('editorAction') || {}
        if (typeof modal !== 'function') return
        modal('load-component-type', id)
        return
      }
    },
    generateComponentSelectors,
  },
}
</script>

<template>
  <div class="AddressBox input_box_shape">
    <label v-if="label">
      <span v-if="icon" class="icon fas" :class="`fa-${icon}`" />
      <QuickEditText
        :value="label"
        _key="label"
        class="label"
        placeholder="Address Box Label"
        @update-text="$emit('update-form', $event)"
      >
        <span>{{ label }} </span>
      </QuickEditText>
    </label>
    <v-select
      :filterable="false"
      :options="dataSource"
      v-bind="{ placeholder }"
      v-model="selected"
      @search="onSearch"
    >
      <span slot="no-options"></span>
    </v-select>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import vSelect from 'vue-select'

import { lookup } from '@/helpers/flowApi'
import { unpack } from '@/helpers/computed'

import QuickEditText from './helpers/QuickEditText.vue'

export default {
  name: 'AddressBox',
  components: {
    QuickEditText,
    vSelect,
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    userData: Object,
  },
  data() {
    return {
      selected: null,
      addresses: [],
      query: null,
      answer: null,
    }
  },
  computed: {
    ...unpack('component', ['key', 'icon', 'placeholder', 'us_only']),
    dataSource() {
      return this.addresses.map(({ placeId, text }) => ({
        value: placeId,
        label: text,
      }))
    },
    label() {
      return (
        this.component.label ||
        (this.component.key &&
          this.component.key
            .split(/-|_/)
            .filter(w => w)
            .map(w => w[0].toUpperCase() + w.slice(1))
            .join(' '))
      )
    },
    userDataValue() {
      const val = this.userData && this.userData[this.key]
      if (!val) return val
      if (!val.label) return { ...val, label: val.text || (val.parts || []).join(', ') }
      return val
    },
    debounceAnswer() {
      return debounce(this.updateAnswer, 250, { trailing: true, leading: false })
    },
    debounceAddress() {
      return debounce(this.getAddress, 250, { trailing: true, leading: false })
    },
  },
  watch: {
    userDataValue: {
      handler(v) {
        this.selected = v
      },
      immediate: true,
    },
    selected: {
      handler(v) {
        if (v === this.userDataValue) return
        if (v) this.onSelect(v.value)
      },
    },
    componentData: {
      handler(componentData) {
        this.query = componentData && componentData.text
      },
      immediate: true,
    },
  },
  methods: {
    updateAnswer(answer) {
      // const zipCodeUpdate = this.component.zip_code_key
      //   ? { [this.component.zip_code_key]: 'ZIP_CODE_VALUE' }
      //   : {}
      // this.$emit('update', [this.component.key || this.componentId, answer], zipCodeUpdate)

      this.$emit('update', [this.component.key || this.componentId, answer])
    },
    getAddress() {
      const { query } = this

      if (query) {
        const l = this.userData.location_data
        const location = (l && `${l.latitude},${l.longitude}`) || undefined
        const radius = location ? 500 : undefined
        const components = this.us_only ? `country:us` : undefined

        lookup('google_places', 'autocomplete', { query, location, radius, components }).then(
          data => {
            if (this.query === query)
              this.addresses = data.result.addresses.map(({ place_id, description, terms }) => ({
                placeId: place_id,
                text: description,
                parts: terms.map(({ value }) => value),
              }))
          }
        )
      }
    },
    onSelect(value) {
      const address = this.addresses.find(a => a.placeId === value)

      this.updateAnswer(address)
    },
    onSearch(searchText) {
      this.query = searchText
      if (searchText) this.debounceAddress()
      else this.addresses = []
    },
  },
}
</script>

<script>
export default {
  components: {
    PlainEditableText: () => import('../../utilities/PlainEditableText.vue'),
  },
  inject: { _isEditing: { default: () => () => false } },
  props: { value: String, _key: String, jsxKey: String, placeholder: String },
  data() {
    return {}
  },
  computed: {
    isEditing() {
      return this._isEditing()
    },
  },
  watch: {},
  methods: {
    update(e) {
      this.$emit('update-text', [this._key || this.jsxKey, e])
    },
  },
  render() {
    if (this.isEditing) {
      return (
        <PlainEditableText
          initialText={this.value}
          placeholder={this.placeholder}
          on-update-text={this.update}
        />
      )
    } else {
      return this.$scopedSlots.default ? this.$scopedSlots.default() : null
    }
  },
}
</script>

<template>
  <div class="WindowEvent" />
</template>

<script>
export default {
  name: 'WindowEvent',
  inject: {
    _eventManager: { default: () => null },
  },
  props: {
    eventName: String,
    ignoreModifiers: Boolean,
  },
  mounted() {
    if (this._eventManager && this._eventManager.setEventListener) {
      this._eventManager.setEventListener(document, this.eventName, this.onTrigger)
    } else {
      document.addEventListener(this.eventName, this.onTrigger)
    }
  },
  beforeDestroy() {
    if (this._eventManager && this._eventManager.clearEventListener) {
      this._eventManager.clearEventListener(document, this.eventName, this.onTrigger)
    } else {
      document.removeEventListener(this.eventName, this.onTrigger)
    }
  },
  methods: {
    onTrigger(event) {
      if (this.ignoreModifiers) {
        if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
          this.$emit('on-trigger', event)
        }
      } else this.$emit('on-trigger', event)
    },
  },
}
</script>

export function getCleanUrl(url) {
  const start = url.split('?')[0]

  const noHttpWww = start
    .replace('https://www.', '')
    .replace('http://www.', '')
    .replace('https://', '')
    .replace('http://', '')

  if (noHttpWww.endsWith('/')) return noHttpWww.slice(0, -1)
  else return noHttpWww
}

<template>
  <div class="MediaEmbed" :class="{ roundedCorners, clickable: activated }">
    <QuickEditToggle>
      <template slot="editing">
        <Uploader @update="$emit('update-form', ['src', $event])">
          <video v-if="isVideoTag" controls :class="_elSel('Video')" v-bind="{ src }" />
          <div
            v-else
            style="position: relative; padding-bottom: 56.25%; height: 0;"
            :class="_elSel('IFrameContainer')"
          >
            <iframe
              :src="src"
              :class="_elSel('IFrame')"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
            />
          </div>
        </Uploader>
      </template>
      <template v-if="isVideoTag">
        <video
          v-if="activated && !silentAutoplayPreview"
          :class="_elSel('Video')"
          key="actual"
          :autoplay="centralPlayButton"
          controls
          v-bind="{ src }"
        />
        <img
          v-else-if="!activated && centralPlayButton && component.placeholder_image_url"
          :src="component.placeholder_image_url"
          :class="_elSel('PlaceholderImage')"
          alt="Placeholder"
        />
        <video
          v-else
          :class="_elSel('Video')"
          key="preview"
          :autoplay="silentAutoplayPreview"
          loop
          muted
          v-bind="{ src }"
        />
      </template>
      <div
        style="width: 100%; height: auto;"
        :class="_elSel('Embed')"
        v-else-if="embedCode && embedCode.trim()"
        v-html="embedCode"
      />
      <div
        v-else
        style="position: relative; padding-bottom: 56.25%; height: 0;"
        :class="_elSel('IFrameContainer')"
      >
        <iframe
          :src="src"
          :class="_elSel('IFrame')"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
        />
      </div>
    </QuickEditToggle>
    <button
      v-if="centralPlayButton && !shouldHideControls && !activated"
      type="button"
      class="video-play-button"
      :class="_elSel('PlayButton')"
      @click="userActivated = true"
    >
      <Icon icon="fa-solid:play" />
    </button>
  </div>
</template>

<script>
import { unpack } from '@/helpers/computed'

import Icon from './Icon.vue'
import computedValues from './editor/helpers/computedValues'

export default {
  name: 'MediaEmbed',
  components: {
    Icon,
    QuickEditToggle: () => import('./helpers/QuickEditToggle.vue'),
    Uploader: () => import('./editor/helpers/Uploader.vue'),
  },
  inject: {
    _elSel: { default: () => () => [] },
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    userData: {},
    form: {},
  },
  data() {
    return {
      userActivated: false,
    }
  },
  computed: {
    ...unpack('component', [
      'roundedCorners',
      'silentAutoplayPreview',
      'hide_controls',
      'centralPlayButton',
      'embed_code',
      'embed_code_raw',
    ]),
    src() {
      return computedValues(this.userData, this.component.src, this.form)
    },
    embedCode() {
      return this.embed_code_raw || computedValues(this.userData, this.embed_code, this.form)
    },
    scriptSrc() {
      switch (this.service) {
        default:
          return 'https://play.vidyard.com/embed/v4.js'
      }
    },
    shouldHideControls() {
      return this.silentAutoplayPreview && this.hide_controls
    },
    isVideoTag() {
      return (
        this.src &&
        (this.src
          .toLowerCase()
          .split('?')[0]
          .endsWith('.mp4') ||
          this.src
            .toLowerCase()
            .split('?')[0]
            .endsWith('.mov'))
      )
    },
    activated() {
      return this.userActivated || !this.centralPlayButton
    },
  },
  mounted() {
    this.loadScriptTag()
    // this.loadVidly()
  },
  methods: {
    loadScriptTag() {
      if (!this.scriptSrc) return
      const self = this
      let script = document.createElement('script')
      script.setAttribute('src', this.scriptSrc)
      script.onload = () => {
        self.loadVidly()
      }
      document.head.appendChild(script)
    },
    loadVidly() {
      if (window.vidyardEmbed) window.vidyardEmbed.api.renderDOMPlayers()

      // window['onVidyardAPI'] = vidyardEmbed => {
      //   vidyardEmbed.api.renderDOMPlayers()
      // }
    },
  },
}
</script>

import camelCase from 'lodash/camelCase'
import computedValues from '../components/form/editor/helpers/computedValues'

export function unpack(mainKeyOrObj, keys) {
  return keys.reduce((acc, key) => {
    acc[key] = function() {
      return this[mainKeyOrObj] && this[mainKeyOrObj][key]
    }
    return acc
  }, {})
}

export function unpackToCamel(mainKeyOrObj, keys) {
  return keys.reduce((acc, key) => {
    const newKey = camelCase(key)
    acc[newKey] = function() {
      return this[mainKeyOrObj] && this[mainKeyOrObj][key]
    }
    return acc
  }, {})
}

export function unpackWithComputed(mainKeyOrObj, keys, userData, form) {
  return keys.reduce((acc, key) => {
    const fn = function() {
      const val = this[mainKeyOrObj] && this[mainKeyOrObj][key]
      return computedValues(userData || this.userData, val, form || this.form)
    }

    acc[key] = fn
    return acc
  }, {})
}

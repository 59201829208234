import computedValues from '../editor/helpers/computedValues'
// import emailBlacklist from './emailBlacklist.json'
import debug from '@/helpers/debug'
// import { parsePhoneNumber, isValidNumber } from 'libphonenumber-js'

const emailBlacklist = {}
let parsePhoneNumber = () => {}
let isValidNumber = () => {}
;(async function importEmailBlacklist() {
  const list = await import('./emailBlacklist.json')
  Object.entries(list).forEach(([k, v]) => (emailBlacklist[k] = v))
  const { parsePhoneNumber: parseNum, isValidNumber: isValidNum } = await import(
    'libphonenumber-js'
  )
  parsePhoneNumber = parseNum
  isValidNumber = isValidNum
})()

export function validateInputBox(component, dataVal, isValid, userData) {
  if (component.input_type === 'phone') {
    if (component.isRequired) return isValid === undefined ? false : isValid
    return isValid === undefined ? true : isValid
  }
  if (component.input_type === 'confirm') {
    const isEqualValues = dataVal === true
    return component.isRequired ? isEqualValues : dataVal === undefined ? true : isEqualValues
  }

  if (!component.validate_value) return true

  switch (component.validation_formula) {
    case 'email_address': {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(dataVal).toLowerCase()) && !`${dataVal}`.endsWith('.con')
    }

    case 'business_email': {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const domain = String(dataVal).split('@')[1]
      return (
        re.test(String(dataVal).toLowerCase()) &&
        !emailBlacklist[domain] &&
        !`${dataVal}`.endsWith('.con')
      )
    }

    case 'full_name':
      return dataVal.trim().split(' ').length > 1

    case 'custom': {
      const validationString = component.custom_validation_function || `() => false`
      const fn = Function(`"use strict";return (${validationString})`)
      return fn()(dataVal, userData)
    }
    case 'phone_number_us': {
      try {
        const country = 'US'
        const number = parsePhoneNumber(dataVal, country)
        return number ? isValidNumber(number.formatNational(), country) : false
      } catch (error) {
        debug.error(error)
        return false
      }
    }

    default:
      return true
  }
}

export function componentPassesValidation(c, userData, form) {
  /* If not required + no validation, pass */
  if (!c.isRequired && !c.validate_value) return true
  const dataVal = userData[c.key || c.id] || computedValues(userData, c.defaultVal, form)
  /* If no value, passes if not required, else fails */
  if (!dataVal) return !c.isRequired
  // if (c.isRequired && !dataVal) return false

  /* Evaluate based on type of component */
  switch (c.type) {
    case 'InputBox':
      return validateInputBox(c, dataVal, true, userData)

    case 'StripeCheckout': {
      if (!c.isRequired) return true
      return c.collect_details ? dataVal === 'card_added' : dataVal === 'paid'
    }

    case 'CustomAuth': {
      if (!c.isRequired) return true
      return dataVal === 'signed_in'
    }

    default:
      return !Array.isArray(dataVal) || dataVal.length > 0
  }

  // switch (c.type) {
  //   case 'InputBox':
  //     return dataVal ? validateInputBox(c, dataVal) : true

  //   case 'StripeCheckout':
  //     return dataVal && c.collect_details ? dataVal === 'card_added' : dataVal === 'paid'

  //   default:
  //     return dataVal && (!Array.isArray(dataVal) || dataVal.length)
  // }
}

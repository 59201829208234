<template>
  <div
    class="footer-nav"
    :class="_elSel('PageFooterButtonContainer')"
    v-if="page.showNav !== false"
  >
    <PrevButton
      v-bind="{ form, userData, page, pageMeetsRequirements, hide: !useDefaultPrev }"
      @prev="$emit('prev')"
    />
    <NextButton
      v-bind="{ form, userData, page, pageMeetsRequirements, hide: !useDefaultNext }"
      @update="$emit('update', $event)"
      @prev="$emit('prev')"
      @next="$emit('next', $event)"
      @enter="$emit('enter', $event)"
    />
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'

import PrevButton from './PrevButton.vue'
import NextButton from './NextButton.vue'

export default {
  name: 'FlowFooter',
  components: { PrevButton, NextButton },
  inject: {
    _elSel: { default: () => () => {} },
    _filterByConditions: { default: () => () => {} },
    _uniqueByKey: { default: () => () => {} },
    _getRecentlyClicked: { default: () => () => {} },
    _finish: { default: () => () => {} },
    _updateReactive: { default: () => () => {} },
    _getFlowPages: { default: () => () => [] },
  },
  props: {
    form: Object,
    page: Object,
    userData: Object,
    pageMeetsRequirements: Boolean,
  },
  data() {
    return { finishPending: false }
  },
  computed: {
    fullPage() {
      return this._getFlowPages().find(p => p.id === this.page.id)
    },
    useDefaultPrev() {
      return ![...(this.form.components || []), ...(this.fullPage.components || [])].find(
        c => c.type === 'PrevFooterButton'
      )
    },
    useDefaultNext() {
      return ![...(this.form.components || []), ...(this.fullPage.components || [])].find(
        c => c.type === 'NextFooterButton'
      )
    },
  },
  watch: {},
  methods: {
    finish(event) {
      if (this.finishPending) this.finishPending = false
      else {
        event.preventDefault()

        this.finishPending = true
        this._finish().finally(() => this.$refs.finish.click())
      }
    },
    onUpdatePage(key, value) {
      const pageIndex = this.form.pages.findIndex(p => p.id === this.page.id)
      this._updateReactive(`pages.${pageIndex}`, key, value)
    },
    onUpdateNavButton(button, [key, value]) {
      const newButton = cloneDeep(button)
      newButton[key] = value
      this.onUpdatePage('custom_next_button', newButton)
      // this.$emit('update-form', ['custom_next_button', newButton])
    },
  },
}
</script>

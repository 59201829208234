var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"GridLayout",class:("uid-" + _vm._uid),style:({
    'grid-template-columns': ("repeat(" + _vm.cols + ", auto)"),
    'grid-template-rows': ("repeat(" + _vm.rows + ", auto)"),
    'grid-auto-columns': _vm.colSizing,
    'grid-auto-rows': _vm.rowSizing,
  })},[_vm._l((_vm.blocks),function(block,index){return _c('div',{key:block.join('/'),staticClass:"GridLayoutBlock",class:[
      ("block-" + (index + 1)) ].concat( (((_vm.layoutBlocks || [])[index] || {}).tags || []).map(function (tag) { return ("tag-" + tag); }),
      [{
        selected: Object.entries(_vm.tabGroupIndexes[index] || {}).some(
          function (ref) {
            var key = ref[0];
            var value = ref[1];

            return _vm.userData && (_vm.userData[key] || 0) === value;
  }
        ),
      }] ),on:{"click":function($event){return _vm.onClick(index)}}},[_vm._t("default",null,null,{ block: block, index: index })],2)}),_c('v-style',{attrs:{"type":"text/css"}},[_vm._v(" "+_vm._s(_vm.styleText)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    class="OptionSelector OptionButtons"
    ref="root"
    :class="[
      layoutClass,
      `options-selected-${multiple ? (componentData || []).length : componentData ? 1 : 0}`,
      {
        isDropdown: dropdown,
        input_box_shape: dropdown,
        isOpen,
        has_query: Boolean(query),
        'validation-passed': hasAnswer,
      },
    ]"
  >
    <label
      v-if="label && label.trim()"
      key="label"
      :class="_elSel('Label', ['OptionSelectorLabel'])"
    >
      <span v-if="icon" class="icon fas" :class="`fa-${icon}`" />
      <PlainRichTextToggle
        :rich="component && component.label_rich"
        _key="label"
        class="label"
        placeholder="Input Label"
        :text="label"
        v-bind="{ form, userData }"
        @update-form="$emit('update-form', $event)"
        @update="$emit('update', $event)"
      >
        <span>{{ label }}</span>
      </PlainRichTextToggle>
    </label>
    <template v-if="useSimpleDropdown">
      <select
        ref="dropdown"
        :class="_elSel('OptionDropdown', ['Dropdown'])"
        :multiple="multiple"
        :required="component && component.isRequired"
        v-model="dropdownSelected"
      >
        <option
          v-for="button in buttons"
          :key="button.id"
          :value="button.key"
          :selected="optionIsSelected(button.key, button.text)"
        >
          {{ button.text }}
        </option>
      </select>
    </template>
    <v-select
      v-else-if="dropdown"
      key="select"
      label="text"
      :class="[{ multiple }, ..._elSel('OptionDropdown', ['Dropdown'])]"
      :filterable="!fetching"
      :searchable="allow_typing !== false"
      :clearable="clearable !== false"
      :options="buttons"
      v-bind="{ placeholder, multiple }"
      v-model="dropdownSelected"
      @search:focus="isOpen = true"
      @search:blur="isOpen = false"
      @search="debouncedOnType"
      @input="query = $event"
    >
      <template #no-options="{ searching, loading }">
        {{
          loading
            ? `Searching...`
            : searching
            ? no_results_text || `No results found`
            : search_prompt_text || `Type to search...`
        }}
      </template>
    </v-select>
    <transition-group
      v-else
      :name="component.transition ? component.transition.name : 'list'"
      tag="div"
      class="buttons"
      :class="_elSel('OptionButtonList', ['ButtonList'])"
      :css="false"
      @before-enter="transitionBeforeEnter"
      @enter="transitionEnter"
      @leave="transitionLeave"
    >
      <template v-if="builderVersion >= 2">
        <div
          class="ButtonCard button-animate"
          v-for="{ id, key, text, description, icon, emojiIcon, imageUrl, triggerEvent } in buttons"
          :key="id || key"
          :class="[
            { selected: optionIsSelected(key, text), justClicked: recentlyClicked[key] },
            ..._elSel('OptionButtonCard', ['Button'], { Key: key }),
          ]"
          @click="select(key, triggerEvent)"
        >
          <Checkbox
            v-if="component.checkbox && component.checkbox_location === 'start'"
            :class="_elSel('OptionButtonCardCheckbox', ['Checkbox'], { Key: key })"
            :selected="optionIsSelected(key, text)"
          />
          <span
            class="button-icon img"
            :class="
              _elSel(
                'OptionButtonCardIconImage',
                ['GeneralButtonIcon', 'OptionButtonCardIcon', 'ButtonIconImage', 'Icon'],
                { Key: key }
              )
            "
            v-if="imageUrl"
          >
            <img
              :class="_elSel('OptionButtonCardIconImageElement', [], { Key: key })"
              :src="imageUrl"
            />
          </span>
          <span
            class="button-icon"
            :class="
              _elSel(
                'OptionButtonCardIcon',
                ['GeneralButtonIcon', 'OptionButtonCardIcon', 'Icon'],
                { Key: key }
              )
            "
            v-else-if="emojiIcon"
          >
            {{ emojiIcon }}
          </span>
          <Icon
            class="button-icon"
            :class="
              _elSel(
                'OptionButtonCardIcon',
                ['GeneralButtonIcon', 'OptionButtonCardIcon', 'Icon'],
                { Key: key }
              )
            "
            v-else-if="icon"
            v-bind="{ icon }"
          />
          <PlainRichTextToggle
            :rich="richText"
            _key="text"
            :text="computeValues(text)"
            :class="_elSel('OptionButtonCardText', ['ButtonText'], { Key: key })"
            v-bind="{ form, userData }"
            @update-form="updateButton(id, $event)"
            @update="$emit('update', $event)"
          >
            <div>{{ computeValues(text) }}</div>
          </PlainRichTextToggle>
          <PlainRichTextToggle
            v-if="
              description ||
                (editorActions.getShowButtonDescriptions &&
                  editorActions.getShowButtonDescriptions())
            "
            :rich="richDescription"
            _key="description"
            placeholder="Optional - Description"
            :condition="showDescription(description)"
            :text="computeValues(description)"
            :class="_elSel('OptionButtonCardDescription', ['ButtonDescription'], { Key: key })"
            v-bind="{ form, userData }"
            @update-form="updateButton(id, $event)"
            @update="$emit('update', $event)"
          >
            <div v-if="description" class="description">
              {{ computeValues(description) }}
            </div>
          </PlainRichTextToggle>
          <Checkbox
            v-if="component.checkbox && component.checkbox_location === 'end'"
            :selected="optionIsSelected(key, text)"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="ButtonCard button-animate"
          v-for="{ id, key, text, description, icon, emojiIcon, imageUrl, triggerEvent } in buttons"
          :key="id || key"
          :class="[
            { selected: optionIsSelected(key, text), justClicked: recentlyClicked[key] },
            ..._elSel('OptionButtonCard', ['Button'], { Key: key }),
          ]"
          @click="select(key, triggerEvent)"
        >
          <PlainRichTextToggle
            :rich="richDescription"
            _key="description"
            placeholder="Optional - Description"
            :condition="showDescription(description)"
            :text="computeValues(description)"
            :class="_elSel('OptionButtonCardDescription', ['ButtonDescription'], { Key: key })"
            v-bind="{ form, userData }"
            @update-form="updateButton(id, $event)"
            @update="$emit('update', $event)"
          >
            <div v-if="description" class="description">
              {{ computeValues(description) }}
            </div>
          </PlainRichTextToggle>
          <PlainRichTextToggle
            :rich="richText"
            _key="text"
            :text="computeValues(text)"
            :class="_elSel('OptionButtonCardText', ['ButtonText'], { Key: key })"
            v-bind="{ form, userData }"
            @update-form="updateButton(id, $event)"
            @update="$emit('update', $event)"
          >
            <div>{{ computeValues(text) }}</div>
          </PlainRichTextToggle>
          <span
            class="button-icon img"
            :class="
              _elSel(
                'OptionButtonCardIconImage',
                ['GeneralButtonIcon', 'OptionButtonCardIcon', 'ButtonIconImage', 'Icon'],
                { Key: key }
              )
            "
            v-if="imageUrl"
          >
            <img
              :class="_elSel('OptionButtonCardIconImageElement', [], { Key: key })"
              :src="imageUrl"
            />
          </span>
          <span
            class="button-icon"
            :class="
              _elSel('OptionButtonCardIcon', [
                'GeneralButtonIcon',
                'OptionButtonCardIcon',
                'Icon',
                { Key: key },
              ])
            "
            v-else-if="emojiIcon"
          >
            {{ emojiIcon }}
          </span>
          <Icon
            class="button-icon"
            :class="
              _elSel('OptionButtonCardIcon', [
                'GeneralButtonIcon',
                'OptionButtonCardIcon',
                'Icon',
                { Key: key },
              ])
            "
            v-else-if="icon"
            v-bind="{ icon }"
          /></div
      ></template>
    </transition-group>
    <div
      class="validation-failed"
      v-if="
        form.disable_form_validation &&
          validationMessage &&
          !userData[component.key] &&
          hasRequested
      "
    >
      {{ validationMessage }}
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import shuffle from 'lodash/shuffle'
import vSelect from 'vue-select'
import Velocity from 'velocity-animate'

import { unpack } from '@/helpers/computed'
import computedValues from './editor/helpers/computedValues'
import conversionTrack from '@/components/form/helpers/conversionTrack'
// import { mobileCheck } from '@/components/form/helpers/mobileCheck'

import Icon from './Icon.vue'
import getButtons from './helpers/getButtons'
import PlainRichTextToggle from './PlainRichTextToggle.vue'
import Checkbox from '../utilities/Checkbox.vue'
let Debounce

export default {
  name: 'OptionSelector',
  components: {
    Icon,
    vSelect,
    PlainRichTextToggle,
    Checkbox,
  },
  inject: {
    _isEditing: { default: () => () => false },
    _elSel: { default: () => () => [] },
    _getBuilderVersion: { default: () => () => [] },
    _requestSubmitStatus: { default: () => () => [] },
    _getFlowPages: { default: () => () => [] },
    _getRegistered: { default: () => () => {} },
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    form: {},
    page: {},
    userData: {},
  },
  data() {
    return {
      query: '',
      recentlyClicked: {},
      isOpen: false,
      asyncOptions: [],
      hasRequested: false,
      searchText: '',
    }
  },
  computed: {
    ...unpack('component', [
      'key',
      'multiple',
      'dropdown',
      'maxSelections',
      'placeholder',
      'allow_typing',
      'clearable',
      'nextOnSelect',
      'layout',
      'icon',
      'richText',
      'richDescription',
      'fetch_options',
      'fetch_uri',
      'fetch_headers',
      'fetch_preset',
      'fetch_preset_location_type',
      'fetch_transformer',
      'fetch_debounce',
      'store_data_type',
      'store_full_option_data',
      'no_results_text',
      'search_prompt_text',
    ]),
    builderVersion() {
      return this._getBuilderVersion()
    },
    useSimpleDropdown() {
      return false
      // return Boolean(
      //   this.dropdown &&
      //     !this.allow_typing &&
      //     !this.component.disable_vanilla_dropdown &&
      //     mobileCheck()
      // )
    },
    defaultVal() {
      return computedValues(this.userData, this.component.defaultVal || '', this.form)
    },
    layoutClass() {
      return !this.layout ? 'button_tiles' : this.layout
    },
    label() {
      if (!this.component.label) return ''
      return this._isEditing()
        ? this.component.label
        : computedValues(
            this.userData,
            `${this.component.label}${
              this.form.required_labels && this.component.isRequired ? ' *' : ''
            }`,
            this.form
          )
    },
    fetching() {
      return this.fetch_uri || this.fetch_preset
    },
    storeDataType() {
      return this.store_data_type || this.fetch_options ? 'full_object' : 'key'
    },
    selectedOptionFull() {
      switch (this.storeDataType) {
        case 'full_object':
          return this.componentData
        case 'text':
        case 'key':
        default:
          return (this.asyncOptions || this.buttons).find(
            o => o[this.storeDataType] === this.componentData
          )
      }
    },
    buttons() {
      // Always array of objects { key, text }
      const buttons = this.fetching
        ? this.asyncOptions
            .concat(this.selectedOptionFull ? [this.selectedOptionFull] : [])
            .unique('key')
        : getButtons(this.component, this.userData).filter(b => !b.hide)
      const matchesSearchText = text => text.includes(this.searchText)
      const validButtons = buttons.filter(
        b =>
          (b.key || b.id) &&
          (this.searchText ? matchesSearchText(b.key) || matchesSearchText(b.text) : true) &&
          (b.hide_if_no_nav_target ? this.buttonHasNavTarget(b) : true)
      )
      const shouldRandomize = Boolean(this.component.randomize_buttons)
      if (Array.isArray(this.component.top_keys)) {
        const keys = new Set(this.component.top_keys)
        const front = validButtons.filter(b => keys.has(b.key))
        const back = validButtons.filter(b => !keys.has(b.key))
        return [...front, ...back]
      }
      const shouldTransform = Boolean(this.component.frontend_transform_fn)
      if (shouldTransform) {
        const transformerFn = `${this.component.frontend_transform_fn}; optionTransformer(validButtons)`
        const transformedButtons = eval(transformerFn)
        return shouldRandomize ? shuffle([...transformedButtons]) : transformedButtons
      }
      return shouldRandomize ? shuffle([...validButtons]) : validButtons
    },
    dropdownSelected: {
      get() {
        // storeDataType --> object { key, text }
        if (this.useSimpleDropdown) return this.componentData
        /* Only covers case where expecting an array returned, if not multiple then copy and replace .filter with .find */
        if (this.buttons.some(b => typeof b !== 'string')) {
          const seenData = new Set(
            Array.isArray(this.componentData) ? this.componentData : [this.componentData]
          )
          return this.multiple
            ? this.buttons.filter(b => seenData.has(b) || seenData.has(b.key))
            : this.buttons.find(b => seenData.has(b) || seenData.has(b.key))
        }
        return this.componentData && this.component && this.storeDataType !== 'full_object'
          ? this.buttons.find(b => b[this.storeDataType] === this.componentData)
          : this.componentData
      },
      set(val) {
        // object { key, text } --> storeDataType
        if (this.fetch_options) {
          !val
            ? this.select(null, null)
            : this.select(this.storeDataType === 'full_object' ? val : val[this.storeDataType])
        } else {
          const value = typeof val === 'string' ? { key: val } : val
          if (this.useSimpleDropdown && this.$refs.input)
            this.$refs.input.setCustomValidity(this.getValidationMessage(value.key))
          if (this.multiple) {
            const vals = Array.from(new Set(value.map(v => (typeof v === 'string' ? v : v.key))))
            this.select(vals, undefined, true)
          } else
            val ? this.select(value.key || value.id, value.triggerEvent) : this.select(null, null)
        }
      },
    },
    requestSubmitStatus() {
      return this._requestSubmitStatus()
    },
    hasAnswer() {
      return Boolean(this.userData[this.component.key])
    },
    validationMessage() {
      return this.getValidationMessage(this.userData[this.component.key])
    },
    editorActions() {
      return this._getRegistered('editorAction') || {}
    },
    debouncedOnType() {
      return debounce(this.onType, 250)
    },
  },
  async mounted() {
    if (this.useSimpleDropdown && this.$refs.input)
      this.$refs.input.setCustomValidity(this.validationMessage)
    if (this.component.search_options_frontend) {
      const loading = () => {}
      this.search('', loading, this)
    }

    await this.$nextTick()
    const inputs = this.$refs.root.querySelectorAll('input.vs__search')
    if (inputs)
      inputs.forEach(el => {
        el.autocomplete = 'off'
      })
  },
  watch: {
    defaultVal: {
      handler(v) {
        if (!v || this.componentData !== undefined) return
        const componentData = this.multiple ? toggle([], v, this.maxSelections, this.buttons) : v

        this.$emit('update', [this.key || this.componentId, componentData])
      },
      immediate: true,
    },
    hasAnswer: {
      handler(v) {
        if (v) this.hasRequested = false
        if (this.requestSubmitStatus && !v) this.hasRequested = true
      },
    },
    requestSubmitStatus: {
      handler(v) {
        if (v) this.hasRequested = true
      },
    },
    fetch_debounce: {
      handler(fetch_debounce) {
        Debounce = fetch_debounce
      },
      immediate: true,
    },
  },
  methods: {
    transitionBeforeEnter(el) {
      el.style.opacity = 0
      el.style.height = 0
    },
    transitionEnter(el, done) {
      var delay = el.dataset.index * 150
      setTimeout(function() {
        Velocity(el, { opacity: 1, height: '100%' }, { complete: done })
      }, delay)
    },
    transitionLeave(el, done) {
      var delay = el.dataset.index * 150
      setTimeout(function() {
        Velocity(el, { opacity: 0, height: 0 }, { complete: done })
      }, delay)
    },
    updateButton(id, [k, v]) {
      let component = (this.form.components || []).find(c => c.id === this.component.id)
      if (!component) {
        const page = this.form.pages.find(p => p.id === this.page.id)
        component = page.components.find(c => c.id === this.component.id)
      }
      const bt = component.buttons.map(b => (b.id === id ? { ...b, [k]: v } : b))

      this.$emit('update-form', ['buttons', bt])
    },
    getKeyFromVal(val) {
      switch (this.storeDataType) {
        case 'full_object':
          return (this.asyncOptions || this.buttons).find(o => o.key === val)
        case 'text':
        case 'key':
        default:
          return this.val
      }
    },
    async select(val, triggerEvent, skipToggle) {
      const key = this.getKeyFromVal(val)
      if (this._isEditing()) return
      if (key) {
        this.$set(this.recentlyClicked, key, false)
        setTimeout(() => this.$set(this.recentlyClicked, key, true), 10)
      }
      const componentData =
        this.multiple && !skipToggle
          ? toggle(this.componentData, val, this.maxSelections, this.buttons)
          : val

      this.$emit('update', [this.key || this.componentId, componentData])
      /* make sure that the update is finished for conditions */
      await this.$nextTick()
      await this.$nextTick()
      if (!this.dropdown && !this.multiple) {
        if (triggerEvent === 'open-url') {
          const button = this.buttons.find(b => b.key === val)
          if (button && button.url) {
            const url = button.url //.startsWith('http') ? button.url : `https://${button.url}`
            window.open(url, button.openUrlInNewTab ? '_blank' : '_self')
          }
        } else if (triggerEvent === undefined || triggerEvent === 'next-page') this.$emit('next')
      }

      conversionTrack(this.component, this.userData, this.form)

      if (this.store_full_option_data) {
        const fullOptionData = this.multiple
          ? componentData.map(_key => this.buttons.find(b => b.key === _key))
          : this.buttons.find(b => b.key === key)
        this.$emit('update', [this.store_full_option_data, fullOptionData])
      }
    },
    getValueToStore(key) {
      if (this.fetching) return this.buttons.find(b => b.key === key) || key
      return key
    },
    onType(searchText, loading) {
      if (this.component.search_options_frontend) {
        this.searchText = searchText
        return
      }
      if (!this.fetching || !searchText) return
      const searchMinLength = this.component.search_text_min_length
      if (typeof searchMinLength === 'number') {
        const canSearch = typeof searchText === 'string' && searchText.length >= searchMinLength
        if (!canSearch) return
      }
      loading(true)

      this.search(searchText, loading, this)
    },
    search: debounce((search, loading, vm) => {
      switch (vm.fetch_preset) {
        case 'location':
          {
            switch (vm.fetch_preset_location_type) {
              case 'places_autocomplete':
                return vm.searchPlacesAutocomplete(search, loading, vm)
            }
          }
          break

        default:
          return vm.customSearch(search, loading, vm)
      }
    }, Debounce || 350),
    customSearch(search, loading, vm) {
      const uri = computedValues(vm.userData, vm.fetch_uri, vm.form, {
        $query: encodeURIComponent(search),
      })

      fetch(uri, {
        headers: this.fetch_headers ? JSON.parse(this.fetch_headers) : {},
      })
        .then(res => {
          res.json().then(json => {
            // if (vm.fetch_transformer) {
            //   const fn = Function(`"use strict";return (${vm.fetch_transformer})`)
            //   vm.asyncOptions = fn()(json)
            // } else {
            //   vm.asyncOptions = json
            // }
            vm.asyncOptions = vm.fetch_transformer
              ? eval(`${vm.fetch_transformer}; transformer(${JSON.stringify(json)})`)
              : json
          })
          loading(false)
        })
        .catch(error => {
          console.error('Fetching Error!', error)
          loading(false)
        })
    },
    searchPlacesAutocomplete(search, loading, vm) {
      const google = window.google

      // const request = {
      //   query: search,
      //   fields: ['place_id', 'name', 'formatted_address'],
      // }
      const request = {
        input: search,
        fields: ['place_id', 'name', 'formatted_address'],
      }

      const service = new google.maps.places.AutocompleteService(document.createElement('div'))
      // const service = new google.maps.places.PlacesService(document.createElement('div'))

      // service.findPlaceFromQuery(request, function(results, status) {
      service.getPlacePredictions(request, function(results, status) {
        loading(false)

        if (status === google.maps.places.PlacesServiceStatus.OK) {
          vm.asyncOptions = results.map(p => ({ key: p.place_id, text: p.description }))
        }
      })
    },
    optionIsSelected(key, text) {
      return this.multiple && this.componentData
        ? this.componentData.includes(text) || this.componentData.includes(key)
        : this.componentData && (this.componentData === text || this.componentData === key)
    },
    getValidationMessage(key) {
      const isValid = this.component && this.component.isRequired ? Boolean(key) : true
      if (isValid) return ''
      if (!key && this.component.empty_invalid_message)
        return computedValues(this.userData, this.component.empty_invalid_message, this.form)
      if (key && this.component.invalid_message)
        return computedValues(this.userData, this.component.invalid_message, this.form)
      switch (this.component.validation_formula) {
        default:
          return 'Please fill out this field.'
      }
    },
    showDescription(description) {
      return [undefined].includes(this.layout) || Boolean(description)
    },
    computeValues(text) {
      return this._isEditing() ? text : computedValues(this.userData, text, this.form)
    },
    buttonHasNavTarget(button) {
      if (button && button.hide_if_no_nav_target) {
        const action = button.triggerEvent
        const currentPageId = this.userData.currentPageId
        const pages = this._getFlowPages(true)
        const currentPageIndex = pages.findIndex(p => p.id === currentPageId)
        switch (action) {
          case 'next-page':
            return Boolean(currentPageIndex < pages.length - 1)
          case 'prev-page':
            return Boolean(currentPageIndex > 0)
          default:
            break
        }
      }
      return true
    },
  },
}

function toggle(_arr, val, maxSelections, optionbuttons) {
  const buttons = optionbuttons || []
  const singleSelectKeys = new Set(buttons.filter(b => b.single_select).map(b => b.key))
  const button = buttons.find(b => b.key === val)
  if (button && button.single_select) return [val]
  const baseArr = Array.isArray(_arr) ? cloneDeep(_arr) : []
  const arr = baseArr.filter(v => !singleSelectKeys.has(v))

  const index = arr.indexOf(val)

  if (index === -1) {
    return [...arr, val].slice(-1 * maxSelections)
  } else {
    arr.splice(index, 1)
    return arr
  }
}
</script>

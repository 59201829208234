<template>
  <div class="Checkbox" :class="{ selected }">
    <Icon v-if="selected" name="check" icon="fa-solid:check" />
  </div>
</template>
<script>
// import Icon from './Icon.vue'
import Icon from '@/components/form/Icon.vue'
export default {
  components: { Icon },
  name: 'Checkbox',
  props: { selected: Boolean },
}
</script>

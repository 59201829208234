<script>
import { unpack } from '@/helpers/computed'
import computedValues from './editor/helpers/computedValues'
import emitSyntheticEvent from '@/helpers/emitSyntheticEvent'

import Icon from './Icon.vue'
import PlainRichTextToggle from './PlainRichTextToggle.vue'
import conversionTrack from '@/components/form/helpers/conversionTrack'

export default {
  components: {
    Icon,
    PlainRichTextToggle,
  },
  inject: {
    _setRedirectPopup: { default: () => () => {} },
    _finish: { default: () => () => {} },
    _triggerInfoBox: { default: () => () => {} },
    _setCheckpoint: { default: () => () => {} },
    _closePopup: { default: () => () => {} },
    _getSelectionMode: { default: () => () => {} },
    _elSel: { default: () => () => [] },
    _isEditing: { default: () => () => false },
    _updateUserData: { default: () => () => false },
    _resetUserData: { default: () => () => {} },
    _submitForm: { default: () => () => false },
    _sendIndividualDataOutput: { default: () => () => false },
    _getRegistered: { default: () => () => {} },
    _getFlowPages: { default: () => () => {} },
    _getComponentElement: { default: () => () => {} },
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    form: Object,
    userData: Object,
    pageMeetsRequirements: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      value: 0,
      justClicked: false,
      finishCompleted: false,
      isAsyncLoading: false,
    }
  },
  computed: {
    ...unpack('component', [
      'key',
      'toggle',
      'action',
      'actionUrl',
      'openUrlInNewTab',
      'shouldDownloadUrl',
      'downloadFilename',
      'info_box_key',
    ]),
    url() {
      // if (!this.actionUrl) return ''
      // const url = this.actionUrl.c ? this.actionUrl : `https://${this.actionUrl}`
      return computedValues(this.userData, this.actionUrl, this.form)
    },
    text() {
      let text
      const key = this.key || this.componentId
      if (this.component.use_custom_code && this.component.custom_code) {
        if (![undefined, null].includes(this.userData[key])) {
          if (this.isAsyncLoading) text = this.component.custom_code_loading_text || 'Loading...'
          else
            text = this.userData[key]
              ? this.component.custom_code_success_text || 'Success!'
              : this.component.custom_code_fail_text || 'Failed  - Submit Again'
        } else text = this.component.text
      } else text = this.component.text
      return this._isEditing() ? text : computedValues(this.userData, text, this.form)
    },
    description() {
      return this._isEditing()
        ? this.component.description
        : computedValues(this.userData, this.component.description, this.form)
    },
    buttonIsDisabled() {
      const componentPassesValidation =
        (this.component.needs_validation_passed && !this.pageMeetsRequirements) || this.disabled
      if (
        this.component.action &&
        this.component.action.startsWith('page-') &&
        this.component.disable_if_unreachable
      ) {
        const pageId = this.action.slice('page-'.length)
        switch (this.component.disable_if_unreachable) {
          case 'page-hidden': {
            const pages = this._getFlowPages(true)
            const page = pages.find(p => p.id === pageId)
            const condition = !page || page.hide
            if (condition) return true
            break
          }
          case 'page-in-front': {
            const pages = this._getFlowPages()
            const currentPageIndex = pages.findIndex(p => p.id === this.userData.currentPageId)
            const pageIndex = pages.findIndex(p => p.id === pageId)
            const condition = currentPageIndex < pageIndex
            if (condition) return true
            break
          }
          case 'page-not-reached': {
            const pages = this._getFlowPages()
            const pageIndex = pages.findIndex(p => p.id === pageId)
            const targetPageIndex = pages.findIndex(p =>
              this.userData.highest_page_reached_id
                ? p.id === this.userData.highest_page_reached_id
                : p.key === this.userData.highest_page_reached_key
            )
            const condition = targetPageIndex < pageIndex
            if (condition) return true
            break
          }
        }
      }

      return this.component.validation_show_state === 'always_show'
        ? false
        : componentPassesValidation
    },
    validationFailed() {
      return (
        (this.component.needs_validation_passed || this.component.isFinish) &&
        !this.pageMeetsRequirements
      )
    },
    shouldShowButton() {
      if (this.component && this.component.hide_if_no_nav_target) {
        const action = this.action
        const currentPageId = this.userData.currentPageId
        const pages = this._getFlowPages(true)
        const currentPageIndex = pages.findIndex(p => p.id === currentPageId)
        switch (action) {
          case 'next-page':
            return Boolean(currentPageIndex < pages.length - 1)
          case 'prev-page':
            return Boolean(currentPageIndex > 0)
          default:
            break
        }
      }
      return true
    },
  },
  methods: {
    async onClick(e, fromLink) {
      if (this._getSelectionMode()) return
      // if (this.validationFailed && this.form.show_next_button_on_incomplete === 'always')
      //   this.$emit('request-submit')
      /*

      Cases:
      * link + open same tab (not finish) clicks 1-2+
      * link + open same tab (finish) clicks 1-2+
      * link + open new tab (not finish) clicks 1-2+
      * link + open new tab (finish) clicks 1-2+
      * no link (not finish) clicks 1-2+
      * no link (finish) clicks 1-2+

      */
      /* If from a link and have submitted at least once, default to anchor tag behavior */
      const openInSameTab = fromLink && !this.openUrlInNewTab
      const sameTabFinishCompleted = openInSameTab && this.finishCompleted
      if (sameTabFinishCompleted) return

      const watchNav = this.form && this.form.emit_nav_events
      if (watchNav) e.preventDefault()
      const validationFailed =
        this.component.validation_show_state === 'always_show' ? false : this.validationFailed
      /* If finish is in progress we prevent the link behavior + stop any further action */
      const skipExecution = this.finishInProgress || validationFailed
      if (skipExecution) {
        e.preventDefault()
        return
      }
      /* if this is a link, stop default anchor tag behavior */

      if (e && typeof e.preventDefault === 'function' && !(this.openUrlInNewTab && fromLink)) {
        e.preventDefault()
        e.stopPropagation()
      }

      this.finishInProgress = true
      this.justClicked = false
      setTimeout(() => (this.justClicked = true), 10)

      const key = this.key || this.componentId
      let finished = false
      const context = { fromLink, finished, openInSameTab, event: e, watchNav }
      if (this.form.form_wrapper) {
        if (this.component.submit_form) this._submitForm()
        if (this.component.validation_show_state === 'always_show' && this.validationFailed) {
          this.finishInProgress = false
          return
        }
      }

      if (this.component.use_custom_code && this.component.custom_code) {
        const res = await this.runCustomCode()
        this.finishInProgress = false
        if (!res) return
      }

      conversionTrack(this.component, this.userData, this.form)
      const checkpoints = this.component.checkpoints || []
      if (checkpoints.length > 0) await this._setCheckpoint(checkpoints)

      const now = new Date().toISOString()
      const outputs = this.component.outputs_onclick
      if (Array.isArray(outputs))
        await Promise.all(outputs.map(k => this._sendIndividualDataOutput(k, now)))

      if (this.component.isFinish && !this.finishCompleted) {
        await this._finish(true)
        this.finishCompleted = true
      }

      if (this.toggle) {
        if (key && !this.component.custom_code) this.$emit('update', [key, !this.componentData])
      } else {
        this.value += 1
        if (key && !this.component.custom_code) this.$emit('update', [key, this.value])
        await this.$nextTick()
        await this.$nextTick()
        await this.doAction(this.action, context)
      }
      this.finishInProgress = false
    },
    async doAction(action, context) {
      switch (action) {
        case 'open-url': {
          if (context.watchNav) {
            if (context && context.event && context.event.preventDefault) {
              context.event.preventDefault()
            }
            emitSyntheticEvent('savvy.navigation', { url: this.url })
          } else {
            if (!context.openInSameTab) break
            const newWindow = window.open(this.url, this.openUrlInNewTab ? '_blank' : '_self')
            if (!newWindow) this._setRedirectPopup(this.url)
          }
          break
        }
        case 'open-info-box': {
          this._triggerInfoBox(this.info_box_key)
          break
        }
        case 'finish': {
          if (!this.finishCompleted) {
            await this._finish(true)
            this.finishCompleted = true
          }
          break
        }
        case 'next-page': {
          this.$emit('next', false, this.component.allow_skip_validation)
          break
        }
        case 'close-popup': {
          this._closePopup()
          break
        }
        case 'reset': {
          this.$emit('reset')
          break
        }
        case 'prev-page': {
          this.$emit('prev')
          break
        }
        default: {
          if ((this.action || '').startsWith('page-')) {
            const pageKey = this.action.slice(5)
            this.$emit('go-to-page', pageKey)
          }
          break
        }
      }
    },
    onUpdateForm(...args) {
      this.$emit('update-form', ...args)
    },
    onUpdate(...args) {
      this.$emit('update', ...args)
    },
    async runCustomCode() {
      const setUserData = this._updateUserData
      const resetUserData = this._resetUserData
      const contextFunctions = {
        setUserData,
        runAction: this.doAction,
        getComponentElement: this._getComponentElement,
        resetUserData,
        openInfoBox: this._triggerInfoBox,
      }
      if (!contextFunctions) console.log(contextFunctions)
      const key = this.key || this.componentId
      this.isAsyncLoading = true
      let finalRes
      try {
        const code = `${this.component.custom_code}; customHandler(${JSON.stringify(
          this.userData
        )}, contextFunctions);`
        const result = await eval(code)
        setUserData(key, result.success)
        if (this.component.output_key) {
          await this.$nextTick()
          setUserData(this.component.output_key, result.value)
        }
        finalRes = result.success
      } catch (error) {
        console.error(error)
        setUserData(key, false)
        // this.$emit('update', [key, false])
        if (this.component.output_key) {
          await this.$nextTick()
          const errorContext = (error.response && error.response.data) || error.message
          setUserData(this.component.output_key, errorContext)
        }
        finalRes = false
      }
      this.isAsyncLoading = false
      return finalRes
    },
  },
  render() {
    if (!this.shouldShowButton) return null
    const useWrapper = this.action === 'open-url'
    const isDisabled = this.buttonIsDisabled
    const divClasses = [
      'CustomButton',
      this.component.toggle && this.componentData === true && 'selected',
      isDisabled && 'disabled',
    ]
      .filter(e => e)
      .join(' ')

    const icon = this.component.icon ? (
      <Icon
        class={`button-icon ${this._elSel('ButtonIcon', [
          'ButtonIcon',
          'GeneralButtonIcon',
          'Icon',
        ]).join(' ')}`}
        icon={this.component.icon}
      />
    ) : this.component.emojiIcon ? (
      <span
        class={`icon button-icon ${this._elSel('ButtonIcon', [
          'ButtonIcon',
          'GeneralButtonIcon',
          'Icon',
        ]).join(' ')}`}
      >
        {this.component.emojiIcon}
      </span>
    ) : this.component.imageUrl ? (
      <span
        class={`img ${this._elSel('ButtonIconImage', [
          'ButtonIcon',
          'GeneralButtonIcon',
          'ButtonIconImage',
          'Icon',
        ]).join(' ')}`}
      >
        <img src={this.component.imageUrl} />
      </span>
    ) : null

    const displayAlternateText =
      useWrapper && this.url && this.finishCompleted && !this.openUrlInNewTab
    const text = displayAlternateText ? `Confirm Navigation to ${this.url}` : this.text
    const description = displayAlternateText ? `` : this.description

    const actions = this._getRegistered('editorAction') || {}
    const Description =
      this.description ||
      displayAlternateText ||
      (actions.getShowButtonDescriptions && actions.getShowButtonDescriptions()) ? (
        <PlainRichTextToggle
          rich={this.component.richDescription}
          jsxKey="description"
          placeholder="Button Description"
          text={description}
          form={this.form}
          userData={this.userData}
          onUpdate-text={this.onUpdateForm}
          onUpdate-form={this.onUpdateForm}
          onUpdate={this.onUpdate}
          class={this._elSel('ButtonDescription', ['ButtonDescription'])}
        >
          <div class="description">{description}</div>
        </PlainRichTextToggle>
      ) : null
    const Inner = (
      <div>
        <PlainRichTextToggle
          rich={this.component.richText}
          jsxKey="text"
          placeholder="Button Text"
          text={text}
          form={this.form}
          userData={this.userData}
          onUpdate-text={this.onUpdateForm}
          onUpdate-form={this.onUpdateForm}
          onUpdate={this.onUpdate}
          class={this._elSel('ButtonText', ['ButtonText'])}
        >
          <div class="text">{text}</div>
        </PlainRichTextToggle>
        {Description}
      </div>
    )

    if (
      this.component.validation_show_state === 'hide' &&
      this.validationFailed &&
      this.component.needs_validation_passed
    )
      return null
    const styles = this._getSelectionMode() ? { pointerEvents: 'auto !important' } : null
    return useWrapper ? (
      <a
        href={this.validationFailed ? null : this.url}
        target={this.openUrlInNewTab ? '_blank' : '_self'}
        rel="noopener noreferrer"
        class={`CustomButton_link animate ${this.justClicked ? 'justClicked' : ''} ${divClasses}`}
        disabled={isDisabled}
        download={this.shouldDownloadUrl && (this.downloadFilename || 'download')}
        onClick={e => this.onClick(e, true)}
        style={styles}
      >
        {icon}
        {Inner}
      </a>
    ) : (
      <div class={divClasses} disabled={isDisabled} onClick={this.onClick} style={styles}>
        {icon}
        {Inner}
      </div>
    )
  },
}
</script>

import chunk from 'lodash/chunk'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import flatten from 'lodash/flatten'
import { lookup } from '@/helpers/flowApi'

export default [
  {
    key: 'lookup_zipcode',
    name: 'Lookup Zipcode',
    group: 'async',
    fields: [
      {
        key: 'address',
        type: 'address',
      },
    ],
    async formula({ address }) {
      const placeId = address && (address.placeId || address.key)

      if (placeId)
        return lookup('google_places', 'details', { placeId }).then(data => {
          const postalCodeDetail = data.result.details.find(c => c.types.includes('postal_code'))

          return (postalCodeDetail || {}).long_name
        })
      else return null
    },
  },
  {
    key: 'enrich',
    name: 'Enrich',
    group: 'async',
    fields: [
      {
        key: 'data',
        type: 'data_rows',
      },
      {
        key: 'name_field',
        type: 'string',
      },
      {
        key: 'email_field',
        type: 'string',
      },
      {
        key: 'location_field',
        type: 'string',
      },
    ],
    async formula({ data, name_field, email_field, location_field }) {
      if (!data || !(name_field || email_field || location_field)) return null

      const allContacts = data
        .map(contact => ({
          name: contact[name_field],
          email: contact[email_field],
          location: contact[location_field],
        }))
        .filter(({ name, email, location }) => email || (name && location))

      const chunks = chunk(allContacts, 100)

      const results = await Promise.all(
        chunks.map(data => lookup('people_data_labs', 'enrich', { data }))
      )

      const allResults = flatten(results.map(r => r.result.results)).map(({ data }) => data)

      const enrichedContacts = data.map((c, index) => {
        const contact = cloneDeep(c)

        Object.entries(getEnrichKeys()).forEach(([key, transform]) => {
          const value = getStringVal(get(allResults[index], key), transform)

          if (value && value.length) contact[key.split('.').join('_')] = value
        })

        return contact
      })

      return enrichedContacts
    },
  },
  {
    key: 'random_group',
    name: 'Random Group',
    group: 'split_testing',
    fields: [
      {
        key: 'num_groups',
        type: 'number',
      },
    ],
  },
  {
    key: 'url_query',
    name: 'Url Query Value',
    group: 'Web Page',
    fields: [
      {
        key: 'query_name',
        type: 'string',
      },
    ],
  },
  {
    key: 'email_validation',
    name: 'Email Validation',
    group: 'validation',
    fields: [
      {
        key: 'email',
        type: 'string',
      },
    ],
    formula({ email }) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
  },
  {
    key: 'add',
    name: 'Add',
    group: 'math',
    fields: [
      {
        key: 'num1',
        type: 'number',
      },
      {
        key: 'num2',
        type: 'number',
      },
    ],
    formula({ num1, num2 }) {
      return getNumber(num1) + getNumber(num2)
    },
  },
  {
    key: 'subtract',
    name: 'Subtract',
    group: 'math',
    fields: [
      {
        key: 'num1',
        type: 'number',
      },
      {
        key: 'num2',
        type: 'number',
      },
    ],
    formula({ num1, num2 }) {
      return getNumber(num1) - getNumber(num2)
    },
  },
  {
    key: 'multiple',
    name: 'Multiple',
    group: 'math',
    fields: [
      {
        key: 'num1',
        type: 'number',
      },
      {
        key: 'num2',
        type: 'number',
      },
    ],
    formula({ num1, num2 }) {
      return getNumber(num1) * getNumber(num2)
    },
  },
  {
    key: 'divide',
    name: 'Divide',
    group: 'math',
    fields: [
      {
        key: 'num1',
        type: 'number',
      },
      {
        key: 'num2',
        type: 'number',
      },
    ],
    formula({ num1, num2 }) {
      return getNumber(num1) / getNumber(num2)
    },
  },
  {
    key: 'custom',
    name: 'Custom',
    group: 'custom',
  },
]

function getNumber(val) {
  return typeof val === 'string' ? parseInt(val) : val
}

function getEnrichKeys() {
  return {
    full_name: null,
    first_name: null,
    last_name: null,
    emails: emails => emails.map(e => e.address),
    'emails.address': null,
    work_email: null,
    location_geo: null,
    location_locality: null,
    location_region: null,
    location_postal_code: null,
    location_country: null,
    facebook_id: null,
    facebook_url: null,
    linkedin_id: null,
    linkedin_url: null,
    twitter_username: null,
    twitter_url: null,
    mobile_phone: null,
    phone_numbers: null,
    birth_date: null,
    birth_year: null,
    // 'education': null,
    'education.school': null,
    job_company_name: null,
    job_company_location_street_address: null,
    job_company_location_address_line_2: null,
    job_company_location_locality: null,
    job_company_location_geo: null,
    job_company_location_region: null,
    job_company_location_postal_code: null,
    job_company_website: null,
    job_company_linkedin_url: null,
    job_company_linkedin_id: null,
    job_title: null,
    job_title_role: null,
    job_last_updated: null,
    job_start_date: null,
  }
}

function getStringVal(val, transform) {
  if (val && transform) val = transform(val)

  if (Array.isArray(val)) val = val.join(', ')

  return val
}

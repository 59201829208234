<template>
  <div class="Container" :class="`ContainerType-${containerType}`">
    <template v-if="containerType !== 'carousel'">
      <FormComponent
        v-for="(component, cidx) in componentsToShow"
        :key="component.id"
        v-bind="{
          form,
          page,
          component,
          pageData,
          userData,
          pageId,
          componentTabGroupIndexes,
          componentIndex: cidx,
          pageMeetsRequirements,
        }"
        :class="[`in-container-key-${containerKey}`, { [`in-page-key-${page && page.key}`]: page }]"
        @update="$emit('update', $event)"
        @prev="onPrev"
        @next="onNext"
        @reset="$emit('reset')"
        @go-to-page="$emit('go-to-page', $event)"
        @update-form="onUpdateForm(component, $event)"
        @click.native="clickAction(component, cidx)"
      />
    </template>
    <template v-else>
      <Carousel
        :key="componentKeys"
        v-bind="carouselSettings"
        :initialSlide="initialSlide"
        @afterChange="afterSlideChange"
      >
        <!-- <div>12</div>
        <div>23</div>
        <div>34</div>
        <div>45</div>
        <div>51</div> -->
        <FormComponent
          v-for="(component, cidx) in componentsToShow"
          :key="component.id"
          v-bind="{
            form,
            page,
            component,
            pageData,
            userData,
            pageId,
            componentTabGroupIndexes,
            componentIndex: cidx,
            pageMeetsRequirements,
          }"
          :class="[
            `in-container-key-${containerKey}`,
            { [`in-page-key-${page && page.key}`]: page },
          ]"
          @update="$emit('update', $event)"
          @prev="onPrev"
          @next="onNext"
          @reset="$emit('reset')"
          @go-to-page="$emit('go-to-page', $event)"
          @update-form="onUpdateForm(component, $event)"
          @click.native="clickAction(component, cidx)"
        />
      </Carousel>
    </template>
  </div>
</template>
<script>
export default {
  name: 'Container',
  components: {
    FormComponent: () => import('./FormComponent'),
    Carousel: () => import('vue-slick-carousel'),
  },
  inject: {
    _onUpdateFormComponent: { default: () => () => {} },
    _clickAction: { default: () => () => {} },
    _filterByConditions: { default: () => () => {} },
  },
  props: {
    form: Object,
    page: Object,
    component: Object,
    componentData: {},
    components: Array,
    pageData: Object,
    userData: Object,
    pageId: String,
    componentTabGroupIndexes: Array,
    componentIndex: Number,
    pageMeetsRequirements: Boolean,
  },
  data() {
    return { initialSlide: 0 }
  },
  computed: {
    containerType() {
      return this.component.container_type || 'container'
    },
    containerKey() {
      return this.component.key
    },
    carouselSettings() {
      const component = this.component || {}
      const getValue = (key, defaultVal) =>
        component[key] !== undefined ? component[key] : defaultVal
      return {
        autoplay: getValue('autoplay', false),
        arrows: getValue('arrows', true),
        dots: getValue('dots', true),
        infinite: getValue('infinite', true),
        centerMode: getValue('center_mode', false),
        centerPadding: getValue('center_padding', '50px'),
        slidesToShow: getValue('visible_slides', 1),
        slidesToScroll: 1,
      }
    },
    componentKeys() {
      return this.componentsToShow.map(c => c.id).join('')
    },
    componentsToShow() {
      const containerIsGlobal = (this.form.components || []).find(f => f.id === this.component.id)
      if (!this.containerKey) return []
      const components = Array.isArray(this.components)
        ? this.components
        : containerIsGlobal
        ? (this.form && this.form.components) || []
        : (this.page && this.page.components) || []

      const componentsToRender = components.filter(this._filterByConditions).filter(
        c =>
          c.parent_key === this.containerKey &&
          // Checks for single-level recursive (component trying to contain itself)
          c.parent_key !== c.key
      )
      /* Recursive Fix - commented out because not fully tested, so unsure if there are nay cases I've missed */
      // const parentKey = this.component.parent_key

      // if (parentKey) {
      //   const possiblyRecursive = new Set(componentsToRender.map(c => c.key)).has(parentKey)
      //   if (possiblyRecursive) {
      //     return []
      //   }
      // }

      return componentsToRender
    },
  },
  mounted() {
    this.initialSlide = this.componentData
  },
  methods: {
    afterSlideChange(index) {
      this.$emit('update', [this.component.key || this.component.id, index])
    },
    clickAction(component, i) {
      this._clickAction(component, i)
    },
    onUpdateForm(component, event) {
      this._onUpdateFormComponent(component, event)
    },
    onPrev(...e) {
      this.$emit('prev', ...e)
    },
    onNext(...e) {
      this.$emit('next', ...e)
    },
  },
}
</script>

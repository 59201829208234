<template>
  <div class="MediaImage" :class="{ roundedCorners }">
    <QuickEditToggle>
      <template slot="editing">
        <Uploader hideIcon @update="$emit('update-form', ['src', $event])">
          <img :class="_elSel('Image')" :src="computedSrc" />
        </Uploader>
      </template>
      <FlowTransition :form="form" :transition="page && page.transition" name="content">
        <img :class="_elSel('Image')" :key="computedSrc" :src="computedSrc" />
      </FlowTransition>
    </QuickEditToggle>
    <QuickEditText
      :value="caption"
      _key="caption"
      placeholder="Image Caption"
      class="caption"
      @update-text="$emit('update-form', $event)"
    >
      <div :class="_elSel('Caption')" class="caption">{{ caption }}</div>
    </QuickEditText>
  </div>
</template>

<script>
import { unpack } from '@/helpers/computed'
import computedValues from './editor/helpers/computedValues'

import QuickEditText from './helpers/QuickEditToggle'
import QuickEditToggle from './helpers/QuickEditToggle'
import FlowTransition from './FlowTransition'

export default {
  name: 'MediaImage',
  components: {
    QuickEditText,
    QuickEditToggle,
    FlowTransition,
    Uploader: () => import('./editor/helpers/Uploader.vue'),
  },
  inject: {
    _elSel: { default: () => () => [] },
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    form: Object,
    page: Object,
    userData: {},
  },
  computed: {
    ...unpack('component', ['src', 'caption', 'roundedCorners']),
    computedSrc() {
      return computedValues(this.userData, this.src, this.form)
    },
  },
}
</script>

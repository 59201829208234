import isEqual from 'lodash/isEqual'

Array.prototype.unique = function(key) {
  const newArr = []

  this.forEach(el => {
    if (!newArr.find(_el => (key ? isEqual(_el[key], el[key]) : isEqual(_el, el)))) newArr.push(el)
  })

  return newArr
}
Array.prototype.flatten = function() {
  let newArr = []

  this.forEach(el => {
    newArr = newArr.concat(el)
  })

  return newArr
}

Array.prototype.filterExists = function() {
  return this.filter(item => item)
}

Array.prototype.spliceReturn = function(start, deleteCount, ...items) {
  return [...this.slice(0, start), ...items, ...this.slice(start + deleteCount)]
}

// NOTE: Only use this if you can guarantee the value is a String
String.prototype.isIn = function(arr) {
  return arr.includes(`${this}`)
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.pageMeetsRequirements &&
      _vm.pages.length - 1 <= _vm.pageIndex &&
      _vm.hasFinish &&
      _vm.fullDestinationUrl &&
      _vm.shouldShowButton
  )?_c('a',{ref:"finish",staticClass:"NextButton button next finish animate",class:_vm._elSel('PageFooterButtonNext', ['PageFooterButton']).concat( [{
      pending: _vm.finishPending,
      hide: _vm.page.showNext === false || _vm.hide,
      justClicked: _vm.recentlyClicked.next,
    }] ),attrs:{"target":_vm.isLocal ? '_blank' : null,"disabled":_vm.finishPending,"href":_vm.fullDestinationUrl},on:{"click":_vm.finish}},[_c('Icon',{staticClass:"fa-spin",attrs:{"icon":['fas', 'spinner']}}),_vm._v(" Finish "),(_vm.page.nextIcon)?_c('Icon',{attrs:{"icon":_vm.page.nextIcon}}):_vm._e()],1):(
    _vm.showNextButton && _vm.page.use_custom_next && _vm.page.custom_next_button && _vm.shouldShowButton
  )?_c('CustomButton',_vm._b({staticClass:"NextButton",class:_vm._elSel('PageFooterButtonNext', ['PageFooterButton']).concat( [{
      hasNext: _vm.hasNext,
      hasFinish: _vm.hasFinish,
      finish: _vm.pageIndex === _vm.pages.length - 1,
      hide: _vm.page.showNext === false || _vm.hide,
      justClicked: _vm.recentlyClicked.next,
    }] ),attrs:{"form":_vm.form,"userData":_vm.userData,"disabled":!_vm.pageMeetsRequirements && _vm.showNextButtonOnIncomplete !== 'always_show'},on:{"update":function($event){return _vm.$emit('update', $event)},"prev":function($event){return _vm.$emit('prev')},"next":function($event){return _vm.$emit('next', $event)},"enter":function($event){return _vm.$emit('enter', $event)},"update-form":function($event){return _vm.onUpdateNavButton(_vm.page.custom_next_button, $event)}}},'CustomButton',{
    component: _vm.page.custom_next_button,
    componentData: _vm.userData[_vm.page.custom_next_button.key],
    componentId: _vm.page.custom_next_button.id,
    form: _vm.form,
    userData: _vm.userData,
  },false)):(_vm.showNextButton && _vm.shouldShowButton)?_c('button',{staticClass:"NextButton next animate",class:_vm._elSel('PageFooterButtonNext', ['PageFooterButton']).concat( [{
      hasNext: _vm.hasNext,
      hasFinish: _vm.hasFinish,
      finish: _vm.pageIndex === _vm.pages.length - 1,
      hide: _vm.page.showNext === false || _vm.hide,
      justClicked: _vm.recentlyClicked.next,
    }] ),attrs:{"type":"button","disabled":!_vm.pageMeetsRequirements && _vm.showNextButtonOnIncomplete !== 'always_show'},on:{"click":_vm.onClickNext}},[_c('QuickEditText',{attrs:{"_key":"nextText","value":_vm.footerButtonTexts.next},on:{"update-text":function($event){return _vm.onUpdatePage.apply(void 0, $event)}}},[_vm._v(" "+_vm._s(_vm.footerButtonTexts.next)+" ")]),(_vm.page.nextIcon)?_c('Icon',{attrs:{"icon":_vm.page.nextIcon}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
import computedValues from '../editor/helpers/computedValues'
import { getEventData } from '../editor/helpers/facebookPixelEvents'

export default async function conversionTrack(parent, userData, form, conversionsKey) {
  const conversions = conversionsKey ? parent[conversionsKey] || [] : parent.conversions || []
  if (conversions.length) {
    await Promise.all(conversions.map(async c => doConversion(c, userData, form)))
  } else {
    if (Array.isArray(parent[conversionsKey] || parent.conversions)) return
    const conversionData = parent.conversion_data || {}
    if (!parent.conversion_data) conversionData.platform = 'facebook'
    if (parent.fbq && !conversionData.fbq) conversionData.fbq = parent.fbq
    if (parent.facebook_pixel_event && !conversionData.facebook_pixel_event) {
      conversionData.facebook_pixel_event = parent.facebook_pixel_event
    }

    doConversion(conversionData, userData, form)
  }
}

function doConversion(conversionData, userData, form) {
  switch (conversionData.platform) {
    case 'facebook':
      return fbqTrack(conversionData, userData, form)

    case 'google':
      return googleTrack(conversionData)

    case 'gtag':
      return gtagTrack(conversionData)
    case 'segment':
      return segmentTrack(conversionData, userData)

    default:
      return
  }
}

function gtagTrack(conversionData) {
  return new Promise((resolve, reject) => {
    if (typeof window.gtag === 'function') {
      const callback = () => resolve()

      window.gtag('event', conversionData.event_name, {
        event_category: conversionData.event_category,
        event_label: conversionData.event_label,
        event_action: conversionData.event_action,
        value: +conversionData.value,
        event_callback: callback,
      })
    } else reject('window.gtag not found!')
  })
}

function googleTrack(conversionData) {
  return new Promise((resolve, reject) => {
    if (typeof window.gtag === 'function') {
      const callback = () => resolve()

      window.gtag('event', 'conversion', {
        send_to: conversionData.google_conversion_id,
        event_callback: callback,
      })
    } else reject('window.gtag not found!')
  })
}

async function fbqTrack(conversionData, userData, form) {
  // if (conversionData.facebook_pixel_event) {
  if (conversionData.facebook_pixel_event && typeof window.fbq === 'function') {
    const eventData = getEventData(conversionData.facebook_pixel_event)
    const data = conversionData.fbq
      ? Object.keys(eventData).reduce((acc, k) => {
          const val = conversionData.fbq[k]
          if (k === 'content_ids' && conversionData.send_page_key_as_content_id)
            acc[k] = [userData.current_page_key]
          else if (val !== undefined) acc[k] = computedValues(userData, val, form)
          return acc
        }, {})
      : null
    if (typeof window.fbq === 'function') {
      if (data) {
        window.fbq('track', conversionData.facebook_pixel_event, data)
      } else {
        window.fbq('track', conversionData.facebook_pixel_event)
      }
    }
  }
}

function segmentTrack(conversionData, userData) {
  return new Promise((resolve, reject) => {
    if (window.analytics && typeof window.analytics.track === 'function') {
      const callback = () => resolve()

      const code = `${conversionData.parameter_payload_code}; customPayload(${JSON.stringify(
        userData
      )})`
      let result = eval(code)
      if (typeof result !== 'object') result = {}

      window.analytics.track(conversionData.event_name, result || {}, {}, callback)
    } else reject('window.analytics not found!')
  })
}

import Axios from 'axios'

export default async function api(path, data, config) {
  let { method, rootUrl } = config || {}
  if (!rootUrl) rootUrl = getRootUrl()
  const token = null

  if (rootUrl && rootUrl.endsWith('/')) rootUrl = rootUrl.slice(0, -1)
  const fullPath = `${rootUrl || ''}/api${path}` //getPath(path)

  return Axios[method || 'post'](fullPath, { token, data })
}

export async function lookup(source, type, payload) {
  const rootUrl = getRootUrl()

  const { data } = await api(
    '/public/flows/lookup',
    {
      source,
      type,
      payload,
    },
    {
      noAuth: true,
      rootUrl,
    }
  )
  return data
}

function getRootUrl() {
  const hostIsLocal =
    window.location.host.startsWith('localhost') && !localStorage.SavvyDebugUseFlowsEndpoint
  const hostIsDeployedWebApp =
    window.location.host === 'staging.trysavvy.com' || window.location.host === 'app.trysavvy.com'
  const isReviewApp = process.env.VUE_APP_IS_REVIEW_APP
  return hostIsLocal || hostIsDeployedWebApp || isReviewApp ? null : `https://flows.trysavvy.com`
}

// function getPath(path) {
//   const relativePath = `/api/${path.startsWith('/') ? path.slice(1) : path}`
//   const isLocal =
//     window.location.host.startsWith('localhost:') || window.location.host.startsWith('192.168.')
//   const root = isLocal
//     ? window.location.origin
//         .split(':')
//         .slice(0, -1)
//         .join(':') + ':5000'
//     : ''
//   const fullPath = `${root}${relativePath}`

//   return fullPath
// }

import cloneDeep from 'lodash/cloneDeep'
import mergeWith from 'lodash/mergeWith'
import idGen from '@/helpers/idGen'

export function repeatPages(formPages, userData = {}) {
  const pages = formPages || []

  return pages.some(p => p.repeater_key !== undefined)
    ? pages.reduce((acc, p) => {
        acc.push(...repeatPage(p, userData[p.repeater_key]))
        return acc
      }, [])
    : pages
}

export default function repeatPage(page, repeaterData) {
  if (!page.repeater_key) return [page]
  if (!Array.isArray(repeaterData)) return [page]

  if (repeaterData.every(d => typeof d === 'string')) {
    return repeaterData.map(s => {
      const newPage = cloneDeep(page)
      delete newPage.repeater_key
      newPage.key = `${page.key}_${s}`
      newPage.id = `${page.id}_${s}`
      newPage.__is_repeated = true
      newPage.__base_page = page.key
      const genKey = key => `${page.key}_${s}_${key}`
      newPage.components = newPage.components.map(c => {
        const component = {
          ...c,
          key: genKey(c.key),
          id: genKey(c.id),
          parent_key: c.parent_key ? genKey(c.parent_key) : undefined,
        }
        if (Array.isArray(c.buttons))
          component.buttons = c.buttons.map(b => ({
            ...b,
            key: genKey(b.key),
            id: genKey(b.id),
          }))
        return component
      })

      return newPage
    })
  }

  return repeaterData.map(d => {
    const newPage = cloneDeep(page)
    mergeWith(newPage, d, customizer)
    newPage.id = idGen()

    /* Todo: replace ids, prefix nested keys with page key */

    newPage.components = newPage.components.map(c => {
      const component = { ...c, key: `${page.key}_${c.key}`, id: idGen() }
      if (Array.isArray(c.buttons))
        component.buttons = c.buttons.map(b => ({ ...b, key: `${page.key}_${b.key}`, id: idGen() }))
      return component
    })

    return newPage
  })
}

function customizer(objValue, srcValue) {
  if (Array.isArray(objValue)) return objValue.concat(srcValue)
  if (srcValue === null || srcValue === undefined) return objValue
}

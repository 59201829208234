<script>
// import * as Sentry from '@sentry/vue'
import { Database } from 'firebase-firestore-lite'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

import { unpack } from '@/helpers/computed'
import formulas from './editor/helpers/computedFieldFormulas'
import computedValues, { getDisplayValues } from './editor/helpers/computedValues'
import notify from '@/helpers/notifyLite'
import getUrlDict from '../../helpers/getUrlDict'

const db = new Database({ projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID })

export default {
  name: 'ComputedField',
  inject: {
    _updateUserData: { default: () => () => {} },
    _resetUserData: { default: () => () => {} },
    _getComponentElement: { default: () => () => {} },
    _triggerInfoBox: { default: () => () => {} },
  },
  props: {
    computedField: Object,
    userData: Object,
    form: Object,
    pages: Array,
  },
  data() {
    return {
      result: null,
      prevConfig: null,
      resultNum: 0,
    }
  },
  computed: {
    ...unpack('computedField', [
      'key',
      'fields',
      'formula',
      'code',
      'inputs',
      'input_triggers',
      'async',
      'async_debounce_time',
      'include_loading_state',
    ]),
    formulaData() {
      return formulas.find(f => f.key === this.formula) || {}
    },
    fieldValues() {
      if (this.computedField && this.computedField.use_full_user_data) {
        const userDataCopy = { ...this.userData }
        if (userDataCopy.hasOwnProperty(this.key)) delete userDataCopy[this.key]
        return userDataCopy
      }

      const f = {}

      Object.entries(this.fields || {}).forEach(([key, text]) => {
        f[key] = computedValues(this.userData, text, this.form)
      })
      ;(this.inputs || []).forEach(key => {
        f[key] = this.userData[key]
      })

      return f
    },
    watchedFieldValues() {
      if (this.computedField && this.computedField.use_full_user_data) {
        const userDataCopy = { ...this.userData }
        if (userDataCopy.hasOwnProperty(this.key)) delete userDataCopy[this.key]
        return userDataCopy
      }

      if (!this.input_triggers || this.input_triggers.length === 0) return this.fieldValues

      const f = {}

      ;(this.input_triggers || []).forEach(key => {
        f[key] = this.userData[key]
      })

      return f
    },
    debounceGetAndSetResult() {
      return debounce(
        this.getAndSetResult,
        (this.async_debounce_time && parseInt(this.async_debounce_time)) || 250,
        { trailing: true, leading: false }
      )
    },
    isAsync() {
      return (
        this.async ||
        this.formulaData.async ||
        this.formulaData.group === 'async' ||
        (this.code && this.code.trim().startsWith('async function'))
      )
    },
  },
  watch: {
    computedField: {
      handler() {
        this.$nextTick(() => this.updateResult())
      },
      deep: true,
    },
    watchedFieldValues: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) this.$nextTick(() => this.updateResult())
      },
      deep: true,
    },
    result: {
      handler(result) {
        const oldResult = this.userData && this.userData[this.key]

        if (equal(result, oldResult)) return

        this.$emit('update', result)
      },
      // immediate: true,
      deep: true,
    },
  },
  created() {
    this.updateResult()
  },
  methods: {
    async updateResult() {
      const config = {
        computedField: cloneDeep(this.computedField),
        fieldValues: cloneDeep(this.fieldValues),
      }

      if (equal(config, this.prevConfig)) return

      this.resultNum++
      const resultNum = this.resultNum

      if (this.isAsync && this.include_loading_state) this.result = '_loading'

      if (
        !this.isAsync ||
        !this.include_loading_state ||
        !Object.values(this.fieldValues).includes('_loading')
      ) {
        // console.log(`Actually getting results #${resultNum} for`, this.key)

        this.isAsync
          ? await this.debounceGetAndSetResult(resultNum)
          : this.getAndSetResult(resultNum)
      }

      this.prevConfig = config
    },
    getCachePath() {
      try {
        const cacheKey = Object.entries(this.watchedFieldValues)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([k, v]) => {
            return `${k}=${
              Array.isArray(v)
                ? v.join(',')
                : typeof v === 'object' && v
                ? Object.entries(v || {})
                    .map(([kv, vv]) => `${kv}-${vv}`.replace(/\s/gi, '+'))
                    .join(',')
                : v
            }`
          })
          .join(';')
        const subDocKey = this.computedField.key
        return `forms/${this.form.id}/cache/${subDocKey}/values/${cacheKey}`
      } catch (error) {
        console.error('getCachePath', error)
        // Sentry.captureException(error)
        return ''
      }
    },
    async getAndSetResult(resultNum) {
      let result
      const formulaKey = this.formulaData && this.formulaData.key
      const cacheData = this.computedField && this.computedField.cache_data
      const cachePath = this.getCachePath()
      if (formulaKey === 'custom' && cacheData && cachePath) {
        /* Get result from cache id first. If no cache, then do result below */
        try {
          const res = await db.ref(cachePath).get()
          if (res && res.data) result = JSON.parse(res.data)
        } catch (error) {
          if (!error.message.includes('not found.')) {
            console.error(error)
            notify(`Computed Field ${this.computedField.key} Cache Fetch Error:`, error, {
              form: this.form,
              userData: this.userData,
            })
          }
        }
      }
      if (!result) {
        result = await this.getResult()
        if (formulaKey === 'custom' && cacheData && cachePath) {
          try {
            await db.ref(cachePath).set({ data: JSON.stringify(result), timestamp: Date.now() })
          } catch (error) {
            console.error(error)
            notify(`Computed Field ${this.computedField.key} Cache Set Error:`, error, {
              form: this.form,
              userData: this.userData,
            })
          }
        }
      }
      if (resultNum === this.resultNum) this.result = result === undefined ? null : result
    },
    async getResult() {
      if (this.formulaData && this.formulaData.formula)
        return this.formulaData.formula(this.fieldValues)

      switch (this.formula) {
        case 'random_group': {
          return this.userData
            ? [undefined, null].includes(this.userData[this.key])
              ? getRandomGroup(this.fieldValues.num_groups)
              : this.userData[this.key]
            : undefined
        }
        case 'url_query': {
          const dict = getUrlDict() || {}
          return dict[this.fieldValues.query_name] || ''
        }
        case 'custom': {
          const res = await this.getCustomResult()
          return typeof res === 'number' && (isNaN(res) || res === Infinity) ? '_error' : res
        }

        default:
          return null
      }
    },
    async getCustomResult() {
      if (!this.code) return null

      const fields = {}

      if (this.computedField.use_full_user_data) {
        const userDataCopy = { ...this.userData }
        if (userDataCopy.hasOwnProperty(this.key)) delete userDataCopy[this.key]
        Object.entries(userDataCopy).forEach(([k, v]) => (fields[k] = v))
      } else if (this.inputs) this.inputs.forEach(key => (fields[key] = this.userData[key]))
      const setUserData = this._updateUserData
      const resetUserData = this._resetUserData
      const contextFunctions = {
        setUserData,
        resetUserData,
        getComponentElement: this._getComponentElement,
        openInfoBox: this._triggerInfoBox,
      }
      if (!contextFunctions) console.log(contextFunctions)
      fields._display_values = getDisplayValues(this.userData, this.form)
      try {
        // console.log(`Evaluating ${this.key} with inputs ${(this.inputs || []).join(', ')}`)
        const code = `${this.code}; result(${JSON.stringify(fields)}, contextFunctions)`

        return eval(code)
      } catch (e) {
        console.log(`Error in ComputedField ${this.key}`, e)
        return '_error'
      }
    },
  },
  render() {
    return null
  },
}

function getRandomGroup(numGroups) {
  if (!numGroups) return null

  return parseInt(Math.random() * numGroups)
}

function equal(val1, val2) {
  return isEqual(val1 === undefined ? null : val1, val2 === undefined ? null : val2)
}
</script>

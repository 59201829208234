export default function emitSyntheticEvent(name, detail, host) {
  const eventConstructor =
    typeof window.CustomEvent === 'function' ? window.CustomEvent : CustomEventPolyfill
  const event = new eventConstructor(name, { detail, bubbles: true })
  const el = host || window
  el.dispatchEvent(event)

  if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ event: name, data: detail }))
  }
}

function CustomEventPolyfill(event, params) {
  params = params || { bubbles: false, cancelable: false, detail: null }
  var evt = document.createEvent('CustomEvent')
  evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
  return evt
}

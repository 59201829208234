<template>
  <div class="RichText" ref="sudoroot">
    <RichTextEditor
      v-if="isInWebApp && _isEditing()"
      v-bind="{ component }"
      style="width: 100%; height: 100%;"
      @update="$emit('update-form', $event)"
    />
    <div v-else v-html="compiledMarkdown" ref="root"></div>
  </div>
</template>

<script>
import marked from 'marked'
import computedValues from './editor/helpers/computedValues'
import { parseURIComponent } from '@/helpers/textStringConversions'

export default {
  name: 'RichText',
  components: {
    RichTextEditor: () => import('./editor/RichTextEditor'),
  },
  inject: {
    _isInWebApp: { default: () => () => {} },
    _isEditing: { default: () => () => false },
    _triggerInfoBox: { default: () => () => {} },
  },
  props: {
    component: Object,
    form: Object,
    userData: Object,
  },
  data() {
    return {
      editingMarkdown: false,
    }
  },
  computed: {
    isInWebApp() {
      return this._isInWebApp()
    },
    isEditing() {
      return this._isEditing()
    },
    compiledMarkdown() {
      return this.component.text
        ? marked(computedValues(this.userData, this.component.text, this.form) || '')
        : null
    },
  },
  async mounted() {
    await this.$nextTick()
    this.cleanUpListeners()
    this.setUpListeners()
  },
  beforeDestroy() {
    this.cleanUpListeners()
  },
  watch: {
    async isEditing() {
      await this.$nextTick()
      this.cleanUpListeners()
      this.setUpListeners()
    },
    async compiledMarkdown() {
      await this.$nextTick()
      this.cleanUpListeners()
      this.setUpListeners()
    },
  },
  methods: {
    cleanUpListeners() {
      if (this.listeners) this.listeners.forEach(l => l())
    },
    async setUpListeners() {
      const root = this.isEditing ? this.$refs.sudoroot : this.$refs.root
      if (!root) return

      if (this.isEditing) {
        await this.$nextTick()
        await this.$nextTick()
      }

      const els = root.querySelectorAll('a')
      const self = this
      const returns = []

      if (els.length) {
        els.forEach(el => {
          el.addEventListener('click', self.anchorListener)
          returns.push(() => el.removeEventListener('click', self.anchorListener))
        })
      }
      this.listeners = returns
    },
    anchorListener(e) {
      const valueUpdate = 'value_update='
      // 'value_update:%7B%22testkeyvaluepair%22%3A%22text%22%7D'
      let target = e.target

      let href = target.getAttribute('href') || target.attributes.getNamedItem('href')
      while (!href) {
        target = target.parentElement
        if (target === this.$refs.root) break
        if (!target) break
        href = target.getAttribute('href') || target.attributes.getNamedItem('href')
      }

      if (!href) return

      const [actionCommand, userDataUpdates] = href.split('?')
      const [action, actionTarget] = actionCommand.split(':')

      if (href.startsWith(valueUpdate)) {
        e.preventDefault()
        const jsonData = parseURIComponent(href.slice(valueUpdate.length))
        const data = JSON.parse(jsonData)
        this.$emit('update', [], data)
      }

      switch (action) {
        case 'open_popup': {
          e.preventDefault()
          const infoBoxName = parseURIComponent(actionTarget)
          this._triggerInfoBox(infoBoxName)
          break
        }

        default:
          break
      }

      if (userDataUpdates) {
        const updates = userDataUpdates.split('&')
        const data = updates.reduce((acc, u) => {
          const [k, val] = u.split('=')
          const v = parseURIComponent(val)
          try {
            acc[k] = JSON.parse(v)
          } catch (error) {
            acc[k] = v
          }
          return acc
        }, {})
        this.$emit('update', [], data)
      }
    },
  },
}
</script>

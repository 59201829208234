<template>
  <IconifyIcon v-if="iconIsLoaded" class="Icon iconify" :icon="icon" :inline="inline" />
</template>
<script>
import IconifyIcon from '@iconify/vue'

export default {
  components: { IconifyIcon },
  inject: { _loadedIcons: { default: () => () => {} } },
  props: { icon: String, inline: Boolean },
  computed: {
    loadedIcons() {
      return this._loadedIcons()
    },
    iconIsLoaded() {
      const [col, ic] = this.icon.split(':')
      const icons = this.loadedIcons
      if (!icons) return false
      if (!Array.isArray(icons[col])) return false
      return Boolean(icons[col].find(c => c === ic))
    },
  },
}
</script>

<template>
  <a
    v-if="
      pageMeetsRequirements &&
        pages.length - 1 <= pageIndex &&
        hasFinish &&
        fullDestinationUrl &&
        shouldShowButton
    "
    class="NextButton button next finish animate"
    :target="isLocal ? '_blank' : null"
    ref="finish"
    :disabled="finishPending"
    :href="fullDestinationUrl"
    @click="finish"
    :class="[
      ..._elSel('PageFooterButtonNext', ['PageFooterButton']),
      {
        pending: finishPending,
        hide: page.showNext === false || hide,
        justClicked: recentlyClicked.next,
      },
    ]"
  >
    <Icon class="fa-spin" :icon="['fas', 'spinner']" />
    Finish
    <Icon v-if="page.nextIcon" :icon="page.nextIcon" />
  </a>
  <CustomButton
    v-else-if="
      showNextButton && page.use_custom_next && page.custom_next_button && shouldShowButton
    "
    v-bind="{
      component: page.custom_next_button,
      componentData: userData[page.custom_next_button.key],
      componentId: page.custom_next_button.id,
      form,
      userData,
    }"
    :form="form"
    :userData="userData"
    :disabled="!pageMeetsRequirements && showNextButtonOnIncomplete !== 'always_show'"
    class="NextButton"
    :class="[
      ..._elSel('PageFooterButtonNext', ['PageFooterButton']),
      {
        hasNext,
        hasFinish,
        finish: pageIndex === pages.length - 1,
        hide: page.showNext === false || hide,
        justClicked: recentlyClicked.next,
      },
    ]"
    @update="$emit('update', $event)"
    @prev="$emit('prev')"
    @next="$emit('next', $event)"
    @enter="$emit('enter', $event)"
    @update-form="onUpdateNavButton(page.custom_next_button, $event)"
  />
  <button
    v-else-if="showNextButton && shouldShowButton"
    class="NextButton next animate"
    type="button"
    :disabled="!pageMeetsRequirements && showNextButtonOnIncomplete !== 'always_show'"
    :class="[
      ..._elSel('PageFooterButtonNext', ['PageFooterButton']),
      {
        hasNext,
        hasFinish,
        finish: pageIndex === pages.length - 1,
        hide: page.showNext === false || hide,
        justClicked: recentlyClicked.next,
      },
    ]"
    @click="onClickNext"
  >
    <QuickEditText
      _key="nextText"
      :value="footerButtonTexts.next"
      @update-text="onUpdatePage(...$event)"
    >
      {{ footerButtonTexts.next }}
    </QuickEditText>
    <Icon v-if="page.nextIcon" :icon="page.nextIcon" />
  </button>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import { passesConditions } from './helpers/conditions'
import QuickEditText from './helpers/QuickEditText.vue'
import Icon from './Icon'

export default {
  name: 'NextButton',
  components: { QuickEditText, Icon },
  inject: {
    _elSel: { default: () => () => {} },
    _filterByConditions: { default: () => () => {} },
    _uniqueByKey: { default: () => () => {} },
    _getRecentlyClicked: { default: () => () => {} },
    _finish: { default: () => () => {} },
    _updateReactive: { default: () => () => {} },
    _getSelectionMode: { default: () => () => {} },
    _getFlowPages: { default: () => () => [] },
  },
  props: {
    form: Object,
    page: Object,
    component: Object,
    userData: Object,
    pageMeetsRequirements: Boolean,
    hide: Boolean,
  },
  data() {
    return { finishPending: false, isLocal: window.location.hostname === 'localhost' }
  },
  computed: {
    recentlyClicked() {
      return this._getRecentlyClicked()
    },
    pages() {
      return (
        this.form &&
        this._getFlowPages()
          .reduce(this._uniqueByKey, [])
          .filter(this._filterByConditions)
      )
    },
    pageIndex() {
      return this.pages.findIndex(p => p.id === this.page.id)
    },
    hasNext() {
      return this.pageIndex < this.pages.length - 1
    },
    shouldShowButton() {
      if (this.component && this.component.hide_if_no_nav_target) {
        return Boolean(this.hasNext || this.hasFinish)
      }
      return true
    },
    hasFinish() {
      const destinations =
        ((this.form && this.form.destinations) || []).filter(this._filterByConditions) || []

      return destinations.length > 0
    },
    footerButtonTexts() {
      const next =
        this.pages.length - 1 > this.pageIndex
          ? (this.page.usePageNavText !== false
              ? this.page.nextText
              : this.form && this.form.defaults && this.form.defaults.nextText) || 'Next'
          : 'Finish'

      return { next }
    },
    pageComponents() {
      const components = this.page.components || []
      const page = this._getFlowPages().find(p => p.id === this.page.id)
      const containerKeys = new Set(
        page.components.filter(c => c.type === 'Container').map(c => c.key)
      )
      return components.filter(c => (c.parent_key ? !containerKeys.has(c.parent_key) : true))
    },
    showNextButtonOnIncomplete() {
      return (
        (this.page && this.page.show_next_button_on_incomplete) ||
        (this.form && this.form.show_next_button_on_incomplete)
      )
    },
    showNextButton() {
      if (this.showNextButtonOnIncomplete === 'always_show') return this.hasNext || this.hasFinish
      const showNextIfIncomplete =
        this.showNextButtonOnIncomplete === 'show_disabled' ||
        (this.showNextButtonOnIncomplete === 'show_disabled_except_single_selects' &&
          this.pageComponents.length === 1 &&
          (this.pageComponents[0].type === 'OptionButtons' ||
            this.pageComponents[0].type === 'OptionSelector') &&
          !this.pageComponents[0].multiple)

      return (
        (this.pageMeetsRequirements || showNextIfIncomplete) && (this.hasNext || this.hasFinish)
      )
    },
    destinations() {
      return (
        (this.form &&
          (this.form.destinations || []).filter(destination =>
            passesConditions(
              destination.conditions,
              this.userData,
              this.components,
              this.isInWebApp
            )
          )) ||
        []
      )
    },
    fullDestinationUrl() {
      if (this.destinations[0]) {
        const userData = cloneDeep(this.userData)

        delete userData.currentPageId

        let [url, queryString] = (this.destinations[0].url || '').split('?')

        const newQueryString = (queryString || '')
          .split('&')
          .concat([`data=${encodeURIComponent(JSON.stringify(userData))}`])
          .join('&')

        return `${url}/?${newQueryString}`
      } else return null
    },
  },
  watch: {},
  methods: {
    finish(event) {
      if (this._getSelectionMode()) return
      if (this.finishPending) this.finishPending = false
      else {
        event.preventDefault()

        this.finishPending = true
        this._finish().finally(() => this.$refs.finish.click())
      }
    },
    onUpdatePage(key, value) {
      const pageIndex = this.form.pages.findIndex(p => p.id === this.page.id)
      this._updateReactive(`pages.${pageIndex}`, key, value)
    },
    onUpdateNavButton(button, [key, value]) {
      const newButton = cloneDeep(button)
      newButton[key] = value
      this.onUpdatePage('custom_next_button', newButton)
    },
    onClickNext() {
      if (this._getSelectionMode()) return
      this.$emit('next', true)
    },
  },
}
</script>

import axios from 'axios'
import detectLocalStorage from '@/helpers/detectLocalStorage'

export default async function appNotification(flowId, event, userData, productId) {
  const contactId = detectLocalStorage() && localStorage.getItem(`SavvyUserId-${productId}`)
  await axios.post('https://us-central1-savvy-app-live.cloudfunctions.net/sendFlowNotification', {
    flowId,
    event,
    userData,
    contactId,
  })
}

<template>
  <FormPageRoot
    :isForm="form.form_wrapper"
    id="savvy-signup-form"
    ref="formpage"
    class="FormPage"
    :class="[
      generatePageSelectors(page),
      `page-index-${pageIndex}`,
      `page-key-${page.key}`,
      ...(tags || []).map(tag => `tag-${tag}`),
      { isFlush },
    ]"
    :data-ms-form="memberstack_form"
    :form="form"
    v-bind="myTempVBind"
  >
    <div class="form-page-main" :class="_elSel('PageContents')">
      <div
        v-if="!hideHeader || !(builderVersion && builderVersion >= 3)"
        class="form-page-header"
        :class="_elSel('PageHeader')"
      >
        <component
          v-if="showFormLogoInPage || showProgressBar"
          :is="logo_link_url ? 'a' : 'div'"
          target="_blank"
          :href="logo_link_url"
          class="logo"
          :class="_elSel('PageLogo')"
        >
          <!-- <QuickEditToggle v-if="showFormLogoInPage">
            <template slot="editing">
              <Uploader
                :hideIcon="showFormLogoInPage"
                tooltip="Click or Drag to upload a logo"
                @update="$emit('update-form', ['logoImgUrl', $event])"
              >
                <img v-if="showFormLogoInPage" :src="form.logoImgUrl" />
              </Uploader>
            </template>
            <img :src="form.logoImgUrl" :class="_elSel('PageLogoImage')" />
          </QuickEditToggle> -->
          <img v-if="showFormLogoInPage" :src="form.logoImgUrl" :class="_elSel('PageLogoImage')" />
          <div v-else></div>
        </component>
        <CircularFormProgress
          v-if="showProgressBar && !hideProgress && useDefaultProgressLocation"
          :class="_elSel('PageProgressBar')"
          v-bind="{ form, userData, page }"
          @reset="$emit('reset')"
        />
      </div>

      <FlowComponents
        location="page_top"
        class="GlobalLocation-PageTop"
        :class="inPageClasses"
        :components="globalComponents"
        v-bind="{ form, page, userData, componentTabGroupIndexes, pageMeetsRequirements }"
        @prev="onPrev"
        @next="onNext"
        @reset="$emit('reset')"
        @go-to-page="$emit('go-to-page', $event)"
        @enter="onEnter"
      />
      <template v-if="!(builderVersion && builderVersion >= 3)">
        <FlowTransition :form="form" :transition="page.transition" name="title">
          <!-- <PageHeadingLabel
          v-if="
            useDefaultPageHeadings.heading &&
              headingLabel &&
              !headingLabelBlock &&
              !headingLabelColumn
          "
          :key="pageId"
          :rich="headingLabelRich"
          v-bind="{ form, userData, page }"
          @update="onUpdate"
        /> -->
          <FormComponent
            v-if="
              useDefaultPageHeadings.heading &&
                headingLabel &&
                !headingLabelBlock &&
                !headingLabelColumn
            "
            :key="pageId"
            v-bind="{ form, userData, page, component: defaultHeadingComponents.heading }"
          />
        </FlowTransition>
        <FlowTransition :form="form" :transition="page.transition" name="title">
          <FormComponent
            v-if="useDefaultPageHeadings.title && title && !titleColumn && !titleBlock"
            :key="pageId"
            v-bind="{ form, userData, page, component: defaultHeadingComponents.title }"
          />
          <!-- <PageTitle
          v-if="useDefaultPageHeadings.title && title && !titleColumn && !titleBlock"
          :key="pageId"
          :rich="titleRich"
          v-bind="{ form, userData, page }"
          @update="onUpdate"
        /> -->
        </FlowTransition>
        <FlowTransition :form="form" :transition="page.transition" name="title">
          <FormComponent
            v-if="useDefaultPageHeadings.subtitle && subtitle && !subtitleColumn && !subtitleBlock"
            :key="pageId"
            v-bind="{ form, userData, page, component: defaultHeadingComponents.subtitle }"
          />
          <!-- <PageSubtitle
          v-if="useDefaultPageHeadings.subtitle && subtitle && !subtitleColumn && !subtitleBlock"
          :key="pageId"
          :rich="subtitleRich"
          v-bind="{ form, userData, page }"
          @update="onUpdate"
        /> -->
        </FlowTransition>
      </template>
      <FlowTransition :form="form" :transition="page.transition" name="content">
        <div
          class="components"
          :key="pageId"
          :class="[
            `components-length-${pageComponents.length}`,
            `in-page-key-${page.key}`,
            ..._elSel('PageComponents'),
          ]"
        >
          <GridLayout
            v-if="layout"
            v-bind="{ layout, blockStyles, layoutBlocks, userData }"
            @update="onUpdate"
          >
            <template v-slot:default="{ index }">
              <div
                class="GridLayoutBlockGrid"
                :class="(layoutBlocks || [])[index] || {}"
                :style="{
                  'grid-gap': ((layoutBlocks || [])[index] || {}).spacing,
                }"
              >
                <FormComponent
                  v-if="useDefaultPageHeadings.heading && headingLabelBlock === index + 1"
                  v-bind="{ form, userData, page, component: defaultHeadingComponents.heading }"
                  @update="onUpdate"
                />
                <FormComponent
                  v-if="useDefaultPageHeadings.title && titleBlock === index + 1"
                  v-bind="{ form, userData, page, component: defaultHeadingComponents.title }"
                  @update="onUpdate"
                />
                <FormComponent
                  v-if="useDefaultPageHeadings.subtitle && subtitleBlock === index + 1"
                  v-bind="{ form, userData, page, component: defaultHeadingComponents.subtitle }"
                  @update="onUpdate"
                />
                <!-- <PageHeadingLabel
                  v-if="useDefaultPageHeadings.heading && headingLabelBlock === index + 1"
                  :rich="headingLabelRich"
                  key="headingLabel"
                  v-bind="{ form, userData, page }"
                  @update="onUpdate"
                />
                <PageTitle
                  v-if="useDefaultPageHeadings.title && titleBlock === index + 1"
                  :rich="titleRich"
                  key="title"
                  v-bind="{ form, userData, page }"
                  @update="onUpdate"
                />
                <PageSubtitle
                  v-if="useDefaultPageHeadings.subtitle && subtitleBlock === index + 1"
                  :rich="subtitleRich"
                  key="subtitle"
                  v-bind="{ form, userData, page }"
                  @update="onUpdate"
                /> -->
                <FormComponent
                  v-for="(component, cidx) in pageComponents.filter(
                    component => index + 1 === (component.block || 1)
                  )"
                  :key="component.id"
                  v-bind="{
                    form,
                    page,
                    component,
                    pageData,
                    userData,
                    pageId,
                    componentTabGroupIndexes,
                    componentIndex: cidx,
                    pageMeetsRequirements,
                  }"
                  :class="inPageClasses"
                  @update="onUpdate"
                  @prev="onPrev"
                  @next="onNext"
                  @reset="$emit('reset')"
                  @go-to-page="$emit('go-to-page', $event)"
                  @update-form="onUpdateFormComponent(component, $event)"
                  @click.native="clickAction(component, cidx)"
                />
              </div>
            </template>
          </GridLayout>

          <transition-group
            name="list"
            tag="div"
            v-else
            style="display: flex; flex-direction: row; align-items: stretch;"
          >
            <div
              name="list"
              tag="div"
              v-for="(column, index) in columns"
              :key="column"
              class="column"
              :class="[`column-${index + 1}`, ..._elSel('PageComponentsList'), 'Container']"
              :style="`width: ${Math.round(100 / columns.length)}%`"
            >
              <FlowComponents
                location="before_components"
                class="GlobalLocation-BeforeComponents"
                :class="inPageClasses"
                :components="globalComponents"
                v-bind="{ form, page, userData, componentTabGroupIndexes, pageMeetsRequirements }"
                @prev="onPrev"
                @next="onNext"
                @reset="$emit('reset')"
                @go-to-page="$emit('go-to-page', $event)"
                @enter="onEnter"
              />
              <FormComponent
                v-if="
                  useDefaultPageHeadings.heading &&
                    headingLabel &&
                    (headingLabelBlock || headingLabelColumn) === column
                "
                v-bind="{ form, userData, page, component: defaultHeadingComponents.heading }"
                @update="onUpdate"
              />
              <FormComponent
                v-if="
                  useDefaultPageHeadings.title && title && (titleBlock || titleColumn) === column
                "
                v-bind="{ form, userData, page, component: defaultHeadingComponents.title }"
                @update="onUpdate"
              />
              <FormComponent
                v-if="
                  useDefaultPageHeadings.subtitle &&
                    subtitle &&
                    (subtitleBlock || subtitleColumn) === column
                "
                v-bind="{ form, userData, page, component: defaultHeadingComponents.subtitle }"
                @update="onUpdate"
              />
              <!-- <PageHeadingLabel
                v-if="
                  useDefaultPageHeadings.heading &&
                    headingLabel &&
                    (headingLabelBlock || headingLabelColumn) === column
                "
                :rich="headingLabelRich"
                key="headingLabel"
                v-bind="{ form, userData, page }"
                @update="onUpdate"
              />
              <PageTitle
                v-if="
                  useDefaultPageHeadings.title && title && (titleBlock || titleColumn) === column
                "
                :rich="titleRich"
                key="title"
                v-bind="{ form, userData, page }"
                @update="onUpdate"
              />
              <PageSubtitle
                v-if="
                  useDefaultPageHeadings.subtitle &&
                    subtitle &&
                    (subtitleBlock || subtitleColumn) === column
                "
                :rich="subtitleRich"
                key="subtitle"
                v-bind="{ form, userData, page }"
                @update="onUpdate"
              /> -->
              <FormComponent
                v-for="(component, cidx) in pageComponents.filter(
                  component => column === (component.column || 1)
                )"
                :key="component.id"
                v-bind="{
                  form,
                  page,
                  component,
                  pageData,
                  userData,
                  pageId,
                  componentTabGroupIndexes,
                  componentIndex: cidx,
                  pageMeetsRequirements,
                }"
                @update="onUpdate"
                @prev="onPrev"
                @next="onNext"
                @enter="onEnter"
                @reset="$emit('reset')"
                @go-to-page="$emit('go-to-page', $event)"
                @update-form="onUpdateFormComponent(component, $event)"
                @click.native="clickAction(component, cidx)"
              />

              <FlowComponents
                location="after_components"
                class="GlobalLocation-AfterComponents"
                :class="inPageClasses"
                :components="globalComponents"
                v-bind="{ form, page, userData, componentTabGroupIndexes, pageMeetsRequirements }"
                @prev="onPrev"
                @next="onNext"
                @reset="$emit('reset')"
                @go-to-page="$emit('go-to-page', $event)"
                @enter="onEnter"
              />
            </div>
          </transition-group>
        </div>
      </FlowTransition>
    </div>
    <FlowTransition
      v-if="!hideFooter && !(builderVersion && builderVersion >= 3)"
      :form="form"
      :transition="page.transition"
      name="footer"
    >
      <div class="footer" :class="_elSel('PageFooter')" :key="pageId">
        <FlowComponents
          location="footer"
          class="GlobalLocation-Footer"
          :class="inPageClasses"
          :components="globalComponents"
          v-bind="{ form, page, userData, componentTabGroupIndexes, pageMeetsRequirements }"
          @prev="onPrev"
          @next="onNext"
          @go-to-page="$emit('go-to-page', $event)"
          @enter="onEnter"
        />
        <FlowFooter
          :key="pageId"
          v-if="useDefaultFooter"
          v-bind="{ form, userData, page, pageMeetsRequirements }"
          @prev="onPrev"
          @next="onNext"
          @enter="onEnter"
        />

        <div
          v-if="form.logoImgUrl && form.logoPlacement !== 'top-left'"
          class="logo"
          :key="pageId"
          :class="_elSel('PageLogo')"
        >
          <img :src="form.logoImgUrl" :class="_elSel('PageLogo')" />
        </div>
      </div>
    </FlowTransition>
    <FlowComponents
      location="flow_bottom"
      class="GlobalLocation-FlowBottom"
      :class="inPageClasses"
      :components="globalComponents"
      v-bind="{ form, page, userData, componentTabGroupIndexes, pageMeetsRequirements }"
      @prev="onPrev"
      @next="onNext"
      @reset="$emit('reset')"
      @go-to-page="$emit('go-to-page', $event)"
      @enter="onEnter"
    />
  </FormPageRoot>
</template>

<script>
import get from 'lodash/get'
import range from 'lodash/range'
import flatten from 'lodash/flatten'
// import Papa from 'papaparse'
// import { GridLayout, GridItem } from 'vue-grid-layout'

import Icon from './Icon'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

import { unpack } from '@/helpers/computed'
import conversionTrack from '@/components/form/helpers/conversionTrack'
import { defaultHeadingComponents } from '@/components/form/helpers/defaultComponents'
import emitSyntheticEvent from '@/helpers/emitSyntheticEvent'
import { passesConditions } from './helpers/conditions'
import { generatePageSelectors } from './helpers/labelGenerator'
import { parseURIComponent } from '@/helpers/textStringConversions'
import GridLayout from './helpers/GridLayout'
import QuickEditToggle from './helpers/QuickEditToggle'
import FormComponent from './FormComponent'
import FlowComponents from './FlowComponents'
import FlowTransition from './FlowTransition'
import FormPageRoot from './FormPageRoot'
import PlainRichTextToggle from './PlainRichTextToggle'
import QuickEditText from './helpers/QuickEditText.vue'
import CustomButton from './CustomButton.vue'
import FlowFooter from './FlowFooter.vue'
import PageTitle from './PageTitle.vue'
import PageSubtitle from './PageSubtitle.vue'
import PageHeadingLabel from './PageHeadingLabel.vue'

export default {
  name: 'FormPage',
  components: {
    Icon,
    GridLayout,
    QuickEditToggle,
    FormComponent,
    FlowComponents,
    FlowTransition,
    FormPageRoot,
    PlainRichTextToggle,
    QuickEditText,
    CustomButton,
    FlowFooter,
    PageTitle,
    PageSubtitle,
    PageHeadingLabel,
    CircularFormProgress: () => import('./CircularFormProgress'),
  },
  provide() {
    return {
      _onUpdateFormComponent: this.onUpdateFormComponent,
      _clickAction: this.clickAction,
      _triggerFormSubmit: this.triggerFormSubmit,
      _getRecentlyClicked: () => this.recentlyClicked,
      _requestSubmitStatus: () => this.hasRequestedFormSubmit,
      _requestingComponentId: () => this.requestingComponentId,
    }
  },
  inject: {
    _isDraggable: { default: () => () => {} },
    _scrollToTop: { default: () => () => {} },
    // _updateGrid: { default: () => () => {} },
    _disableDelays: { default: () => () => {} },
    _isInWebApp: { default: () => () => {} },
    _getSelectionMode: { default: () => () => {} },
    _isInEditor: { default: () => () => {} },
    _getFlowPages: { default: () => () => [] },
    _getBuilderVersion: { default: () => () => [] },
    _triggerInfoBox: { default: () => () => [] },
    _elSel: { default: () => () => [] },
  },
  props: {
    form: Object,
    pages: Array,
    page: Object,
    pageId: String,
    pageIndex: Number,
    pageData: Object,
    userData: Object,
    components: Object,
    hasPrev: Boolean,
    hasNext: Boolean,
    infoBox: Boolean,
    pageMeetsRequirements: Boolean,
    hasFinish: Boolean,
    fullDestinationUrl: String,
    hideFooter: Boolean,
    hideHeader: Boolean,
    hideProgress: Boolean,
    isPopup: Boolean,
    showHiddenComponents: Boolean,
  },
  data() {
    return {
      isLocal: window.location.hostname === 'localhost',
      // gridLayoutArray: [],
      recentlyClicked: {},
      myTempVBind: { PageKey: 'main' },
      hasRequestedFormSubmit: false,
      requestingComponentId: null,
    }
  },
  computed: {
    ...unpack('form', ['show_next_button_on_incomplete', 'logo_link_url']),
    ...unpack('page', [
      'key',
      'transition',
      'tags',
      'headingLabel',
      'title',
      'subtitle',
      'headingLabelBlock',
      'titleBlock',
      'subtitleBlock',
      'headingLabelRich',
      'titleRich',
      'subtitleRich',
      'headingLabelColumn',
      'titleColumn',
      'subtitleColumn',
      'isFlush',
      'layout',
      'layoutBlocks',
      'facebook_pixel_event',
      'memberstack_form',
    ]),
    builderVersion() {
      return this._getBuilderVersion()
    },
    fullPage() {
      return this._getFlowPages().find(p => p.id === this.page.id)
    },
    useDefaultProgressLocation() {
      return ![...(this.form.components || []), ...(this.fullPage.components || [])].find(
        c => c.type === 'ProgressBar'
      )
    },
    useDefaultFooter() {
      const components = [...(this.form.components || []), ...(this.fullPage.components || [])]
      const hasFooter = components.find(c => ['FlowFooter'].includes(c.type))
      if (hasFooter) return false
      const hasPrev = components.find(c => ['PrevFooterButton'].includes(c.type))
      const hasNext = components.find(c => ['NextFooterButton'].includes(c.type))
      const hasBoth = hasPrev && hasNext
      return !hasBoth
    },
    useDefaultPageHeadings() {
      const componentList = [...(this.form.components || []), ...(this.fullPage.components || [])]
      return {
        title: !componentList.find(c => ['PageTitle'].includes(c.type)),
        subtitle: !componentList.find(c => ['PageSubtitle'].includes(c.type)),
        heading: !componentList.find(c => ['PageHeadingLabel'].includes(c.type)),
      }
    },
    defaultHeadingComponents() {
      return defaultHeadingComponents()
    },
    showFormLogoInPage() {
      return this.form.logoImgUrl && this.form.logoPlacement === 'top-left'
    },
    pageComponents() {
      const components = this.page.components || []
      const page = this.fullPage
      const containerKeys = new Set(
        page.components.filter(c => c.type === 'Container').map(c => c.key)
      )
      return components.filter(c => (c.parent_key ? !containerKeys.has(c.parent_key) : true))
    },
    showProgressBar() {
      return this.form && this.form.progressBarType === 'circle'
    },
    columns() {
      return range(
        1,
        Math.max(
          ...this.pageComponents
            .map(({ column }) => column || 1)
            .concat([this.titleColumn || 1, this.subtitleColumn || 1])
        ) + 1,
        1
      )
    },
    blockStyles() {
      return (this.layoutBlocks || []).map(lb => ({
        'justify-content': (lb && lb.align) || undefined,
      }))
    },
    componentTabGroupIndexes() {
      const components = this.page.components || []
      return components.map((c, index) => {
        const { tab_group } = c || {}

        const tabGroupIndex = components
          .filter(lb => lb.tab_group === tab_group)
          .findIndex(lb => lb.index === index)

        return tab_group ? { [tab_group]: tabGroupIndex } : {}
      })
    },
    inPageClasses() {
      return [
        `in-page-key-${this.page.key}`,
        ...(this.page.tags || []).map(tag => `in-page-tag-${tag}`),
      ]
    },
    globalComponents() {
      const components = (this.form && this.form.components) || []
      return this.showHiddenComponents ? components : components.filter(this.filterByConditions)
    },
  },
  // beforeDestroy() {
  //   clearTimeout(this.timer)
  // },
  watch: {
    pageId: {
      async handler(v, o) {
        clearTimeout(this.pageTimeout)
        if (!this.page) return
        if (!this._disableDelays()) {
          const timeoutDelay = this.page.next_on_timeout
          if (timeoutDelay && !(this.$route && this.$route.query.current_page_id)) {
            const self = this
            this.pageTimeout = setTimeout(() => {
              if (self.pageMeetsRequirements && !this._disableDelays()) self.$emit('next')
            }, Number(timeoutDelay))
          }
        }
        if (v === o) return
        this.hasRequestedFormSubmit = false
        if (this.facebook_pixel_event || this.page.conversions) {
          conversionTrack(this.page, this.userData, this.form)
        }
        await this.$nextTick()
        if (!this.$refs.formpage) return
        // const el = this.$refs.formpage.$el
        if (this.infoBox) return
        if (
          !this.isPopup &&
          this.form &&
          (this.form.scroll_to_top_page || this.form.scroll_to_document_top)
        ) {
          this._scrollToTop()
          // el.scrollIntoView({ behavior: 'smooth' })
        }
        if (!this.isPopup || (this.form && this.form.dont_scroll_to_top_popup)) return
        this._scrollToTop()
        // el.scrollIntoView({ behavior: 'smooth' })
      },
      immediate: true,
    },
    // facebook_pixel_event: {
    //   handler(e, o) {
    //     if (isEqual(e, o)) return
    //     console.log(`thef `)
    //     conversionTrack(this.page, this.userData, this.form)
    //   },
    //   deep: true,
    // },
    pageComponents: {
      handler(c, old) {
        if (isEqual(c, old)) return
        // this.gridLayoutArray = c
        //   // .filter(item => item.grid)
        //   .map((item, i) => {
        //     const grid = item.grid || {}
        //     const { w = 4, h = 6, x = i, y = 0 } = grid
        //     return { w, h, x, y, i, id: item.id, item }
        //   })
      },
      deep: true,
      immediate: true,
    },
    // gridLayoutArray: {
    //   handler(gridLayoutArray, old) {
    //     if (isEqual(gridLayoutArray, old)) return
    //     this._updateGrid(gridLayoutArray)
    //   },
    //   deep: true,
    // },
  },
  methods: {
    onEnter({ component }) {
      const userInputsAllowed = new Set([
        'InputBox',
        'AddressBox',
        'OptionButtons',
        'OptionSelector',
        'FileUpload',
        'CustomButton',
      ])
      const inputComponents = this.pageComponents.filter(c => userInputsAllowed.has(c.type))
      const componentIsLastInput = inputComponents[inputComponents.length - 1].id === component.id

      if (componentIsLastInput && this.pageMeetsRequirements) {
        this.$emit('next')
      }
    },
    filterByConditions(item) {
      if (item.hide) return false
      return passesConditions(
        item.conditions || [],
        this.userData || {},
        this.components,
        this._isInWebApp()
      )
    },
    triggerFormSubmit(id) {
      if (this.$refs.formpage && this.$refs.formpage.$el) {
        const formEl = this.$refs.formpage.$el
        if (typeof this.$refs.formpage.$el.requestSubmit === 'function') {
          formEl.requestSubmit()
        } else {
          const manualButton = formEl.querySelector('button#formpage-submit[type="submit"]')
          if (manualButton) manualButton.click()
          else {
            const submitButton = document.createElement('button')
            submitButton.type = 'submit'
            formEl.appendChild(submitButton)
            submitButton.click()
            submitButton.remove()
          }
        }
      }
      this.hasRequestedFormSubmit = true
      if (this.form.disable_form_validation) {
        this.requestingComponentId = id
        // clearTimeout(this.timer)
        // this.timer = setTimeout(() => (this.hasRequestedFormSubmit = false), 5000)
      }
    },
    async onPrev() {
      this.$set(this.recentlyClicked, 'prev', false)
      setTimeout(() => this.$set(this.recentlyClicked, 'prev', true), 10)
      this.$emit('prev')
    },
    async onNext(nextButtonClicked, skipValidation) {
      if (!skipValidation) {
        if (nextButtonClicked === true) {
          this.$set(this.recentlyClicked, 'next', false)
          setTimeout(() => this.$set(this.recentlyClicked, 'next', true), 10)
        }
        await this.$nextTick()
        this.triggerFormSubmit()
      }
      this.$emit('next', skipValidation)
    },
    onUpdateFormComponent(component, [key, value]) {
      const pageComponents = this.fullPage.components
      const components = cloneDeep(pageComponents)
      components.find(c => c.id === component.id)[key] = value
      this.$emit('update-form', ['components', components])
    },
    onUpdateNavButton(button, [key, value]) {
      const newButton = cloneDeep(button)
      newButton[key] = value
      this.$emit('update-form', ['custom_next_button', newButton])
    },
    onUpdate([key, value], extra) {
      const updateValues = extra || {}
      const updateObj = { ...this.pageData, ...updateValues }
      if (key !== undefined && value !== undefined) updateObj[key] = value
      this.$emit('update', [this.pageId, updateObj])
    },
    clickAction(component, index) {
      if (this._getSelectionMode()) return
      if (component.linkUrl) {
        const [actionCommand] = component.linkUrl.split('?')
        const [action, actionTarget] = actionCommand.split(':')

        switch (action) {
          case 'open_popup': {
            const infoBoxName = parseURIComponent(actionTarget)
            this._triggerInfoBox(infoBoxName)
            break
          }
          default: {
            if (this._isInEditor()) {
              this.$message.info(
                `This action will send you to ${component.linkUrl} in your deployed Flow`
              )
            } else {
              const watchNav = this.form && this.form.emit_nav_events
              if (watchNav) emitSyntheticEvent('savvy.navigation', { url: component.linkUrl })
              else this.openLink(component.linkUrl, component.open_link_in_new_tab)
            }
          }
        }
      }
      if (component.downloadData) this.downloadData(component.downloadData)

      if (typeof index !== 'number') return
      const components = this.page.components || []

      const { tab_group } = component

      const tabGroupIndex = components
        .map((lb, index) => ({ ...lb, index }))
        .filter(lb => lb.tab_group === tab_group)
        .findIndex(lb => lb.index === index)

      if (tab_group) this.$emit('update', [tab_group, { [tab_group]: tabGroupIndex }])
    },
    openLink(url, inNewTab) {
      if (url) window.open(url, inNewTab === false ? '_self' : '_blank')
    },
    async downloadData(downloadData) {
      const key = downloadData.slice(2, -2)

      const data = get(this.userData, key)

      const columns = Array.from(new Set(flatten(data.map(c => Object.keys(c)))))
      const Papa = await import('papaparse')
      const csvString = Papa.unparse(data, { columns })

      const csvContent = 'data:text/csv;charset=utf-8,' + csvString

      var encodedUri = encodeURI(csvContent.replace(/#/g, '%23'))

      window.open(encodedUri, '_blank')
    },
    generatePageSelectors,
  },
}
</script>

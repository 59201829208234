export function universalIdToProper(text) {
  return typeof text === 'string'
    ? text
        .split(/-|_/)
        .filterExists()
        .map(t => t[0].toUpperCase() + t.slice(1))
        .join(' ')
        .split(/:|\./)
        .filterExists()
        .map(t => t[0].toUpperCase() + t.slice(1))
        .join(': ')
    : text
}

export function kebabToProper(text) {
  return text
    .split('-')
    .filterExists()
    .map(t => t[0].toUpperCase() + t.slice(1))
    .join(' ')
}

export function properToKebab(text) {
  return text
    .split(' ')
    .filterExists()
    .map(t => t.toLowerCase())
    .join('-')
}

export function properToSnake(text) {
  return text
    .split(' ')
    .filterExists()
    .map(t => t.toLowerCase())
    .join('_')
}

export function camelToSnake(text) {
  return text.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

export function pascalToSnake(text) {
  return camelToSnake(text).slice(1)
}

export function snakeToPascal(text) {
  return text
    .split('_')
    .map(w => `${w[0].toUpperCase()}${w.slice(1)}`)
    .join('')
}

export function snakeToProper(text) {
  return text
    .split('_')
    .filterExists()
    .map(t => t[0].toUpperCase() + t.slice(1))
    .join(' ')
}

export function camelToProper(text) {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function pascalToProper(text) {
  return camelToProper(text).slice(1)
}

export function properToCamel(text) {
  return text
    .split(' ')
    .filterExists()
    .map((word, i) =>
      i === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('')
    .replace(/[^a-zA-Z0-9 ]/g, '')
}

export function toSnake(text) {
  return text
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .filterExists()
    .map(word => word.toLowerCase())
    .join('_')
}

export function eventIdToReadable(eventId, context) {
  const { stages } = context
  if (eventId.startsWith('funnel.')) {
    const split = eventId.split('.')
    const stageId = split[split.length - 1]
    const stageLabel = stages.find(s => s.id === stageId) || { label: stageId }

    return `Manually moved to ${stageLabel.label}`
  }
  switch (eventId) {
    case 'visit-site':
    case '$pageview':
      return 'Page View'
    case 'enter-card':
      return 'Entered Credit Card'
    case 'submit-email':
      return 'Submitted Email'
    case 'began-email-submit':
    case 'begin-email-submit':
      return 'Started Typing Email'

    default:
      return universalIdToProper(eventId)
  }
}

export function toCapitalCase(id) {
  return id
    .split(/-|_/)
    .filterExists()
    .map(t => t[0].toUpperCase() + t.slice(1))
    .join(' ')
}

export function getInitials(text, max) {
  let initials =
    text &&
    text
      .split(/\s|-|_|\[|\]/g)
      .filterExists()
      .map(w => w[0] && w[0].toUpperCase())
      .join('')

  return initials.length > max
    ? initials
        .split(/\+|&/g)
        .filterExists()
        .join('')
        .slice(0, max)
    : initials
}

export function parseURIComponent(p) {
  if (!p) return p
  return decodeURIComponent(p.replace(/\+/g, ' '))
}

export function trunc(text, maxChars) {
  return typeof text === 'string' && text.length > maxChars ? text.slice(0, maxChars) + '...' : text
}

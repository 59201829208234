const ongoingTrackData = {}

setup()

function setup() {
  if (window.jitsu) return

  window.jitsu =
    window.jitsu ||
    function() {
      ;(window.jitsuQ = window.jitsuQ || []).push(arguments)
    }

  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://t.jitsu.com/s/lib.js'
  script.setAttribute('data-init-only', 'false')
  script.setAttribute('defer', 'true')
  script.setAttribute('data-key', 'js.w0ovvxugd8gfml4xqqaclc.0pzubyvfcjqs2rktl1s2yzt')

  document.head.appendChild(script)

  const user_id = getGlobalUserId()

  const legacy_product_id = getLegacyProductId()

  set({ user_id, is_new_engine: false, legacy_product_id })
  identify({ id: user_id, is_new_engine: false, legacy_product_id })

  track('webpage:viewed', { view_type: 'load' })

  window.history.pushState = new Proxy(window.history.pushState, {
    apply: (target, thisArg, argArray) => {
      // Not just an anchor link change
      if (!argArray[2].startsWith('#')) track('webpage:viewed', { view_type: 'view' })

      return target.apply(thisArg, argArray)
    },
  })
}

export default {
  track,
  identify,
  set,
}

export function track(...args) {
  return runJitsu('track', ...args)
}

export function identify(data, ...args) {
  try {
    sendToTrackingQueue({ ...ongoingTrackData, ...data })
  } catch (error) {
    console.warn('Error sending Jitsu identify data to tracking queue:', error)
  }
  return runJitsu('id', data, ...args)
}

export function set(data, ...args) {
  // @TODO: Use ongoingTrackData to set data for track() calls in Jitsu iself
  // Because Jitsu doesn't handle multiple flows on the page with different flow_ids, userDatas etc.
  try {
    Object.entries(data || {}).forEach(([key, value]) => {
      console.log('ongoingTrackData', ongoingTrackData)
      ongoingTrackData[key] = value
      sendToTrackingQueue(ongoingTrackData)
    })
  } catch (error) {
    console.warn('Error setting Jitsu data for track() calls in other services:', error)
  }

  return runJitsu('set', data, ...args)
}

function runJitsu(method, ...args) {
  try {
    if (tooLarge(args[0], 25)) {
      console.warn(
        `Cannot do Jitsu ${method.toUpperCase()} - Too Large at ${estimatedSizeInKb(args[0])}kb`,
        ...args
      )
    } else {
      if (
        new URL(window.location).searchParams.get('savvy_debug') === 'true' ||
        localStorage.getItem('savvy_debug')
      )
        console.log(`Jitsu ${method.toUpperCase()}:`, ...args)
      else if (window.location.host.startsWith('localhost:')) return
      window.jitsu(method, ...args)
    }
  } catch (error) {
    console.error(`Error in Jitsu analytics ${method} call:`, error)
  }
}

function tooLarge(obj, maxSizeInKb) {
  return estimatedSizeInKb(obj) > maxSizeInKb
}

function estimatedSizeInKb(obj) {
  if (!obj) return 0
  const charsPerKb = 1024
  const length = (JSON.stringify(obj) && JSON.stringify(obj).length) || 0

  return length / charsPerKb
}

function getGlobalUserId() {
  const localStorageKey = 'SAVVY_GLOBAL_ID'
  let id = localStorage.getItem(localStorageKey)

  if (!id) {
    id =
      Math.random()
        .toString(20)
        .slice(2) +
      Math.random()
        .toString(20)
        .slice(2)
    localStorage.setItem(localStorageKey, id)
  }

  return id
}

function getLegacyProductId() {
  const el = document.getElementsByTagName('savvy-smart-form')[0]
  if (!el) return

  return el.getAttribute('id')
}

function sendToTrackingQueue(data) {
  // @TODO: Stop this from sending if in local env
  data = { ...data, ...JSON.parse(data.merged_data || '{}') }
  const keysToRemove = ['merged_data', 'all_data', 'entryId']
  keysToRemove.forEach(key => {
    if (data[key] !== undefined) delete data[key]
  })
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      _timestamp: Date.now(),
      event_name: 'user_identify',
      ...data,
    }),
  }

  fetch('https://savvy-queues.heysavvy.workers.dev/', options).catch(err =>
    console.error(`Error sending to analytics queue`, err)
  )
}

export function defaultHeadingComponents() {
  return {
    title: { key: '__default_page_title', id: '__default_page_title', type: 'PageTitle' },
    subtitle: {
      key: '__default_page_subtitle',
      id: '__default_page_subtitle',
      type: 'PageSubtitle',
    },
    heading: {
      key: '__default_page_heading',
      id: '__default_page_heading',
      type: 'PageHeadingLabel',
    },
  }
}

export function addDefaultComponents(components, globals = []) {
  const componentsArray = [...components, ...globals]
  const seenComponents = new Set(componentsArray.map(c => c.type))
  const defaultComponents = defaultHeadingComponents()
  if (!seenComponents.has('PageSubtitle')) {
    componentsArray.unshift(defaultComponents.subtitle)
  }
  if (!seenComponents.has('PageTitle')) {
    componentsArray.unshift(defaultComponents.title)
  }
  if (!seenComponents.has('PageHeadingLabel')) {
    componentsArray.unshift(defaultComponents.heading)
  }
  return componentsArray
}

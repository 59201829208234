var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.componentsToShow.length > 0)?_c('div',{staticClass:"FlowComponents Container",class:[_vm.location, _vm._elSel()]},_vm._l((_vm.componentsToShow),function(component){return _c('FormComponent',_vm._b({key:component.id,on:{"update":_vm.onUpdate,"prev":_vm.onPrev,"next":_vm.onNext,"enter":_vm.onEnter,"reset":function($event){return _vm.$emit('reset')},"go-to-page":function($event){return _vm.$emit('go-to-page', $event)},"update-form":function($event){return _vm.onUpdateFormComponent(component, $event)}},nativeOn:{"click":function($event){return _vm.clickAction(component, component._originalIndex)}}},'FormComponent',{
      form: _vm.form,
      component: component,
      page: _vm.page,
      pageData: _vm.userData,
      userData: _vm.userData,
      componentTabGroupIndexes: _vm.componentTabGroupIndexes,
      componentIndex: component._originalIndex,
      pageMeetsRequirements: _vm.pageMeetsRequirements,
      components: _vm.components,
    },false))}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="PlainText">
    <PlainEditableText
      v-if="_isEditing()"
      :class="_elSel('InnerText')"
      v-bind="{ ...component, initialText: component.text }"
      @update-text="$emit('update-form', ['text', $event])"
    />
    <TextValue v-else :class="_elSel('InnerText')" v-bind="{ ...component, userData, form }" />
  </div>
</template>

<script>
import TextValue from './TextValue'

export default {
  name: 'PlainText',
  components: {
    TextValue,
    PlainEditableText: () => import('../utilities/PlainEditableText.vue'),
  },
  inject: {
    _elSel: { default: () => () => [] },
    _isEditing: { default: () => () => false },
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    form: Object,
    userData: Object,
  },
}
</script>

<script>
import Bowser from 'bowser'
export default {
  props: { isForm: Boolean, form: Object },
  provide() {
    return {
      _submitForm: this.submitForm,
    }
  },
  computed: {
    isiOSOrSafari() {
      try {
        const browser = Bowser.getParser(window.navigator.userAgent)
        const isSafari = browser.getBrowser().name === 'Safari'
        const isiOS = browser.getOSName() === 'iOS'
        return isiOS || isSafari
      } catch (error) {
        console.error(error)
        return true
      }
    },
  },
  methods: {
    submitForm() {
      if (this.isiOSOrSafari) {
        if (this.$refs.button) {
          const button = this.$refs.button.$el || this.$refs.button
          button.click()
        }
      } else {
        if (this.$refs.form) {
          if (typeof this.$refs.form.requestSubmit === 'function') {
            this.$refs.form.requestSubmit()
          } else this.$refs.form.submit()
        }
      }
    },
  },
  render() {
    const inner = this.$scopedSlots.default ? this.$scopedSlots.default() : null
    return this.isForm ? (
      <form
        ref="form"
        novalidate={this.form.disable_form_validation}
        onSubmit={e => e.preventDefault()}
      >
        {inner}
        {this.isiOSOrSafari ? (
          <button
            ref="button"
            id="formpage-submit"
            type="submit"
            style="visibility: hidden; position: absolute;"
          />
        ) : null}
      </form>
    ) : (
      <div>{inner}</div>
    )
  },
}
</script>

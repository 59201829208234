<template>
  <IconifyIcon
    v-if="iconIsLoaded"
    class="icon"
    :class="{ 'animate-spin': spin }"
    :icon="iconString"
    :inline="inline || false"
  />
  <span
    v-else-if="Array.isArray(icon) || (icon && icon.indexOf(':') === -1)"
    class="Icon icon"
    :class="[iconClass, { 'animate-spin': spin }]"
  />
  <IconifyIcon
    v-else
    class="icon"
    :class="{ 'animate-spin': spin }"
    :icon="iconString"
    :inline="inline || false"
  />
  <!-- <svg
    class="icon"
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    v-if="library === 'feather'"
  >
    <use :xlink:href="`/svg/feather-sprite.svg#${icon[1]}`" />
  </svg>
  -->
</template>

<script>
import { iconToString } from '@/helpers/loadIconify'
import IconifyIcon from './Iconify'

export default {
  name: 'Icon',
  components: {
    IconifyIcon,
  },
  inject: { _loadedIcons: { default: () => () => {} } },
  props: {
    icon: [Array, String],
    inline: Boolean,
    spin: Boolean,
  },
  computed: {
    loadedIcons() {
      return this._loadedIcons()
    },
    iconIsLoaded() {
      const icon = this.iconArr
      const [col, ic] = icon //.split(':')
      const icons = this.loadedIcons
      if (!icons) return false
      if (!Array.isArray(icons[col])) return false
      return Boolean(icons[col].find(c => c === ic))
    },
    iconString() {
      return `${this.library}:${this.iconName}`
    },
    iconArr() {
      const { icon } = this

      const iconString = iconToString(icon)

      const split = iconString && iconString.split(':')

      if (split.length === 2) return split

      return ['fa-regular', icon]
    },
    library() {
      return this.iconArr[0]
    },
    iconName() {
      return this.iconArr[1]
    },
    iconClass() {
      const { icon } = this

      return Array.isArray(icon) ? `${icon[0]} fa-${icon[1]}` : `fas fa-${icon}`
    },
  },
}
</script>

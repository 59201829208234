<template>
  <div
    class="BookMeeting"
    :class="[`service-${service}`, (service === 'calendly' && 'Calendly') || '']"
    ref="book_meeting"
  >
    <div
      v-if="(scriptLoaded === false && service === 'hubspot') || service === 'calendly'"
      class="spinner"
    >
      <Icon name="spinner" class="fa-spin" />
    </div>
    <div
      v-if="service === 'calendly'"
      ref="calendlyContainer"
      class="meetings-iframe-container calendly-container"
      :data-auto-load="false"
    />
    <div v-else-if="service === 'hubspot'" class="meetings-iframe-container" :data-src="embedUrl" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import computedValues from './editor/helpers/computedValues'
import conversionTrack from '@/components/form/helpers/conversionTrack'
/* global Calendly */

export default {
  inject: ['_track', '_isPreview', '_setCheckpoint', '_sendIndividualDataOutput'],
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    userData: {},
    form: Object,
  },
  data() {
    return {
      scriptLoaded: false,
    }
  },
  computed: {
    service() {
      return this.component.service || 'calendly'
    },
    embedUrl() {
      const embedUrl = computedValues(this.userData, this.component.embedUrl, this.form)

      switch (this.service) {
        case 'hubspot': {
          const base = embedUrl
          const prefill = this.component.prefill || {}
          const params = Object.entries(prefill).reduce((acc, e) => {
            if (e[1]) {
              const str = `${e[0]}=${computedValues(this.userData, e[1], this.form)}`
              acc.push(str)
            }
            return acc
          }, [])

          return params.length
            ? `${base}${base.includes('?') ? '&' : '?'}${params.join('&')}`
            : base
        }

        default:
          return embedUrl
      }
    },
    currentBookTier() {
      const key = this.component.key || this.component.id
      return eventTier(this.userData[key])
    },
    scriptSrc() {
      switch (this.service) {
        case 'calendly':
          return `https://assets.calendly.com/assets/external/widget.js`
        // case 'hubspot':
        // return `https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js`
        // return `https://flows.trysavvy.com/js/hubspotMeetingBooker.js`
        default:
          return null
      }
    },
  },
  mounted() {
    this.debouncedHubspot = debounce(this.listenForHubspotBooking, 250, {
      leading: false,
      trailing: true,
    })
    this.loadScriptTag()
  },
  beforeDestroy() {
    if (this.listenForUpdate) window.removeEventListener('message', this.listenForUpdate)
    if (this.listenForHubspotBooking) window.removeEventListener('message', this.debouncedHubspot)
  },
  methods: {
    async loadScriptTag() {
      if (this._isPreview()) return
      const self = this
      if (this.service === 'hubspot' && this.embedUrl) {
        const { hubspotMeetingBooker } = await import('./helpers/hubspotMeetingBooker.js')
        this.scriptLoaded = true
        hubspotMeetingBooker(this.$refs.book_meeting)
        window.addEventListener('message', self.debouncedHubspot)
      }
      if (!this.scriptSrc) return
      // if (document.querySelector(`script[src='${this.scriptSrc}']`)) return
      let script = document.createElement('script')
      script.id = `${Math.round(Math.random() * 10000)}`
      script.setAttribute('src', this.scriptSrc)
      script.onload = () => {
        self.scriptLoaded = true
        if (self.service === 'calendly') self.createCalendly()
        // if (self.service === 'hubspot')
        //   window.addEventListener('message', self.listenForHubspotBooking)
      }
      if (this.$refs.book_meeting) this.$refs.book_meeting.appendChild(script)
    },
    createCalendly() {
      const self = this

      if (!this.$refs.calendlyContainer) return

      const prefill = {
        name: this.userData.name,
        lastName: this.userData.last_name,
        firstName: this.userData.first_name,
        email: this.userData.email,
      }
      if (Array.isArray(this.component.prefill_keys)) {
        prefill.customAnswers = this.component.prefill_keys.reduce((acc, k, i) => {
          if (k === '__empty') return acc
          const key = `a${i + 1}`
          const val = this.userData[k]
          if (val !== undefined && val !== null) acc[key] = val
          return acc
        }, {})
      }
      Calendly.initInlineWidget({
        url: this.embedUrl,
        parentElement: this.$refs.calendlyContainer,
        prefill,
        utm: {},
      })

      function isCalendlyEvent(e) {
        return e.data.event && e.data.event.indexOf('calendly') === 0
      }

      this.listenForUpdate = function(e) {
        if (isCalendlyEvent(e)) {
          self.onEvent(e.data.event, e.data.payload)
        }
      }
      window.addEventListener('message', this.listenForUpdate)
    },
    onEvent(eventName, data) {
      this._track(`${this.service}.${eventName}`, data)

      const status = getStatus(eventName)

      this.updateBookStatus(status)
      this.$emit(status)
      if (status === 'scheduled') this.$emit('completed')
    },
    listenForHubspotBooking(e) {
      if (e.origin !== 'https://meetings.hubspot.com') return
      if (e && e.data && e.data.height) {
        this._track(`hubspot.meeting_viewed`)
        this.updateBookStatus('viewed')
      }
      if (e && e.data && e.data.meetingBookSucceeded) {
        this._track(`hubspot.meeting_booked`)

        // const status = getStatus(eventName)
        this.updateBookStatus('booked')

        // this.$emit(status)
        // if (status === 'scheduled')
        // this.$emit('completed')
      }
    },
    async updateBookStatus(value, trackEvent, data) {
      const key = this.component.key || this.componentId
      const tier = eventTier(value)
      // if (tier === 2) {
      //   const checkpoints = this.component.checkpoints
      //   if (Array.isArray(checkpoints) && checkpoints.length > 0) {
      //     this._setCheckpoint(checkpoints)
      //   }
      // }
      if ([0, 1, 2].includes(tier)) {
        const now = new Date().toISOString()
        if (this.component[`conversions_tier_${tier + 1}`]) {
          conversionTrack(this.component, this.userData, this.form, `conversions_tier_${tier + 1}`)
        }
        /* tier 2 mapping for backwards compatibility */
        const checkpoints =
          tier === 2 ? this.component.checkpoints : this.component[`checkpoints_tier_${tier + 1}`]
        if (Array.isArray(checkpoints) && checkpoints.length > 0) {
          this._setCheckpoint(checkpoints)
        }
        const outputs = this.component[`outputs_tier_${tier + 1}`]
        if (Array.isArray(outputs)) outputs.forEach(k => this._sendIndividualDataOutput(k, now))
      }
      if (tier >= this.currentBookTier) {
        this.$emit('update', [key, value])
        if (trackEvent) this._track(trackEvent, data)
        if (tier === 2) {
          await this.$nextTick()
          if (this.component.next_on_complete) this.$emit('next')
        }
      }
    },
  },
}

function eventTier(event) {
  const eventTiers = [['loaded', 'viewed'], ['selected_timeslot'], ['scheduled', 'booked']]
  const tier = eventTiers.findIndex(t => t.includes(event))
  return tier === -1 ? 0 : tier
}

function getStatus(eventName) {
  const key = eventName.split('.')[1] || eventName
  switch (eventName.split('.')[1]) {
    case 'profile_page_viewed':
    case 'event_type_viewed': {
      return 'loaded'
    }
    case 'date_and_time_selected': {
      return 'selected_timeslot'
    }
    case 'event_scheduled': {
      return 'scheduled'
    }
    default:
      return key
  }
}
</script>

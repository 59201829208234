import Firebase from 'firebase/app'
import 'firebase/auth'
import Axios from 'axios'

const URL =
  process.env.VUE_APP_FIREBASE_PROJECT_ID === 'savvy-b2c-dev'
    ? `https://us-central1-savvy-b2c-dev.cloudfunctions.net/updateSavvy`
    : `https://us-central1-savvy-app-live.cloudfunctions.net/updateSavvy`

const ENV = process.env.VUE_APP_FIREBASE_PROJECT_ID === 'savvy-b2c-dev' ? 'Dev' : 'Prod'

export default async function notifySlack(text, channel, justText) {
  console.log('notifySlack', text, channel, justText)
  // return text
  const userId = Firebase.auth().currentUser && Firebase.auth().currentUser.uid
  const message = justText
    ? text
    : `${ENV} - User ${userId} encountered an error at ${window.location.toString()} - ${text} `
  await Axios.post(URL, {
    message,
    channelId: channel || (ENV === 'Dev' ? 'C01527NKEF6' : 'G012HKB0XQE'),
  })
}

export async function notifySavvy(message, channel, options) {
  const response = await Axios.post(URL, {
    message,
    channelId: ENV === 'Dev' ? 'C01527NKEF6' : channel || 'G012HKB0XQE',
    options,
  })
  return response.data
}

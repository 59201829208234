<script>
export default {
  props: {},
  inject: { _isEditing: { default: () => () => false } },
  render() {
    return this._isEditing()
      ? this.$scopedSlots.editing
        ? this.$scopedSlots.editing()
        : null
      : this.$scopedSlots.default
      ? this.$scopedSlots.default()
      : null
  },
}
</script>

export default function componentTypes() {
  return [
    {
      key: 'Container',
      label: 'Component Container',
      group: 'Layout',
      buttonIcon: 'bi:box',
      hasNoValue: true,
    },
    {
      key: 'PlainText',
      label: 'Plain Text',
      group: 'Static Display',
      buttonIcon: 'fluent:text-24-regular',
      hasNoValue: true,
    },
    {
      key: 'RichText',
      label: 'Rich Text',
      group: 'Static Display',
      buttonIcon: 'fluent:text-add-24-regular',
      hasNoValue: true,
    },
    {
      key: 'MediaImage',
      label: 'Image / GIF',
      group: 'Static Display',
      buttonIcon: 'akar-icons:image',
      hasNoValue: true,
    },
    {
      key: 'MediaEmbed',
      label: 'Video / Embed',
      group: 'Static Display',
      buttonIcon: 'fluent:video-clip-20-regular',
      hasNoValue: true,
    },
    {
      key: 'Lottie',
      label: 'Lottie Player',
      group: 'Static Display',
      buttonIcon: 'uil:lottiefiles',
      hasNoValue: true,
    },
    {
      key: 'InputBox',
      label: 'Input',
      group: 'User Input',
      buttonIcon: 'ic:twotone-input',
    },
    // {
    //   key: 'AddressBox',
    //   label: 'Address',
    //   group: 'User Input',
    // },
    {
      key: 'OptionSelector',
      label: 'Option Selector',
      group: 'User Input',
      buttonIcon: 'vaadin:options',
      hideFromCreator: true,
    },
    {
      key: 'FileUpload',
      label: 'File Upload',
      group: 'User Input',
      buttonIcon: 'ri:file-upload-line',
    },
    {
      key: 'CustomButton',
      label: 'Button',
      group: 'User Input',
      buttonIcon: 'dashicons:button',
      hasTriggers: true,
    },
    {
      key: 'BookMeeting',
      label: 'Book Meeting',
      group: 'Integrations',
      buttonIcon: '',
      hasTriggers: true,
      hideFromCreator: true,
    },
    {
      key: 'StripeCheckout',
      label: 'Stripe Checkout',
      group: 'Integrations',
      buttonIcon: 'ph:stripe-logo',
      hasTriggers: true,
    },
    {
      key: 'PaypalCheckout',
      label: 'Paypal Checkout',
      group: 'Integrations',
      buttonIcon: 'bi:paypal',
      hasTriggers: true,
    },
    {
      key: 'RegisterForMeeting',
      label: 'Register For Meeting',
      group: 'Advanced',
      buttonIcon: 'zondicons:inbox-check',
    },
    {
      key: 'CustomAuth',
      label: 'Custom Auth',
      group: 'Advanced',
      buttonIcon: 'carbon:two-factor-authentication',
    },
    // {
    //   key: 'VideoCall',
    //   label: 'Video Call (Daily.co)',
    //   group: 'Advanced',
    //   buttonIcon: 'fluent:video-person-call-24-regular',
    // },
    {
      key: 'ProgressBar',
      label: 'Savvy Progress Bar',
      group: 'Savvy Presets',
      buttonIcon: '',
      // noEditor: true,
      hasNoValue: true,
    },
    {
      key: 'FlowFooter',
      label: 'Footer',
      group: 'Savvy Presets',
      buttonIcon: '',
      noEditor: true,
      hasNoValue: true,
    },
    {
      key: 'PrevFooterButton',
      label: 'Prev Footer Button',
      group: 'Savvy Presets',
      buttonIcon: 'dashicons:button',
      // noEditor: true,
      hasNoValue: true,
    },
    {
      key: 'NextFooterButton',
      label: 'Next Footer Button',
      group: 'Savvy Presets',
      buttonIcon: 'dashicons:button',
      // noEditor: true,
      hasNoValue: true,
    },
    {
      key: 'PageTitle',
      label: 'Page Title',
      group: 'Savvy Presets',
      buttonIcon: 'fluent:text-24-regular',
      // noEditor: true,
      hasNoValue: true,
    },
    {
      key: 'PageSubtitle',
      label: 'Page Subtitle',
      group: 'Savvy Presets',
      buttonIcon: 'fluent:text-24-regular',
      // noEditor: true,
      hasNoValue: true,
    },
    {
      key: 'PageHeadingLabel',
      label: 'Page Heading Label',
      group: 'Savvy Presets',
      buttonIcon: 'fluent:text-24-regular',
      // noEditor: true,
      hasNoValue: true,
    },
    {
      key: 'PlaceholderComponent',
      label: 'Placeholder Component',
      group: 'Savvy Presets',
      buttonIcon: 'fluent:text-24-regular',
      noEditor: true,
      hasNoValue: true,
    },
  ]
}

export function inputTypes() {
  return [
    'text',
    'email',
    'number',
    'checkbox',
    'phone',
    'date',
    'time',
    'month',
    'password',
    'switch',
    'range',
    'confirm',
  ]
}

export function extraComponentTypes() {
  return [
    {
      key: 'OptionSelector-Buttons',
      label: 'Option Buttons',
      group: 'User Input',
      buttonIcon: 'vaadin:options',
      componentKey: 'OptionSelector',
      componentProperties: { dropdown: false },
    },
    {
      key: 'OptionSelector-Dropdown',
      label: 'Dropdown Selector',
      group: 'User Input',
      buttonIcon: 'carbon:list-dropdown',
      componentKey: 'OptionSelector',
      componentProperties: { dropdown: true },
    },
    {
      key: 'InputBox-Slider',
      label: 'Range Slider',
      group: 'User Input',
      buttonIcon: 'radix-icons:slider',
      componentKey: 'InputBox',
      componentProperties: { input_type: 'range' },
    },
    {
      key: 'InputBox-Switch',
      label: 'Toggle Switch',
      group: 'User Input',
      buttonIcon: 'fad:roundswitch-off',
      componentKey: 'InputBox',
      componentProperties: { input_type: 'switch' },
    },
    {
      key: 'InputBox-Checkbox',
      label: 'Checkbox',
      group: 'User Input',
      buttonIcon: 'fluent:checkbox-checked-16-regular',
      componentKey: 'InputBox',
      componentProperties: { input_type: 'checkbox' },
    },
    {
      key: 'MeetingBooker-Calendly',
      label: 'Calendly Meeting Booker',
      group: 'Integrations',
      buttonIcon: 'ant-design:calendar-outlined',
      componentKey: 'BookMeeting',
      componentProperties: { service: 'calendly' },
    },
    {
      key: 'MeetingBooker-HubSpot',
      label: 'HubSpot Meeting Booker',
      group: 'Integrations',
      buttonIcon: 'cib:hubspot',
      componentKey: 'BookMeeting',
      componentProperties: { service: 'hubspot' },
    },
  ]
}
// { page: Page, style: Style }, will auto assign default container key
export function defaultStyles(builderVersion) {
  switch (builderVersion) {
    case 3:
      return {
        '.Flow-Element.ElementType-OfflineIndicator': {
          position: 'absolute',
          bottom: '0',
          'z-index': '50',
          width: '100%',
          padding: '10px',
          'text-align': 'center',
          'background-color': 'lightcoral',
          color: 'white',
          'font-weight': '400',
        },
        '.Flow-EntireFlow': {
          '--primary-color': '#6454f3',
          '--fail-color': 'red',

          // Reset any inherited styles??
          'text-align': 'left',

          // Set default styles
          'font-family': "'Lato', sans-serif",
          'font-size': '16px',
          'font-weight': '300',
          'line-height': '1.5',
          color: '#555',
        },
        '.Flow-Page': {
          position: 'relative',
          padding: '32px',
        },
        '.ElementType-PageContents': {
          width: '100%',
        },

        // Component Types
        '.Flow-Component.ComponentType-RichText ul': {
          'list-style': 'disc',
          'padding-left': '1.1em',
        },
        '.Flow-Component.ComponentType-InputBox': {
          display: 'flex',
          'flex-direction': 'column',
          position: 'relative',
          'max-width': '100%',
          width: '300px',
        },
        '.Flow-Component.ComponentType-OptionSelector': {
          display: 'flex',
          'flex-direction': 'column',
          position: 'relative',
        },
        '.Flow-Component.ComponentType-CustomButton': {
          'box-sizing': 'border-box',
          border: '1px solid #ddd',
          padding: '8px 32px',
          'border-radius': 'var(--border-radius)',
          'background-color': 'white',
          color: 'inherit',
          transition: 'background-color 0.3s, color 0.3s',
          cursor: 'pointer',
        },
        '.Flow-Component.ComponentType-MediaEmbed': {
          position: 'relative',
          width: '100%',
        },

        // Component States
        '.Flow-Component.ComponentType-CustomButton:hover': {
          'background-color': '#fafafa',
        },
        '.Flow-Component.ComponentType-CustomButton.selected': {
          'border-color': 'var(--primary-color)',
        },
        '.Flow-Component.ComponentType-CustomButton.disabled': {
          cursor: 'default',
          background: '#eee',
          'pointer-events': 'none',
          color: '#aaa',
        },

        // Conponent Tags
        '.Flow-Component.ComponentTag-main_container': {
          display: 'flex',
          'flex-direction': 'column',
          'justify-content': 'flex-start',
          'align-items': 'center',
        },
        '.Flow-Component.ComponentTag-row': {
          flex: 1,
          display: 'flex',
          'flex-direction': 'row',
          'justify-content': 'stretch',
        },
        '.Flow-Component.ComponentTag-column': {
          flex: 1,
          display: 'flex',
          'flex-direction': 'column',
        },
        '.Flow-Component.ComponentTag-title': {
          'font-size': '44px',
          'font-weight': '600',
          'justify-content': 'center',
          display: 'flex',
          'margin-bottom': '16px',
        },
        '.Flow-Component.ComponentTag-subtitle': {
          'justify-content': 'center',
          display: 'flex',
          'font-size': '24px',
          'font-weight': '300',
          'margin-bottom': '16px',
        },
        '.Flow-Component.ComponentTag-dropdown_selector': {
          display: 'flex',
          'margin-top': '20px',
        },
        '.Flow-Component.ComponentTag-standard_input': {
          display: 'flex',
        },
        '.Flow-Component.ComponentTag-checkbox': {
          display: 'flex',
        },
        '.Flow-Component.ComponentTag-buttons_wrapped': {
          display: 'flex',
        },
        '.Flow-Component.ComponentTag-buttons_bars': {
          display: 'flex',
          'flex-direction': 'column',
          width: '100%',
          'align-items': 'center',
        },
        '.Flow-Component.ComponentTag-range': {},
        '.Flow-Component.ComponentTag-stripe_checkout': {},
        '.Flow-Component.ComponentTag-image': {},
        '.Flow-Component.ComponentTag-inputs_container': {
          display: 'flex',
          'flex-wrap': 'wrap',
          'justify-content': 'center',
        },
        '.Flow-Component.ComponentTag-header': {
          'justify-content': 'flex-end',
          display: 'flex',
          'flex-direction': 'row',
        },
        '.Flow-Component.ComponentTag-footer': {
          display: 'flex',
          'flex-direction': 'row',
          'justify-content': 'space-between',
          'align-items': 'center',
        },
        // '.Flow-Component.ComponentTag-global_next': {
        //   'justify-content': 'center',
        //   'align-items': 'center',
        //   display: 'flex',
        // },
        '.Flow-Component.ComponentTag-global_prev': {
          'background-color': 'transparent',
          border: 'none',
          'padding-left': '0px',
          'padding-right': '0px',
        },

        // Component Elements
        '.Flow-Element.ElementType-Label': {
          display: 'flex',
          'flex-direction': 'row',
          'align-items': 'end',
        },
        '.Flow-Element.ElementType-InputElement': {
          padding: '8px 16px',
          'border-radius': 'var(--border-radius)',
          border: '1px solid #ddd',
          width: '100%',
        },
        '.Flow-Element.ElementType-InputTextarea': {
          padding: '8px 16px',
          'border-radius': 'var(--border-radius)',
          border: '1px solid #ddd',
          width: '100%',
        },
        '.Flow-Element.ElementType-Image': {
          'max-width': '100%',
        },
        '.Flow-Element.ElementType-OptionButtonList': {
          display: 'flex',
          // 'flex-direction': 'row',
          'align-items': 'stretch',
          'justify-content': 'center',
          'flex-wrap': 'wrap',
        },
        '.Flow-Element.ElementType-OptionButtonCard': {
          display: 'flex',
          'flex-direction': 'column',
          'align-items': 'center',
          'justify-content': 'space-between',
          padding: '8px 32px',
          margin: '8px',
          flex: '0 0 auto',
          'text-align': 'center',
          'box-sizing': 'border-box',
          border: '1px solid #ddd',
          'background-color': 'white',
          'border-radius': 'var(--border-radius)',
          cursor: 'pointer',
          'line-height': '1.3',
          transition: 'transform 0.1s, border-color 0.5s, background-color 0.2s',
        },
        '.Flow-Element.ElementType-ButtonIconImage': {
          'max-width': '100%',
          'max-height': '50px',
        },
        '.Flow-Element.ElementType-ButtonIcon': {
          'font-size': '40px',
          color: '#999',
        },

        // States
        '.Flow-Component:selected .ElementType-ButtonIcon': {
          color: 'var(--primary-color)',
        },

        // Component Tags - Element
        '.Flow-Element.ElementType-OptionButtonList.ComponentTag-buttons_bars': {
          width: '100%',
          'max-width': '420px',
          'flex-direction': 'column',
          'flex-wrap': 'nowrap',
        },
        '.Flow-Element.ElementType-OptionButtonCard.ComponentTag-buttons_tiles': {
          padding: '30px 15px',
          width: '140px',
          'max-width': '140px',
          'min-height': '150px',
          'border-radius': 'var(--border-radius-lg)',
        },
        '.Flow-Element.ElementType-OptionButtonCard.ComponentTag-buttons_wrapped': {
          'max-width': '100%',
          'white-space': 'nowrap',
        },
        '.Flow-Element.ElementType-OptionButtonCard.ComponentTag-buttons_bars': {
          width: '100%',
          'max-width': '420px',
        },
        '.Flow-Element.ElementType-OptionButtonCardIcon.ComponentTag-buttons_wrapped': {
          display: 'none',
        },
        '.Flow-Element.ElementType-StripeCheckoutButton': {
          padding: '10px 20px',
          background: 'var(--primary-color)',
          color: 'white',
          'border-radius': '6px',
          border: 'none',
          cursor: 'pointer',
          transition: 'padding 0.3s',
        },
      }

    default:
      return {}
  }
}

export function pageLayouts() {
  return [
    {
      key: 'blank_page',
      label: 'Blank Page',
      buttonIcon: 'ic:baseline-check-box-outline-blank',
      group: 'Blank',
      template: {
        page: {
          components: [],
        },
      },
    },
    {
      key: '2_columns',
      label: '2 Columns',
      buttonIcon: 'feather:columns',
      group: 'Grids',
      template: {
        page: {
          components: [
            {
              key: 'container_row',
              type: 'Container',
              container_type: 'container',
              tags: ['row'],
            },
            {
              key: 'column_1_container',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_row',
              tags: ['column'],
            },
            {
              key: 'column_2_container',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_row',
              tags: ['column'],
            },
          ],
        },
      },
    },
    {
      key: '3_columns',
      label: '3 Columns',
      buttonIcon: 'fe:columns',
      group: 'Grids',
      template: {
        page: {
          components: [
            {
              key: 'container_row',
              type: 'Container',
              container_type: 'container',
              tags: ['row'],
            },
            {
              key: 'column_1_container',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_row',
              tags: ['column'],
            },
            {
              key: 'column_2_container',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_row',
              tags: ['column'],
            },
            {
              key: 'column_3_container',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_row',
              tags: ['column'],
            },
          ],
        },
      },
    },
    {
      key: '2_rows',
      label: '2 rows',
      buttonIcon: 'feather:columns@90',
      group: 'Grids',
      template: {
        page: {
          components: [
            {
              key: 'container_column',
              type: 'Container',
              container_type: 'container',
              tags: ['column'],
            },
            {
              key: 'container_row_1',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_column',
              tags: ['row'],
            },
            {
              key: 'container_row_2',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_column',
              tags: ['row'],
            },
          ],
        },
      },
    },
    {
      key: '3_rows',
      label: '3 rows',
      buttonIcon: 'fe:columns@90',
      group: 'Grids',
      template: {
        page: {
          components: [
            {
              key: 'container_column',
              type: 'Container',
              container_type: 'container',
              tags: ['column'],
            },
            {
              key: 'container_row_1',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_column',
              tags: ['row'],
            },
            {
              key: 'container_row_2',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_column',
              tags: ['row'],
            },
            {
              key: 'container_row_3',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_column',
              tags: ['row'],
            },
          ],
        },
      },
    },
    {
      key: '2_by_2',
      label: '2x2',
      buttonIcon: 'th-large',
      group: 'Grids',
      template: {
        page: {
          components: [
            {
              key: 'container_grid',
              type: 'Container',
              container_type: 'container',
              tags: ['column'],
            },
            {
              key: 'container_row_1',
              type: 'Container',
              container_type: 'container',
              tags: ['row'],
              parent_key: 'container_grid',
            },
            {
              key: 'container_row_2',
              type: 'Container',
              container_type: 'container',
              tags: ['row'],
              parent_key: 'container_grid',
            },
            {
              key: 'container_row_1_col_1',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_row_1',
              tags: ['grid_cell'],
            },
            {
              key: 'container_row_1_col_2',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_row_1',
              tags: ['grid_cell'],
            },
            {
              key: 'container_row_2_col_1',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_row_2',
              tags: ['grid_cell'],
            },
            {
              key: 'container_row_2_col_2',
              type: 'Container',
              container_type: 'container',
              parent_key: 'container_row_2',
              tags: ['grid_cell'],
            },
          ],
        },
      },
    },
    {
      key: 'option_buttons_tiles',
      label: 'Option Buttons (Tiles)',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/odUysNvBzsiKreGlmh27%2FGroup%204optionButtons_11536197770287127.png?alt=media&token=a4155c1d-f8bc-42d4-80f5-ba4fd2899f3d',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'input_value',
              type: 'OptionSelector',
              tags: ['buttons_tiles'],
              parent_key: 'container_main',
              buttons: [
                {
                  key: 'option_1',
                  text: 'Option 1',
                },
                {
                  key: 'option_2',
                  text: 'Option 2',
                },
                {
                  key: 'option_3',
                  text: 'Option 3',
                },
              ],
            },
          ],
        },
      },
    },
    // {
    //   key: 'option_buttons_two_by_two',
    //   label: 'Option Buttons 2 x 2',
    //   buttonImage:
    //     'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/W8DE1YuFIPk4O6dFl93C%2FGroup%2018options2by2_14313764243002358.png?alt=media&token=b0cbf356-552c-4e7e-9232-abd5b292d5b1',
    //   group: 'User Input',
    //   template: {
    //     page: {
    //       components: [
    //         {
    //           key: 'container_main',
    //           type: 'Container',
    //           container_type: 'container',
    //         tags: ['main_container', 'column'],
    //         },
    //         {
    //           key: 'title',
    //           type: 'PlainText',
    //           tags: ['title'],
    //           text: 'Page Title',
    //           parent_key: 'container_main',
    //         },
    //         {
    //           key: 'subtitle',
    //           type: 'PlainText',
    //           tags: ['subtitle'],
    //           text: 'Page Subtitle',
    //           parent_key: 'container_main',
    //         },
    //         {
    //           key: 'input_value',
    //           type: 'OptionSelector',
    //           tags: ['option_buttons_two_by_two'],
    //           parent_key: 'container_main',
    //           buttons: [
    //             {
    //               key: 'option_1',
    //               text: 'Option 1',
    //             },
    //             {
    //               key: 'option_2',
    //               text: 'Option 2',
    //             },
    //             {
    //               key: 'option_3',
    //               text: 'Option 3',
    //             },
    //             {
    //               key: 'option_4',
    //               text: 'Option 4',
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   },
    // },
    {
      key: 'option_buttons_wrapped',
      label: 'Option Buttons (Wrapped)',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%201wrappedButtons_21650109460837796.png?alt=media&token=7cd198ae-46ba-4d93-82cd-c908d07fbd38',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'input_value',
              type: 'OptionSelector',
              tags: ['buttons_wrapped'],
              parent_key: 'container_main',
              buttons: [
                {
                  key: 'option_1',
                  text: 'Option 1',
                },
                {
                  key: 'option_2',
                  text: 'Option 2',
                },
                {
                  key: 'option_3',
                  text: 'Option 3',
                },
                {
                  key: 'option_4',
                  text: 'Option 4',
                },
                {
                  key: 'option_5',
                  text: 'Option 5',
                },
              ],
            },
          ],
        },
      },
    },
    {
      key: 'option_buttons_bars',
      label: 'Option Buttons (Bars)',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%206optionButtonsBars_7886627050363095.png?alt=media&token=b9640ccb-7802-4054-aca7-429403f12833',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'input_value',
              type: 'OptionSelector',
              tags: ['buttons_bars'],
              parent_key: 'container_main',
              buttons: [
                {
                  key: 'option_1',
                  text: 'Option 1',
                },
                {
                  key: 'option_2',
                  text: 'Option 2',
                },
                {
                  key: 'option_3',
                  text: 'Option 3',
                },
              ],
            },
          ],
        },
      },
    },
    {
      key: 'checkbox_page',
      label: 'Option Buttons (Checkboxes)',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%209checkbox_31332979335353883.png?alt=media&token=554f9a95-ff4e-42c4-99bb-71ae839a466f',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'input_value',
              type: 'OptionSelector',
              input_type: 'checkbox',
              tags: ['buttons_bars', 'checkbox'],
              parent_key: 'container_main',
              buttons: [
                {
                  key: 'option_1',
                  text: 'Option 1',
                },
                {
                  key: 'option_2',
                  text: 'Option 2',
                },
                {
                  key: 'option_3',
                  text: 'Option 3',
                },
              ],
            },
          ],
        },
      },
    },
    {
      key: 'dropdown_selector_page',
      label: 'Dropdown Selector',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/W8DE1YuFIPk4O6dFl93C%2FGroup%207dropdown(1)_9125957797832535.png?alt=media&token=8bf25aaf-06c6-4681-b6ac-6034f2f9b60a',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'input_value',
              type: 'OptionSelector',
              dropdown: 'true',
              tags: ['dropdown_selector'],
              parent_key: 'container_main',
              buttons: [
                {
                  key: 'option_1',
                  text: 'Option 1',
                },
                {
                  key: 'option_2',
                  text: 'Option 2',
                },
                {
                  key: 'option_3',
                  text: 'Option 3',
                },
              ],
            },
          ],
        },
      },
    },
    {
      key: 'input_box',
      label: 'Input Box',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%2017inputBox_0015187735433399574.png?alt=media&token=35afdfc6-7da5-4614-95f5-a5946e3284f3',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'input_value',
              type: 'InputBox',
              tags: ['standard_input'],
              parent_key: 'container_main',
            },
          ],
        },
      },
    },
    {
      key: 'two_inputs',
      label: 'Two Inputs',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%208twoInputs_8562380846054647.png?alt=media&token=5478b8e2-4e8b-495f-aff3-81b128463bc7',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'input_value_1',
              type: 'InputBox',
              tags: ['standard_input'],
              text: 'First Input',
              parent_key: 'container_main',
            },
            {
              key: 'input_value_2',
              type: 'InputBox',
              tags: ['standard_input'],
              text: 'Second Input',
              parent_key: 'container_main',
            },
          ],
        },
      },
    },
    {
      key: 'calendly',
      label: 'Calendly',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%2015calendly_5303398340040576.png?alt=media&token=fccc8ed3-c0c0-4653-8eaa-b99b6c5d1a28',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'calendly',
              type: 'BookMeeting',
              service: 'calendly',
              tags: ['book_meeting'],
              parent_key: 'container_main',
            },
          ],
        },
      },
    },
    {
      key: 'range_page',
      label: 'Range Slider',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%203range_7513313403309886.png?alt=media&token=aa438696-97fb-4559-a735-8337b7ffe32d',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'range',
              type: 'InputBox',
              input_type: 'range',
              range_min: 1,
              range_max: 10,
              range_step: 1,
              tags: ['range'],
              parent_key: 'container_main',
            },
          ],
        },
      },
    },
    {
      key: 'stripe_checkout',
      label: 'Stripe Checkout',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%202stripe_9627910513351515.png?alt=media&token=35f7a538-8c56-4fe0-908a-4393b525c70d',
      group: 'User Input',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'inputs_container',
              type: 'Container',
              container_type: 'container',
              tags: ['column'],
              parent_key: 'container_main',
            },
            {
              key: 'input_value_1',
              type: 'InputBox',
              tags: ['standard_input'],
              text: 'First Input',
              parent_key: 'inputs_container',
            },
            {
              key: 'input_value_2',
              type: 'InputBox',
              tags: ['standard_input'],
              text: 'Second Input',
              parent_key: 'inputs_container',
            },
            {
              key: 'input_value_3',
              type: 'InputBox',
              tags: ['standard_input'],
              text: 'Third Input',
              parent_key: 'inputs_container',
            },
            {
              key: 'input_value_4',
              type: 'InputBox',
              tags: ['standard_input'],
              text: 'Fourth Input',
              parent_key: 'inputs_container',
            },
            {
              key: 'input_value_5',
              type: 'InputBox',
              tags: ['standard_input'],
              text: 'Fifth Input',
              parent_key: 'inputs_container',
            },
            {
              key: 'input_value_6',
              type: 'InputBox',
              tags: ['standard_input'],
              text: 'Sixth Input',
              parent_key: 'inputs_container',
            },
            {
              key: 'stripe_checkout',
              type: 'StripeCheckout',
              tags: ['stripe_checkout'],
              parent_key: 'container_main',
            },
          ],
        },
      },
    },
    {
      key: 'bulleted_list',
      label: 'Bulleted List',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%2011bulletedList_7034343831984738.png?alt=media&token=a232ef97-aaf4-4792-9c45-c49daf8c0837',
      group: 'Info Page',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'ul_list',
              type: 'PlainText',
              tags: ['ul_list'],
              text: 'List here pending',
              parent_key: 'container_main',
            },
          ],
        },
      },
    },
    {
      key: 'image_page',
      label: 'Image',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%2010imgPage_8619487415274351.png?alt=media&token=e462ff58-66e9-4782-9a36-b652bec0bd06',
      group: 'Info Page',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'image',
              type: 'MediaImage',
              tags: ['image'],
              parent_key: 'container_main',
            },
          ],
        },
      },
    },
    {
      key: 'just_text_page',
      label: 'Just Text',
      buttonImage:
        'https://firebasestorage.googleapis.com/v0/b/savvy-flow-uploads/o/oXrJG5EABAqe3at8RL8W%2FGroup%2012paragraph_4510421902949694.png?alt=media&token=1edf08fa-1089-4d7d-a737-cedf4b0aed1a',
      group: 'Info Page',
      template: {
        page: {
          components: [
            {
              key: 'container_main',
              type: 'Container',
              container_type: 'container',
              tags: ['main_container', 'column'],
            },
            {
              key: 'title',
              type: 'PlainText',
              tags: ['title'],
              text: 'Page Title',
              parent_key: 'container_main',
            },
            {
              key: 'subtitle',
              type: 'PlainText',
              tags: ['subtitle'],
              text: 'Page Subtitle',
              parent_key: 'container_main',
            },
            {
              key: 'paragraph',
              type: 'PlainText',
              tags: ['paragraph'],
              text: 'text here pending',
              parent_key: 'container_main',
            },
          ],
        },
      },
    },
  ]
}

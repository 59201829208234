<template>
  <div
    class="GridLayout"
    :class="`uid-${_uid}`"
    :style="{
      'grid-template-columns': `repeat(${cols}, auto)`,
      'grid-template-rows': `repeat(${rows}, auto)`,
      'grid-auto-columns': colSizing,
      'grid-auto-rows': rowSizing,
    }"
  >
    <div
      v-for="(block, index) in blocks"
      :key="block.join('/')"
      class="GridLayoutBlock"
      :class="[
        `block-${index + 1}`,
        ...(((layoutBlocks || [])[index] || {}).tags || []).map(tag => `tag-${tag}`),
        {
          selected: Object.entries(tabGroupIndexes[index] || {}).some(
            ([key, value]) => userData && (userData[key] || 0) === value
          ),
        },
      ]"
      @click="onClick(index)"
    >
      <slot v-bind="{ block, index }" />
    </div>
    <v-style type="text/css">
      {{ styleText }}
    </v-style>
  </div>
</template>

<script>
export default {
  name: 'GridLayout',
  props: {
    layout: String,
    rowSizing: {
      type: String,
      default: 'min-content',
    },
    colSizing: {
      type: String,
      default: 'min-content',
    },
    blockStyles: Array,
    layoutBlocks: Array,
    userData: Object,
  },
  computed: {
    blocks() {
      return this.layout.split(',').map(block => block.split('/'))
    },
    cols() {
      return Math.max(...this.blocks.reduce((refs, [, b, , d]) => [...refs, b, d], [])) - 1
    },
    rows() {
      return Math.max(...this.blocks.reduce((refs, [a, , c]) => [...refs, a, c], [])) - 1
    },
    styleText() {
      return (this.blocks || [])
        .map(
          (block, index) =>
            `
        .GridLayout.uid-${this._uid} .GridLayoutBlock:nth-child(${index + 1}) {
          grid-area: ${block.join(' / ')};
          ${Object.entries((this.blockStyles || [])[index] || {})
            .filter(([, val]) => val)
            .map(([key, val]) => `${key}: ${val};`)
            .join(`\n`)}
        }`
        )
        .join('\n\n')
    },
    tabGroupIndexes() {
      return (this.layoutBlocks || []).map((layoutBlock, index) => {
        const { tab_group } = (this.layoutBlocks || [])[index] || {}

        const tabGroupIndex = (this.layoutBlocks || [])
          .map((lb, index) => ({ ...lb, index }))
          .filter(lb => lb.tab_group === tab_group)
          .findIndex(lb => lb.index === index)

        return tab_group
          ? {
              [tab_group]: tabGroupIndex,
            }
          : {}
      })
    },
  },
  methods: {
    onClick(index) {
      const { tab_group } = (this.layoutBlocks || [])[index] || {}

      const tabGroupIndex = (this.layoutBlocks || [])
        .map((lb, index) => ({ ...lb, index }))
        .filter(lb => lb.tab_group === tab_group)
        .findIndex(lb => lb.index === index)

      if (tab_group) this.$emit('update', [tab_group, tabGroupIndex])
    },
  },
}
</script>

<style lang="scss">
.GridLayout {
  display: grid;
  grid-gap: 4px;
}
</style>

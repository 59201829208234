var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Container",class:("ContainerType-" + _vm.containerType)},[(_vm.containerType !== 'carousel')?_vm._l((_vm.componentsToShow),function(component,cidx){
var _obj;
return _c('FormComponent',_vm._b({key:component.id,class:[("in-container-key-" + _vm.containerKey), ( _obj = {}, _obj[("in-page-key-" + (_vm.page && _vm.page.key))] = _vm.page, _obj )],on:{"update":function($event){return _vm.$emit('update', $event)},"prev":_vm.onPrev,"next":_vm.onNext,"reset":function($event){return _vm.$emit('reset')},"go-to-page":function($event){return _vm.$emit('go-to-page', $event)},"update-form":function($event){return _vm.onUpdateForm(component, $event)}},nativeOn:{"click":function($event){return _vm.clickAction(component, cidx)}}},'FormComponent',{
        form: _vm.form,
        page: _vm.page,
        component: component,
        pageData: _vm.pageData,
        userData: _vm.userData,
        pageId: _vm.pageId,
        componentTabGroupIndexes: _vm.componentTabGroupIndexes,
        componentIndex: cidx,
        pageMeetsRequirements: _vm.pageMeetsRequirements,
      },false))}):[_c('Carousel',_vm._b({key:_vm.componentKeys,attrs:{"initialSlide":_vm.initialSlide},on:{"afterChange":_vm.afterSlideChange}},'Carousel',_vm.carouselSettings,false),_vm._l((_vm.componentsToShow),function(component,cidx){
      var _obj;
return _c('FormComponent',_vm._b({key:component.id,class:[
          ("in-container-key-" + _vm.containerKey),
          ( _obj = {}, _obj[("in-page-key-" + (_vm.page && _vm.page.key))] = _vm.page, _obj ) ],on:{"update":function($event){return _vm.$emit('update', $event)},"prev":_vm.onPrev,"next":_vm.onNext,"reset":function($event){return _vm.$emit('reset')},"go-to-page":function($event){return _vm.$emit('go-to-page', $event)},"update-form":function($event){return _vm.onUpdateForm(component, $event)}},nativeOn:{"click":function($event){return _vm.clickAction(component, cidx)}}},'FormComponent',{
          form: _vm.form,
          page: _vm.page,
          component: component,
          pageData: _vm.pageData,
          userData: _vm.userData,
          pageId: _vm.pageId,
          componentTabGroupIndexes: _vm.componentTabGroupIndexes,
          componentIndex: cidx,
          pageMeetsRequirements: _vm.pageMeetsRequirements,
        },false))}),1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import cloneDeep from 'lodash/cloneDeep'

export default function emptyUserData(oldData, form) {
  const userData = cloneDeep(oldData)
  const computedFieldKeys = ((form && form.computedFields) || []).map(cf => cf.key)
  const experimentKeys = ((form && form.experiments) || []).map(cf => cf.key)
  const allowedKeys = new Set([
    'entryId',
    'is_test',
    '_referrer',
    '_has_analytics',
    'location_data',
    '_source_data',
    'first_version',
    'last_version',
    'current_total_pages',
    'highest_page_reached_index',
    'highest_page_reached_id',
    'highest_page_reached_key',
  ])
  Object.keys(userData).forEach(key => {
    if (!allowedKeys.has(key) && !computedFieldKeys.includes(key) && !experimentKeys.includes(key))
      delete userData[key]
  })
  if (form && form.defaults && form.defaults.userData) {
    Object.entries(form.defaults.userData || {}).forEach(([k, v]) => {
      if (!allowedKeys.has(k)) userData[k] = v
    })
  }
  return userData
}

export default function compileStyleText(selectorOrder, formStyles, id, flattenZIndexes) {
  // const selectorOrder = (this.form && this.form.selectorOrder) || []
  const styles = Object.entries(formStyles || {})
    .sort(sortByCustomOrder)
    .sort(sortByMaxWidth)

  const zIndexes = flattenZIndexes ? orderZIndexes(styles) : {}

  return styles
    .map(([selector, selectorStyles]) => {
      const isHoverSelector = selector.includes(':hover')
      const split = selector
        .split(',')
        .map(s => formatSelector(s.trim(), id))
        .join(', ')
      const selectorContent = `${split} { ${Object.entries(selectorStyles || {})
        .filter(([, val]) => val)
        .map(([key, val]) => {
          const value = key === 'z-index' ? zIndexes[val] || val : val
          return `${key}: ${value} !important;`
        })
        .join(' ')} }`
      /* Disable hover on mobile */
      return isHoverSelector ? `@media (hover:hover) { ${selectorContent} }` : selectorContent
    })
    .join(' ')

  function sortByCustomOrder([key1], [key2]) {
    if (selectorOrder.indexOf(key1) > selectorOrder.indexOf(key2)) return -1
    if (selectorOrder.indexOf(key1) < selectorOrder.indexOf(key2)) return 1
    return 0
  }

  function sortByMaxWidth([key1], [key2]) {
    const maxWidth1 = getMaxWidth(key1)
    const maxWidth2 = getMaxWidth(key2)

    if (!maxWidth2 || maxWidth1 > maxWidth2) return -1
    if (!maxWidth1 || maxWidth1 < maxWidth2) return 1
    return 0
  }
}

function formatSelector(selector, id) {
  return selector.startsWith('.SignpostForm')
    ? selector.replace('.SignpostForm', `.ElementType-FlowMain.id-${id}`)
    : selector.startsWith('.Flow-EntireFlow')
    ? selector.replace('.Flow-EntireFlow', `.Flow-EntireFlow.id-${id}`)
    : selector.startsWith('.Flow-Element.ElementType-FlowMain')
    ? selector.replace(
        '.Flow-Element.ElementType-FlowMain',
        `.Flow-Element.ElementType-FlowMain.id-${id}`
      )
    : `.Flow-EntireFlow.id-${id} ${selector}`
}

function getMaxWidth(key) {
  if (!key.includes(`[max-width~='`)) return null

  return parseInt(key.split(`max-width~='`)[1].split(`'px]`)[0])
}

function orderZIndexes(styles) {
  const zIndexesDict = new Set()

  Object.values(styles).forEach(styleObj => {
    Object.entries(styleObj).forEach(([k, v]) => {
      if (k === 'z-index') zIndexesDict.add(v)
    })
  })

  const ziArray = Array.from(zIndexesDict)
  ziArray.sort((a, b) => a - b)

  return ziArray.reduce((acc, zIndex, i) => {
    acc[zIndex] = i + 1
    return acc
  }, {})
}

export function groupSelectorsByCategory(selectors) {
  const categories = {
    custom: [],
    page: [],
    pageType: [],
    pageTag: [],
    pageKey: [],
    component: [],
    type: [],
    tag: [],
    componentOnPage: [],
    componentOnPageWithTag: [],
    key: [],
  }

  if (Array.isArray(selectors))
    selectors.forEach(s => {
      const category = getSelectorCategory(s)
      switch (s) {
        case 'key': {
          categories[category].push(s)
          break
        }

        default: {
          categories.custom.push(s)
          break
        }
      }
    })

  return [
    ...categories.key,
    ...categories.componentOnPageWithTag,
    ...categories.componentOnPage,
    ...categories.tag,
    ...categories.type,
    ...categories.component,
    ...categories.pageKey,
    ...categories.pageTag,
    ...categories.pageType,
    ...categories.page,
    ...categories.custom,
  ]
}

function getSelectorCategory(selector) {
  const includes = key => selector.includes(key)
  if (includes('Flow-Component') || includes('Flow-Element')) {
    if (includes('ComponentKey')) return 'key'
    if (includes('PageKey') && includes('ComponentTag')) return 'componentOnPageWithTag'
    if (includes('PageKey')) return 'componentOnPageWithTag'
    if (includes('ComponentTag')) return 'tag'
    if (includes('ComponentType')) return 'type'
    return 'component'
  }
  if (includes('Flow-Page')) {
    if (includes('PageKey')) return 'pageKey'
    if (includes('Pagetag')) return 'pageTag'
    if (includes('PageType')) return 'pageType'
    return 'page'
  }

  return
}

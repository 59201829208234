var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.repeaterComponentData.length)?_c('div',{class:[
    _vm.inPageClasses,
    {
      'is-picker-on-exact':
        this.id && _vm._getHoveredComponentId() && _vm._getHoveredComponentId() === this.id,
      'is-picked': _vm.isSelectedComponent,
      'is-picker-on': _vm._getSelectionMode(),
    } ],attrs:{"id":_vm.element_id},on:{"mouseover":function($event){return _vm.hoverComponent($event, _vm.id, true)},"mouseout":function($event){return _vm.hoverComponent($event, _vm.id, false)},"click":function($event){return _vm.selectComponent($event, _vm.id)}}},_vm._l((_vm.repeaterComponentData),function(c,i){return _c(_vm.type,_vm._b({key:c.id,tag:"component",staticClass:"component",class:[
      _vm.generateComponentSelectors(c, _vm.page),
      ("component-key-" + _vm.key) ].concat( (_vm.tags || []).map(function (tag) { return ("component-tag-" + tag); }),
      (_vm.tags || []).map(function (tag) { return ("tag-" + tag); }), // Backwards compatibility
      [_vm.inPageClasses],
      [{
        isLink: _vm.linkUrl,
        fullPageBackground: _vm.fullPageBackground,
        selected: _vm.isSelected,
        'validation-failed': _vm.validationFailed,
        required: _vm.isRequired,
      }] ),attrs:{"componentId":_vm.id},on:{"update":function($event){return _vm.onRepeaterUpdate(i, $event)},"go-to-page":function($event){return _vm.$emit('go-to-page', $event)},"prev":_vm.onPrev,"next":_vm.onNext,"reset":function($event){return _vm.$emit('reset')},"enter":_vm.onEnter,"update-form":function($event){return _vm.$emit('update-form', $event)}}},'component',{
      component: c,
      components: _vm.components,
      componentData: _vm.userData[c.key] || _vm.componentData,
      userData: _vm.userData,
      form: _vm.form,
      page: _vm.page,
      pageId: _vm.pageId,
      pageMeetsRequirements: _vm.pageMeetsRequirements,
    },false))}),1):_c(_vm.type,_vm._b({tag:"component",staticClass:"component",class:[
    _vm.generateComponentSelectors(_vm.component, _vm.page),
    ("component-key-" + _vm.key) ].concat( (_vm.tags || []).map(function (tag) { return ("component-tag-" + tag); }),
    (_vm.tags || []).map(function (tag) { return ("tag-" + tag); }), // Backwards compatibility
    [_vm.inPageClasses],
    [{
      isLink: _vm.linkUrl,
      fullPageBackground: _vm.fullPageBackground,
      selected: _vm.isSelected,
      'validation-failed': _vm.validationFailed,
      'is-picker-on': _vm._getSelectionMode(),
      'is-picker-on-exact':
        this.id && _vm._getHoveredComponentId() && _vm._getHoveredComponentId() === this.id,
      'is-picked': this.id && _vm._getSelectedComponentId() && _vm._getSelectedComponentId() === this.id,
    }] ),attrs:{"id":_vm.element_id,"componentId":_vm.id},on:{"update":_vm.onUpdate,"go-to-page":function($event){return _vm.$emit('go-to-page', $event)},"prev":_vm.onPrev,"next":_vm.onNext,"reset":function($event){return _vm.$emit('reset')},"enter":_vm.onEnter,"update-form":function($event){return _vm.$emit('update-form', $event)}},nativeOn:{"mouseover":function($event){return _vm.hoverComponent($event, _vm.id, true)},"mouseout":function($event){return _vm.hoverComponent($event, _vm.id, false)},"click":function($event){return _vm.selectComponent($event, _vm.id)}}},'component',{
    component: _vm.component,
    componentData: _vm.componentData,
    userData: _vm.userData,
    form: _vm.form,
    page: _vm.page,
    pageId: _vm.pageId,
    pageMeetsRequirements: _vm.pageMeetsRequirements,
    components: _vm.components,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }
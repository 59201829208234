<template>
  <div
    class="InputBox input_box_shape"
    :class="[
      input_type,
      {
        multiline,
        'invalid-answer': hasRequested && !answerPassesValidation,
        'validation-passed': answerPassesValidation,
        'phone-dropdown-open': phoneDropdownOpen,
        'inline-button': inline_button,
      },
    ]"
  >
    <label
      v-if="label && label.trim() && input_type !== 'switch' && !inline_button"
      :class="_elSel('Label', ['InputLabel'])"
    >
      <span v-if="icon" class="icon fas" :class="`fa-${icon}`" />

      <PlainRichTextToggle
        :rich="label_rich"
        _key="label"
        placeholder="Input Label"
        :text="`${label}${form.required_labels && component.isRequired ? ' *' : ''}`"
        v-bind="{ form, userData }"
        @update-form="$emit('update-form', $event)"
        @update="$emit('update', $event)"
      >
        <span>{{ label }}{{ form.required_labels && component.isRequired ? ' *' : '' }}</span>
      </PlainRichTextToggle>
    </label>

    <PhoneInput
      v-if="input_type === 'phone'"
      :phone="componentData"
      :placeholder="placeholder"
      :required="required"
      :invalidEmptyMessage="component.empty_invalid_message"
      :invalidMessage="component.invalid_message"
      @blur="onBlur()"
      @phone="onPhone"
      @validated="onPhoneValidated"
      @open="phoneDropdownOpen = true"
      @close="phoneDropdownOpen = true"
    />
    <template v-else-if="input_type === 'range'">
      <div class="slider">
        <input
          ref="input"
          class="range"
          :class="_elSel('InputElement')"
          type="range"
          :min="range_min"
          :max="range_max"
          :step="range_step"
          :required="required"
          v-model="answer"
          :style="width ? { width: `${width}ch` } : undefined"
        />
        <input
          ref="input"
          class="range-addon"
          :class="_elSel('InputElement')"
          type="number"
          v-bind="{ placeholder }"
          :min="range_min"
          :max="range_max"
          :step="range_step"
          :required="required"
          v-model="answer"
        />
      </div>
    </template>
    <ToggleSwitch
      v-else-if="input_type === 'switch'"
      :initialOn="componentData"
      :label="two_label ? [off_label, label] : label"
      :dualSide="component.label_on_both_side"
      @input="answer = $event"
    />
    <template v-else>
      <input
        v-if="['date', 'month'].includes(input_type)"
        ref="input"
        :type="inputType"
        :class="_elSel('InputElement')"
        :value="answer"
        :style="width ? { width: `${width}ch` } : undefined"
        v-bind="{ placeholder, required, min: ranges.min, max: ranges.max, step: range_step }"
        @input="onInput"
        @keyup.enter="onEnter"
        @blur="onBlur()"
      />
      <input
        v-else-if="['checkbox'].includes(input_type)"
        ref="input"
        :type="inputType"
        :checked="basicAnswer"
        :class="_elSel('InputElement')"
        :style="width ? { width: `${width}ch` } : undefined"
        :data-ms-member="page.memberstack_form ? key : null"
        v-bind="{ placeholder, required }"
        @input="onInput"
        @keyup.enter="onEnter"
        @blur="onBlur()"
      />
      <input
        v-else-if="['number'].includes(input_type)"
        ref="input"
        type="number"
        :min="range_min"
        :max="range_max"
        :step="range_step"
        :class="_elSel('InputElement')"
        :value="isPhoneValidation ? valueHolder : basicAnswer"
        :style="width ? { width: `${width}ch` } : undefined"
        :data-ms-member="page.memberstack_form ? key : null"
        v-bind="{ placeholder, required }"
        @input="onInput"
        @keyup.enter="onEnter"
        @blur="onBlur()"
      />
      <input
        v-else-if="!multiline"
        ref="input"
        :type="inputType"
        :pattern="component.integer_only ? '\d+' : undefined"
        :class="_elSel('InputElement')"
        :value="isPhoneValidation ? valueHolder : basicAnswer"
        :style="width ? { width: `${width}ch` } : undefined"
        :data-ms-member="page.memberstack_form ? key : null"
        v-bind="{ placeholder, required, maxlength: maxLength }"
        @input="onInput"
        @keyup.enter="onEnter"
        @blur="onBlur()"
      />
      <textarea
        v-else
        ref="input"
        type="text"
        :class="_elSel('TextareaElement')"
        :required="required"
        v-bind="{ placeholder }"
        :value="answer"
        :style="component.width ? { width: `${component.width}ch` } : undefined"
        @input="onInput"
        @blur="onBlur()"
      />
    </template>
    <!-- Turned off validation message for now as it shows up as user is typing - will consider options -->
    <div
      class="validation-failed"
      v-if="validationMessage && !answerPassesValidation && hasRequested && isRequestingComponent"
    >
      {{ validationMessage }}
    </div>
    <CustomButton
      v-if="inline_button && inline_component"
      class="inlined"
      :class="_elSel('InputInlineSubmitButton', ['SubmitButton'])"
      :componentId="inline_component.id"
      :component="inline_component"
      :componentData="componentData"
      :form="form"
      :userData="userData"
      :pageMeetsRequirements="pageMeetsRequirements"
      @update-form="emitInline('update-form', $event)"
      @update="emitInline('update', $event)"
      @next="$emit('next')"
      @prev="$emit('prev')"
      @go-to-page="$emit('go-to-page', $event)"
      @reset="$emit('reset')"
    />
    <!-- @update-form="$emit('update-form', $event)"
        @update="$emit('update', $event)" -->
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
// import { AsYouType } from 'libphonenumber-js'
import { unpack, unpackWithComputed } from '@/helpers/computed'
import { validateInputBox, componentPassesValidation } from '@/components/form/helpers/validation'

import computedValues from './editor/helpers/computedValues'

import PlainRichTextToggle from './PlainRichTextToggle'
import CustomButton from './CustomButton.vue'

export default {
  name: 'InputBox',
  components: {
    PlainRichTextToggle,
    PhoneInput: () => import('./PhoneInput'),
    ToggleSwitch: () => import('../utilities/ToggleSwitch.vue'),
    CustomButton,
  },
  inject: {
    _validationFailed: { default: () => () => {} },
    _localDataGet: { default: () => () => {} },
    _localUpdate: { default: () => () => {} },
    _elSel: { default: () => () => [] },
    _requestSubmitStatus: { default: () => () => [] },
    _triggerFormSubmit: { default: () => () => {} },
    _requestingComponentId: { default: () => () => {} },
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    form: Object,
    userData: {},
    pageMeetsRequirements: Boolean,
    page: Object,
  },
  data() {
    return {
      isValid: false,
      entryHasPhone: false,
      phoneDropdownOpen: false,
      inputValue: undefined,
      basicValue: undefined,
      hasRequested: false,
    }
  },
  computed: {
    ...unpack('component', [
      'key',
      'icon',
      // 'placeholder',
      'isRequired',
      'multiline',
      'autofocus',
      'input_type',
      'validation_formula',
      'range_min',
      'range_max',
      'range_step',
      'width',
      'label_rich',
      'two_label',
      'off_label',
      'inline_button',
      'inline_component',
    ]),
    ...unpackWithComputed('component', ['placeholder']),
    required() {
      return (
        this.component && this.component.isRequired // || (this.validation_formula && this.answer))
      )
    },
    ranges() {
      const relevantRange = ['month', 'date'].includes(this.component.input_type)
      const isMonth = this.component.input_type === 'month'
      const now = new Date()
      const year = now.getFullYear()
      const monthNum = now.getMonth() + 1
      const dayNum = now.getDate()
      const month = monthNum < 10 ? `0${monthNum}` : monthNum
      const day = dayNum < 10 ? `0${dayNum}` : dayNum
      const range = isMonth ? `${year}-${month}` : `${year}-${month}-${day}`
      return {
        min:
          relevantRange && this.component.range_min === 'future'
            ? range
            : computedValues(this.userData, this.component.range_min, this.form),
        max:
          relevantRange && this.component.range_max === 'past'
            ? range
            : computedValues(this.userData, this.component.range_max, this.form),
      }
    },
    label() {
      const label =
        this.component.label ||
        (this.component.key &&
          this.component.key
            .split(/-|_/)
            .filterExists()
            .map(w => w[0].toUpperCase() + w.slice(1))
            .join(' '))
      return computedValues(this.userData, label, this.form)
    },
    inputType() {
      const component = (this.input_type === 'confirm' && this.targetComponent) || this.component
      const inputType = component && component.input_type
      // this.component.input_type === 'confirm'
      //   ? this.targetComponent && this.targetComponent.input_type
      //   : this.component.input_type

      if (inputType === 'number' && component.use_tel_input) return 'tel'
      return (
        inputType ||
        (['email_address', 'business_email'].includes(component.validation_formula)
          ? 'email'
          : 'text')
      )
    },
    basicAnswer: {
      get() {
        return this.basicValue === undefined ? this.answer : this.basicValue
        // return this.basicValue || this.answer
      },
      set(v) {
        this.basicValue = v
        this.debouncedSetAnswer(v)
        if (this.$refs.input) {
          const value =
            (this.component && !this.multiline && this.input_type === 'number' && +v) || v
          this.$refs.input.setCustomValidity(this.getValidationMessage(value))
        }
      },
    },
    answer: {
      get() {
        if (this.input_type === 'password' && !this.component.generatedComponent) {
          return this._localDataGet(this.component.key || this.componentId)
        }
        /* Make sure answer is a bound value from an input field, because confirm type userData value is a boolean */
        if (this.input_type === 'confirm') return this.basicValue

        return this.componentData !== undefined ? this.componentData : this.defaultVal
      },
      set(val) {
        const value =
          (this.component && !this.multiline && this.input_type === 'number' && +val) || val
        // if (this.$refs.input) this.$refs.input.setCustomValidity(this.getValidationMessage(value))
        const key = this.component.key || this.componentId
        const updateContent = [key, value]
        if (this.input_type === 'confirm') {
          /* Emit a boolean instead */
          this.$emit('update', [key, this.targetComponentValue === value])
        } else if (this.input_type === 'password' && !this.component.generatedComponent) {
          this._localUpdate(updateContent)
          this.$emit('update', [key, val ? '********' : ''])
        } else {
          this.$emit('update', updateContent)
        }
      },
    },
    defaultVal() {
      return computedValues(this.userData, this.component.defaultVal || '', this.form)
    },
    requestSubmitStatus() {
      return this._requestSubmitStatus()
    },
    isRequestingComponent() {
      return this._requestingComponentId()
        ? this._requestingComponentId() === this.component.id
        : this._requestingComponentId() === undefined
    },
    valueHolder: {
      get() {
        return this.inputValue === undefined ? this.answer : this.inputValue
      },
      async set(v) {
        try {
          const { AsYouType } = await import('libphonenumber-js')
          const input = new AsYouType('US').input(v)
          /* Use a separate value to display user feedback instantly while debouncing answer so as to not spam entries write */
          this.inputValue = input
          this.debouncedSetAnswer(input)
          const el = this.$refs.input
          if (el) el.setCustomValidity(this.getValidationMessage(input))
        } catch (error) {
          console.warn(error.message)
          this.inputValue = v
        }
      },
    },
    answerPassesValidation() {
      if (!this.answer && this.entryHasPhone) {
        return validateInputBox(this.component, this.answer, this.isValid, this.userData)
      }
      if (this.linkedConfirmComponent) {
        /* Attempt to display does not pass validation message if the linked component does not have a valid response */
        if (this.linkedConfirmComponentValue === false) return false
      }
      return componentPassesValidation(this.component, this.userData, this.form)
      // return this.answer
      //   ? validateInputBox(this.component, this.answer, this.isValid)
      //   : !this.required
    },
    isPhoneValidation() {
      return (
        this.component.validate_value && this.component.validation_formula === 'phone_number_us'
      )
    },
    validationMessage() {
      return this.getValidationMessage(this.answer)
    },
    debouncedSetAnswer() {
      const self = this
      return debounce(v => (self.answer = v), 250, { trailing: true, leading: false })
    },
    linkedConfirmComponent() {
      const pageId = this.userData && this.userData.currentPageId
      const page = this.form && this.form.pages && this.form.pages.find(p => p.id === pageId)

      const componentsOnPage = [
        ...((page && page.components) || []),
        ...((this.form && this.form.components) || []),
      ]
      const targetComponent = componentsOnPage.find(
        c => c.confirm_component_key === this.component.key
      )
      return targetComponent
    },
    linkedConfirmComponentValue() {
      return this.linkedConfirmComponent && this.userData[this.linkedConfirmComponent.key]
    },
    targetComponent() {
      /* Should only be used if confirmComponentKey is a component on the current page, otherwise this breaks */
      const pageId = this.userData.currentPageId
      const page = this.form.pages.find(p => p.id === pageId)

      const componentsOnPage = [...((page || {}).components || []), ...(this.form.components || [])]
      const targetComponent = componentsOnPage.find(
        c => c.key === this.component.confirm_component_key
      )
      return targetComponent
    },
    targetComponentValue() {
      const confirmKey = this.component.confirm_component_key
      if (!confirmKey) return null

      const userDataValue = this.userData[confirmKey]
      if (!this.targetComponent) return userDataValue

      const targetIsPassword = this.targetComponent.input_type === 'password'
      return targetIsPassword ? this._localDataGet(confirmKey) : userDataValue
    },
    maxLength() {
      const allowed = new Set(['password', 'search', 'tel', 'text', 'url'])
      const type =
        this.component.input_type === 'number' && this.component.use_tel_input
          ? 'tel'
          : this.component.input_type
      const canHaveMaxLength = allowed.has(type)

      return (canHaveMaxLength && this.component.max_length) || undefined
    },
  },
  watch: {
    defaultVal(defaultVal) {
      if (this.answer !== defaultVal) this.answer = defaultVal
    },
    targetComponentValue: {
      handler(v) {
        if (this.input_type === 'confirm') {
          this.$emit('update', [this.component.key, v === this.answer])
        }
      },
    },
    linkedConfirmComponentValue: {
      handler(v) {
        if (v === false) {
          if (this.$refs.input) {
            this.$refs.input.setCustomValidity(this.getValidationMessage('', false))
          }
        }
      },
    },
    // hasRequested: {
    //   handler(v) {
    //     console.log(`thef v`, v)
    //   },
    //   immediate: true,
    // },
    answerPassesValidation: {
      handler(v) {
        if (v) this.hasRequested = false
        if (this.requestSubmitStatus && !v) this.hasRequested = true
      },
    },
    componentData: {
      handler(v, o) {
        if (o && (v === undefined || v === null)) {
          this.basicValue = undefined
          this.inputValue = undefined
        }
      },
    },
    requestSubmitStatus: {
      handler(v) {
        this.hasRequested = v
      },
    },
  },
  mounted() {
    if (this.autofocus && this.$refs.input && this.$refs.input.focus) this.$refs.input.focus()
    if (this.$refs.input) this.$refs.input.setCustomValidity(this.validationMessage)
  },
  beforeDestroy() {
    if (['text' || 'email'].includes(this.inputType)) this.onBlur(null, true)
    if (this.input_type === 'password' && !this.component.generatedComponent) {
      const key = this.component.key || this.componentId
      if (this._localDataGet(key)) {
        const updateContent = [key, null]
        this._localUpdate(updateContent)
      }
    }
  },
  methods: {
    onEnter(e) {
      if (e && typeof e.preventDefault === 'function') e.preventDefault()
      if (e && typeof e.stopPropagation === 'function') e.stopPropagation()
      this.$emit('enter')
    },
    getValidationMessage(text, forceValidState) {
      const defaultValidValue = text
        ? validateInputBox(this.component, text, this.isValid, this.userData)
        : !this.required
      const isValid = forceValidState === undefined ? defaultValidValue : forceValidState
      if (isValid) return ''
      if (!text && this.component.empty_invalid_message)
        return computedValues(this.userData, this.component.empty_invalid_message, this.form)
      if (text && this.component.invalid_message)
        return computedValues(this.userData, this.component.invalid_message, this.form)
      if (this.component.type === 'confirm') {
        /* Note - very difficult / annoying to make this same message appear on the linked component without forcing the component to add another field to itself */
        return this.component.mismatch_values_message || 'Fields must match'
      }
      if (this.linkedConfirmComponentValue === false) {
        return (
          (this.linkedConfirmComponent && this.linkedConfirmComponent.mismatch_values_message) ||
          'Fields must match'
        )
      }
      switch (this.component.validation_formula) {
        case 'email_address':
        case 'business_email':
          return 'Please enter a valid email address.'

        case 'full_name':
          return 'Please enter your full name.'

        case 'phone_number_us':
          return 'Please enter a valid US phone number.'

        default:
          return 'Please fill out this field.'
      }
    },
    emitInline(emit, [k, v]) {
      this.$emit(emit, [`inline_component`, { ...(this.component.inline_component || {}), [k]: v }])
    },
    onPhone(phone) {
      if (this.isValid) this.basicAnswer = phone
      else this.basicAnswer = ''
    },
    onInput(e) {
      const val = e.target.type === 'checkbox' ? e.target.checked : e.target.value
      if (this.isPhoneValidation) {
        this.valueHolder = val
      } else {
        if (this.component.integer_only) {
          this.basicAnswer = `${val}`.replace(/[^0-9]/g, '') //parseInt(val) || val
        } else this.basicAnswer = val
        // this.answer = val
      }
    },
    onPhoneValidated(event) {
      this.isValid = event[1]
      this.entryHasPhone = Boolean(event[0])
    },
    onBlur(emit, skipValidation) {
      const key = this.component.key || this.componentId
      if (this.component.trim_whitespace_on_blur) {
        const newVal = (this.answer || '').trim()
        emit ? this.$emit('update', [key, newVal]) : (this.answer = newVal)
      } else if (this.component.format_email_on_blur) {
        const newVal = this.answer
          .split(' ')
          .join('')
          .toLowerCase()
        emit ? this.$emit('update', [key, newVal]) : (this.answer = newVal)
      }
      if (!skipValidation) {
        if (this.component.validate_on_blur && !this.answerPassesValidation) {
          this._requestingComponentId() === undefined
            ? this._triggerFormSubmit()
            : this._triggerFormSubmit(this.component.id)
          if (this._requestingComponentId() === this.component.id) this.hasRequested = true
        }
      }
    },
  },
}
</script>

import geohash from 'ngeohash'

export function getGeohash(latitude, longitude) {
  return geohash.encode(latitude, longitude)
}

// Calculate the upper and lower boundary geohashes for
// a given latitude, longitude, and distance in miles
export function getGeohashRange(
  latitude,
  longitude,
  distance // miles
) {
  const lat = 0.0144927536231884 // degrees latitude per mile
  const lon = 0.0181818181818182 // degrees longitude per mile

  const lowerLat = latitude - lat * distance
  const lowerLon = longitude - lon * distance

  const upperLat = latitude + lat * distance
  const upperLon = longitude + lon * distance

  const lower = geohash.encode(lowerLat, lowerLon)
  const upper = geohash.encode(upperLat, upperLon)

  return {
    lower,
    upper,
  }
}

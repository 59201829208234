import iconify from '@iconify/vue'
import axios from 'axios'

const staticIcons = [
  'fa-solid:redo',
  'fa-solid:upload',
  'fluent:spinner-ios-20-regular',
  'feather:x',
  'fa-solid:play',
  'fa-regular:play',
  'fa-solid:fa-angle-left',
  'fa-regular:fa-angle-left',
]

export default async function loadIconify(form) {
  const icons = getFormIcons(form)

  if (Object.keys(icons).length === 0) return
  const successfulIcons = {}
  const responses = await Promise.all(
    Object.entries(icons).map(async ([prefix, iconList]) => {
      const baseUrl = `https://api.iconify.design/${prefix}.json?icons=${iconList.join(',')}`
      try {
        const { data } = await axios.get(baseUrl)
        successfulIcons[prefix] = iconList
        return data
      } catch (error) {
        console.error(error)
        if (error && error.response && error.response.data) console.error(error.response.data)
        return null
      }
    })
  )
  responses
    .filter(r => r)
    .forEach(r => {
      iconify.addCollection(r)
    })
  return successfulIcons
}

function getFormIcons(form) {
  const pages = (form && form.pages) || []

  const icons = pages
    .reduce((acc, p) => {
      if (p.prevIcon && p.prevIcon.includes(':')) acc.push(p.prevIcon)
      if (p.nextIcon && p.nextIcon.includes(':')) acc.push(p.nextIcon)

      if (!Array.isArray(p.components)) return acc
      ;(p.components || []).forEach(c => {
        switch (c.type) {
          case 'CustomButton': {
            if (c.icon) {
              if (c.icon.includes(':')) acc.push(c.icon)
              else acc.push(iconToString(c.icon))
            }
            break
          }
          case 'FileUpload': {
            if (c.icon) {
              if (c.icon.includes(':')) acc.push(c.icon)
              else acc.push(iconToString(c.icon))
            } else acc.push('fa-solid:upload')
            break
          }
          case 'MediaEmbed': {
            acc.push('fa-solid:play')
            break
          }

          case 'OptionButtons':
          case 'OptionSelector': {
            if (c.checkbox) acc.push('fa-solid:check')
            ;(c.buttons || []).forEach(b => {
              if (b.icon) {
                if (b.icon.includes(':')) acc.push(b.icon)
                else acc.push(iconToString(b.icon))
              }
            })
            break
          }

          default:
            break
        }
      })
      return acc
    }, [])
    .concat(staticIcons)

  if (Array.isArray(form && form.components)) {
    form.components.forEach(c => {
      switch (c.type) {
        case 'CustomButton': {
          if (c.icon && c.icon.includes(':')) icons.push(c.icon)
          break
        }

        case 'OptionButtons':
        case 'OptionSelector': {
          ;(c.buttons || []).forEach(b => {
            if (b.icon && b.icon.includes(':')) icons.push(b.icon)
          })
          break
        }

        default:
          break
      }
    })
  }
  return Array.from(new Set(icons)).reduce((acc, c) => {
    const [collection, icon] = c.split(':')
    if (!collection || !icon) return acc
    acc[collection] = acc[collection] || []
    acc[collection].push(icon)
    return acc
  }, {})
}
export function iconToString(icon) {
  const iconString = Array.isArray(icon)
    ? icon.map((s, i) => (i === 0 ? shortToLong(s) : s)).join(':')
    : icon
  return iconString
}
function shortToLong(icon) {
  switch (icon) {
    case 'fas':
      return 'fa-solid'
    case 'fab':
      return 'fa-brands'
    case 'fa':
      return 'fa-regular'
    default:
      return icon
  }
}

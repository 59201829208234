import axios from 'axios'
import detectLocalStorage from '@/helpers/detectLocalStorage'
import getUrlDict from '../../../helpers/getUrlDict'
import { getReferrer } from '@/helpers/analytics/core'

export default async function getReferrerData(productId) {
  const localStorageKey = `SavvyUserId-${productId}`
  const contactId = detectLocalStorage() && localStorage.getItem(localStorageKey)

  if (!contactId) return null
  const reqData = { domain: window.location.host, contactId }

  const { data } = await axios.post(
    'https://us-central1-savvy-app-live.cloudfunctions.net/recentContactEvents',
    reqData
  )

  if (data.queries) {
    data.queries.data = { ...(data.queries.data || {}), ...getUrlDict() }
  } else {
    data.queries = { timestamp: new Date().toISOString(), data: getUrlDict() }
  }

  const referrerData = getReferrer()
  if (document.referrer && referrerData.hostname !== window.location.hostname && !data.referrers) {
    data.referrers = { timestamp: new Date().toISOString(), data: referrerData }
  }

  return data
}

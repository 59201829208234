<template>
  <a
    v-if="pageIndex === 0 && page.backUrl && shouldShowButton"
    class="PrevButton prev animate hasPrev prev-link button"
    :href="page.backUrl"
    :class="[
      ..._elSel('PageFooterButtonPrev', ['PageFooterButton']),
      { hide: page.showPrev === false || hide, justClicked: recentlyClicked.next },
    ]"
  >
    <Icon v-if="page.prevIcon" :icon="page.prevIcon" />
    <QuickEditText
      _key="prevText"
      :value="footerButtonTexts.prev"
      @update-text="onUpdatePage(...$event)"
    >
      {{ footerButtonTexts.prev }}
    </QuickEditText>
  </a>
  <button
    v-else-if="shouldShowButton"
    type="button"
    class="PrevButton prev animate"
    :disabled="!hasPrev"
    :class="[
      ..._elSel('PageFooterButtonPrev', ['PageFooterButton']),
      { hasPrev, hide: page.showPrev === false || hide, justClicked: recentlyClicked.next },
    ]"
    @click="onClickPrev"
  >
    <Icon v-if="page.prevIcon" :icon="page.prevIcon" />
    <QuickEditText
      _key="prevText"
      :value="footerButtonTexts.prev"
      @update-text="onUpdatePage(...$event)"
    >
      {{ footerButtonTexts.prev }}
    </QuickEditText>
  </button>
</template>
<script>
import QuickEditText from './helpers/QuickEditText.vue'
import Icon from './Icon'
export default {
  name: 'PrevButton',
  components: { QuickEditText, Icon },
  inject: {
    _elSel: { default: () => () => {} },
    _filterByConditions: { default: () => () => {} },
    _uniqueByKey: { default: () => () => {} },
    _getRecentlyClicked: { default: () => () => {} },
    _finish: { default: () => () => {} },
    _updateReactive: { default: () => () => {} },
    _getSelectionMode: { default: () => () => {} },
    _getFlowPages: { default: () => () => [] },
  },
  props: {
    form: Object,
    page: Object,
    component: Object,
    userData: Object,
    pageMeetsRequirements: Boolean,
    hide: Boolean,
  },
  computed: {
    recentlyClicked() {
      return this._getRecentlyClicked()
    },
    pages() {
      return (
        this.form &&
        this._getFlowPages()
          .reduce(this._uniqueByKey, [])
          .filter(this._filterByConditions)
      )
    },
    pageIndex() {
      return this.pages.findIndex(p => p.id === this.page.id)
    },
    shouldShowButton() {
      if (this.component && this.component.hide_if_no_nav_target) {
        return Boolean(this.hasPrev)
      }
      return true
    },
    hasPrev() {
      if (!this.form || !Array.isArray(this._getFlowPages())) return false
      return this._getFlowPages().findIndex(p => p.id === this.page.id) > 0 // this.pageIndex > 0
    },
    footerButtonTexts() {
      const prev =
        (this.page.usePageNavText !== false
          ? this.page.prevText
          : this.form && this.form.defaults && this.form.defaults.prevText) || 'Prev'
      return { prev }
    },
  },
  watch: {},
  methods: {
    onClickPrev() {
      if (this._getSelectionMode()) return
      this.$emit('prev')
    },
    onUpdatePage(key, value) {
      const pageIndex = this.form.pages.findIndex(p => p.id === this.page.id)
      this._updateReactive(`pages.${pageIndex}`, key, value)
    },
    // onUpdateNavButton(button, [key, value]) {
    //   const newButton = cloneDeep(button)
    //   newButton[key] = value
    //   this.onUpdatePage('custom_next_button', newButton)
    // },
  },
}
</script>

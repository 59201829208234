import debug from '@/helpers/debug'
import { getCleanUrl } from '../getCleanUrl'

export function removeTrailingSlash(url) {
  return url.endsWith('/') ? url.slice(0, -1) : url
}

export function getUrlProps() {
  const {
    href: url,
    host: domain,
    hostname,
    pathname: path,
    hash,
    origin: main,
    search: queryString,
  } = window.location
  const query = getUrlQueryParams()
  const site = {
    url,
    domain,
    hostname,
    path,
    hash,
    main,
    query,
    queryString,
    clean: getCleanUrl(url),
  }

  const referrer = getReferrer()

  if (!document) debug.log('No document')

  const urlProps = { url, site, referrer }

  if (site && site.query && site.query.utm_source) {
    urlProps.channel = getChannelFromUtmSource(site.query.utm_source)
  }

  return urlProps
}

export function getUrlQueryParams() {
  const str = window.location.href.split('?')[1]

  return str
    ? str
        .split('&')
        .map(part => part.split('='))
        .reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {})
    : null
}

export function getReferrer() {
  const {
    protocol,
    source: url,
    authority: domain,
    host: hostname,
    path,
    hash,
    query: queryString,
    queryKey: query,
  } = parseUri(document && document.referrer)

  return {
    protocol,
    url,
    main: removeTrailingSlash(url.split('?')[0]),
    domain,
    hostname,
    path,
    hash,
    queryString,
    query,
    clean: getCleanUrl(url),
  }
}

function parseUri(str) {
  var o = parseUri.options,
    m = o.parser[o.strictMode ? 'strict' : 'loose'].exec(str),
    uri = {},
    i = 14

  while (i--) uri[o.key[i]] = m[i] || ''

  uri[o.q.name] = {}
  uri[o.key[12]].replace(o.q.parser, function($0, $1, $2) {
    if ($1) uri[o.q.name][$1] = $2
  })

  return uri
}

parseUri.options = {
  strictMode: false,
  key: [
    'source',
    'protocol',
    'authority',
    'userInfo',
    'user',
    'password',
    'host',
    'port',
    'relative',
    'path',
    'directory',
    'file',
    'query',
    'anchor',
  ],
  q: {
    name: 'queryKey',
    parser: /(?:^|&)([^&=]*)=?([^&]*)/g,
  },
  parser: {
    strict: /^(?:([^:/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:/?#]*)(?::(\d*))?))?((((?:[^?#/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
    loose: /^(?:(?![^:@]+:[^:@/]*@)([^:/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#/]*\.[^?#/.]+(?:[?#]|$)))*\/?)?([^?#/]*))(?:\?([^#]*))?(?:#(.*))?)/,
  },
}

function getChannelFromUtmSource(utmSource) {
  switch (utmSource) {
    case 'facebook':
      return 'facebook-ads'
    default:
      return utmSource
  }
}

export function getSourcesObject(urlProps) {
  const sources = []
  const urlPropsObj = urlProps || getUrlProps()
  const referrer = getReferrer()
  if (referrer.clean) sources.push(`r:${encodeURIComponent(referrer.clean)}`)
  if (urlPropsObj.channel) sources.push(`s:${urlPropsObj.channel}`)

  const queryObj = (urlPropsObj && urlPropsObj.site && urlPropsObj.site.query) || {}
  const medium = queryObj.utm_medium
  if (medium) sources.push(`m:${medium}`)

  addSourceTags(sources)

  return sources.reduce((acc, s) => {
    acc[s] = true
    return acc
  }, {})
}

function addSourceTags(sources) {
  const seenSources = new Set(sources)

  if (seenSources.has(`s:facebook`) && seenSources.has(`m:ads`)) sources.push('facebook|ads')
  if (seenSources.has(`s:facebook`) || seenSources.has(`s:google`)) sources.push(`s:social`)
}

// import * as Sentry from '@sentry/vue'
import getButtons from './getButtons'
import notify from '@/helpers/notifyLite'

const TRUE = '_true'
const FALSE = '_false'
// const NOT_TRUE = '_not_true'
// const NOT_FALSE = '_not_false'
// const EMPTY = '_empty'
// const NOT_EMPTY = '_!empty'

export function passesConditions(conditions, userData, components, webapp) {
  return conditions.every(condition => passesCondition(condition, userData, components, webapp))
}

export function passesCondition({ key, operator, values }, userData, components, webapp) {
  try {
    if (!key || !operator) return true

    const seenValues = new Set(
      (values || []).reduce((acc, v) => {
        acc.push(...valueMapper(v))
        return acc
      }, [])
    )

    const userValues = userData && userData[key]
    const component = components && components[key]
    if (component && (component.buttons || component.preset_buttons)) {
      const seen = seenValues
      getButtons(component, userData).forEach(b => {
        if (seen.has(b.key) || seen.has(b.text)) {
          if (b.text) seenValues.add(b.text)
          if (b.key) seenValues.add(b.key)
        }
      })
    }
    const modifiedValues = Array.from(seenValues)
    const NO_VALUE = '_no_value'

    switch (operator) {
      case '==':
        return Array.isArray(userValues)
          ? modifiedValues.some(
              value =>
                (userValues && userValues.includes(value)) ||
                (value === NO_VALUE && userValues.some(v => !exists(v)))
            )
          : (modifiedValues && modifiedValues.includes(userValues)) ||
              (modifiedValues.includes(NO_VALUE) && !exists(userValues))
      case '!=': {
        return Array.isArray(userValues)
          ? !modifiedValues.some(
              value =>
                (userValues && userValues.includes(value)) ||
                (value === NO_VALUE && userValues.every(v => exists(v)))
            )
          : !modifiedValues ||
              (!modifiedValues.includes(userValues) &&
                (modifiedValues.includes(NO_VALUE) ? exists(userValues) : true))
      }
      // case 'includes': return !modifiedValues.includes( userValues)
      case 'exists':
        return Array.isArray(userValues) ? userValues.filter(exists).length : exists(userValues)
      case '!exists':
        return Array.isArray(userValues)
          ? userValues.filter(exists).length === 0
          : !exists(userValues)
      case 'is-true':
        return userValues === true
      case 'is-false':
        return userValues === false
      case 'is-not-true':
        return userValues !== true
      case 'is-not-false':
        return userValues !== false
      case 'is-empty':
        return userValues === ''
      case 'is-not-empty':
        return userValues !== ''
      default:
        return true
    }
  } catch (error) {
    console.error(error)
    if (!webapp) {
      notify(
        `Error Evaluating Conditions: {key: ${key}, operator: ${operator}, values: ${values} }\nError: ${error.message}`
      )
      // Sentry.captureException(error, {
      //   extra: { key, operator, values, component: components[key] },
      // })
    }
    return false
  }
}

export function exists(value) {
  return value !== undefined && value !== null
}

function valueMapper(v) {
  switch (v) {
    case TRUE:
      return [true]
    case FALSE:
      return [false]
    // case EMPTY:
    // case NOT_EMPTY:

    default:
      return [v]
  }
}

<template>
  <PlainRichTextToggle
    :rich="useRichText"
    _key="headingLabel"
    placeholder="Heading"
    :class="[...inPageClasses, ..._elSel('PageHeadingLabel')]"
    :text="text"
    v-bind="{ form, userData }"
    @update-form="onUpdatePage(...$event)"
    @update="$emit('update', $event)"
  >
    <div class="heading-label" :class="inPageClasses">
      {{ text | computed(userData, form) }}
    </div>
  </PlainRichTextToggle>
</template>

<script>
import PlainRichTextToggle from './PlainRichTextToggle'
export default {
  name: 'PageHeadingLabel',
  components: { PlainRichTextToggle },
  inject: {
    _elSel: { default: () => () => {} },
    _updateReactive: { default: () => () => {} },
  },
  props: {
    rich: Boolean,
    form: Object,
    userData: Object,
    page: Object,
  },
  computed: {
    useRichText() {
      return this.rich || this.page.headingLabelRich
    },
    inPageClasses() {
      return [
        `in-page-key-${this.page.key}`,
        ...(this.page.tags || []).map(tag => `in-page-tag-${tag}`),
      ]
    },
    text() {
      return (this.page && this.page.headingLabel) || ''
    },
  },
  watch: {},
  methods: {
    onUpdatePage(key, value) {
      const pageIndex = this.form.pages.findIndex(p => p.id === this.page.id)
      this._updateReactive(`pages.${pageIndex}`, key, value)
    },
  },
}
</script>
